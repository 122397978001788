import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { ActionCell, ChipListCell } from "../../components";
import { Company } from "../../API";

type Props = ({ row }: { row: Company }) => void;

export const columns = (onClick: Props): GridColDef<Company, string[]>[] => [
  {
    field: "name",
    headerName: "Company",
    renderCell: ({ row, value }) => (
      <ActionCell onClick={() => onClick({ row })}>{value}</ActionCell>
    ),
    minWidth: 200,
    flex: 1,
  },
  {
    field: "clientList",
    headerName: "Manager(s)",
    valueGetter: ({ row: { clients } }) =>
      clients?.items
        .filter((i) => !i?.inactiveAt)
        .map((i) => `${i?.firstName} ${i?.lastName}`) ?? [],
    renderCell: ({ value }) => <ChipListCell data={value ?? []} />,
    minWidth: 200,
    flex: 1,
  },
  {
    field: "contractors",
    headerName: "Consultant(s)",
    valueGetter: ({ row: { clients } }) =>
      clients?.items.flatMap((i) =>
        (i?.employees?.items ?? []).map(
          (e) => `${e?.employee.firstName} ${e?.employee.lastName}`
        )
      ) ?? [],
    renderCell: ({ value }) => <ChipListCell data={value ?? []} />,
    minWidth: 200,
    flex: 1,
  },
];

export const hiddenColumnsDesktop: { [key: string]: boolean } = {};

export const hiddenColumnsMobile: { [key: string]: boolean } = {
  ...hiddenColumnsDesktop,
};
