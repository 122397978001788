import { ElementType, useCallback, useContext, useMemo } from "react";
import { ModalContext } from "./ModalContext";

type UseModal<T> = {
  component: ElementType<T>;
  data?: Omit<T, "isOpen" | "onClose" | "params">;
  onClose?: () => void;
};

export const useModal = <T, K = undefined>({
  component,
  data,
  onClose,
}: UseModal<T>) => {
  const key = useMemo(generateModalKey, []);
  const { showModal: showModalContext, hideModal: hideModalContext } =
    useContext(ModalContext);

  const showModal: (params?: K) => void = useCallback(
    (params?) =>
      showModalContext({
        key,
        component,
        data:
          params instanceof Event
            ? data
            : { ...data, ...({ params: params || {} } || {}) },
      }),
    [showModalContext, key, component, data]
  );

  const hideModal = useCallback(
    () => hideModalContext({ key, onClose }),
    [hideModalContext, key, onClose]
  );

  return {
    showModal,
    hideModal,
  };
};

const generateModalKey = (() => {
  let count = 0;
  return () => `${++count}`;
})();
