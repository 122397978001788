import { ComponentProps } from "react";
import {
  ContractsModal,
  ContractsModalParams,
  UpdateModal,
  useModal,
} from "../../../components";
import { Company } from "../../../API";
import { useCompanyLib, useContractsLib } from "../../../lib";
import { ClientModal } from "./ClientModal";

// This hook is solely responsible for the management of the various Client modals.
// Here we can manage the modals for the various actions (CRUD) that we want to perform on the clients.
export const useClientModals = () => {
  const { setCompanyRecords, setCompanyInactiveDate } = useCompanyLib();
  const { setContracts } = useContractsLib();

  const { showModal: showCreateModal, hideModal: hideCreateModal } = useModal({
    component: ClientModal,
    data: {
      title: "Add Client",
      onSubmit: ({ data }) =>
        setCompanyRecords(data, { onSuccess: hideCreateModal }),
      testID: "add-client",
    },
  });

  const { showModal: showUpdateModal, hideModal: hideUpdateModal } = useModal<
    ComponentProps<typeof ClientModal>,
    { row: Company }
  >({
    component: ClientModal,
    data: {
      title: "Update Client",
      onSubmit: ({ data }) =>
        setCompanyRecords(data, { onSuccess: hideUpdateModal }),
      testID: "update-client",
    },
  });

  const { showModal: showDeactivateModal, hideModal: hideDeactivateModal } =
    useModal<ComponentProps<typeof UpdateModal>, Company>({
      component: UpdateModal,
      data: {
        title: "Deactivate Client",
        label: "Deactivate",
        description: "Are you sure you want to deactivate this entry?",
        onUpdate: ({ id, clients: { items } }) => {
          setCompanyInactiveDate(
            { id, inactiveAt: new Date(), clients: items },
            {
              onSettled: hideDeactivateModal,
            }
          );
        },
      },
    });

  const { showModal: showReactivateModal, hideModal: hideReactivateModal } =
    useModal<ComponentProps<typeof UpdateModal>, Company>({
      component: UpdateModal,
      data: {
        title: "Reactivate Client",
        label: "Reactivate",
        description: "Are you sure you want to reactivate this entry?",
        onUpdate: ({ id }) =>
          setCompanyInactiveDate({ id }, { onSettled: hideReactivateModal }),
      },
    });

  const { showModal: showContractsModal, hideModal: hideContractsModal } =
    useModal<ComponentProps<typeof ContractsModal>, ContractsModalParams>({
      component: ContractsModal,
      data: {
        title: "Employees",
        onSubmit: (data) => {
          setContracts(data, { onSettled: hideContractsModal });
        },
        testID: "client-contracts",
      },
    });

  return {
    showCreateModal,
    showUpdateModal,
    showDeactivateModal,
    showReactivateModal,
    showContractsModal,
  };
};
