import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import tdsLogo from "../../assets/tds_logo.png";

type Props = {
  testID: string;
};

export const TdsLogo: FC<Props> = ({ testID }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate("/")}
      sx={{ p: 0 }}
      data-testid={`${testID}-tds-logo`}
    >
      <Avatar
        alt="TDS Logo"
        src={tdsLogo}
        sx={{
          width: 70,
          height: 30,
          mr: 1,
        }}
        variant="square"
      />
    </Button>
  );
};
