import { API, GraphQLResult } from "@aws-amplify/api";
import * as gqlQueries from "../graphql/queries";
import * as gqlCustoms from "../graphql/custom";
import * as mut from "../graphql/mutations";
import { MutationType, QueryType, ReqType, VarType } from "./types";

export type ApiActions = {
  onComplete?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
};

export type BaseRequestType = {
  headers?: Record<string, string>;
  key?: KeyOptions[];
};

type KeyOptions =
  | {
      id?: string;
      page?: number;
      dateRange?: string[];
    }
  | object
  | number
  | string
  | string[]
  | boolean;

type GraphQLVariables =
  | {
      filter?: object | null | undefined;
      limit?: number | null | undefined;
      nextToken?: string | null | undefined;
      input?: object;
      condition?: object;
    }
  | object;

const gql = { ...gqlQueries, ...gqlCustoms };

// Use query keys, or data won't persist properly when using vars
export const gqlQuery = <T extends QueryType>({
  req,
  vars,
  headers,
  key = [],
}: T) => ({
  queryKey: [req, ...key],
  queryFn: () => gqlRequest<ReqType<T>>(gql[req], vars, headers),
});

export const gqlMutation = <T extends MutationType>({ req, headers }: T) => ({
  mutationFn: (vars: VarType<T>) =>
    gqlRequest<ReqType<T>>(mut[req], vars, headers),
});

export const gqlRequest = async <T extends object>(
  query: string,
  variables?: GraphQLVariables,
  additionalHeaders?: Record<string, string>
) =>
  API.graphql(
    {
      query,
      variables: variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    },
    additionalHeaders
  ) as Promise<GraphQLResult<T>>;
