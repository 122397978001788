import React, { useState } from "react";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Rule as RuleIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { GridSelectionModel } from "@mui/x-data-grid";
import { startOfWeek, endOfWeek } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import {
  DataGrid,
  PageTitle,
  FilterBar,
  useQueryPagination,
} from "../../components";
import {
  getCompanyClientIDs,
  toIntervalString,
  useTimelogLib,
} from "../../lib";
import { useResponsive } from "../../hooks";
import { downloadAction } from "../Timesheet/Downloader";
import { columns, hiddenColumnsDesktop, hiddenColumnsMobile } from "./columns";
import { approvalQuery } from "./utils";

export const Approvals = () => {
  const { isMd } = useResponsive();
  const { setTimelogApproval } = useTimelogLib();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [employeeID, setEmployeeID] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [hideApproved, setHideApproved] = useState(false);
  const [week, setWeek] = useState<Interval | null>({
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date()),
  });

  const { data: company } = useQuery(getCompanyClientIDs(companyID));

  const { data, isError, refetch, isPending, pagination } = useQueryPagination(
    approvalQuery({
      week,
      employeeID,
      companyID,
      logFilter: company?.clientFilter,
    })
  );

  const hideApprovals = {
    label: hideApproved ? "Show Approved" : "Hide Approved",
    icon: <CheckCircleOutlineIcon />,
    onClick: () => setHideApproved(!hideApproved),
  };

  const downloadSheets = {
    disabled: selectionModel.length === 0 || !week,
    label: "Download Timesheets",
    icon: <FileDownloadOutlinedIcon />,
    onClick: () =>
      week &&
      downloadAction({
        employees: (data?.employees ?? []).filter((e) =>
          selectionModel.includes(e.id)
        ),
        week,
        filterCompanyID: companyID,
      }),
  };

  return (
    <>
      <PageTitle
        icon={<RuleIcon />}
        title="Approvals"
        action={downloadSheets}
        altAction={hideApprovals}
        testID="approvals"
      />

      <FilterBar
        dates={{ value: week, onChange: setWeek, type: "week" }}
        company={{ value: companyID, onChange: setCompanyID }}
        employee={{ value: employeeID, onChange: setEmployeeID }}
        testID="approvals"
      />

      <DataGrid
        header={week ? toIntervalString(week) : undefined}
        columns={columns({
          approveTimesheet: ({ logIds, approved }) =>
            setTimelogApproval(logIds, approved, { onSettled: refetch }),
        })}
        data={
          hideApproved
            ? (data?.items ?? []).filter(
                ({ approved }) => approved !== "approved"
              )
            : data?.items ?? []
        }
        emptyState={{
          title: "Timesheet Approvals",
          description:
            "Timesheets that are pending will appear here for you to approve.",
          testID: "approvals",
        }}
        errorState={{
          isError,
          onClick: refetch,
        }}
        loading={isPending}
        pagination={pagination}
        testID="approvals"
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        columnVisibility={isMd ? hiddenColumnsMobile : hiddenColumnsDesktop}
      />
    </>
  );
};
