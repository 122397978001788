import React, { useCallback, useMemo, useState } from "react";
import { ModalContext } from "./ModalContext";
import { ShowModal, HideModal } from "./types";

type Config = {
  [key: string]: Pick<ShowModal, "component" | "data"> & {
    isOpen: boolean;
  };
};

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalsConfig, setModalsConfig] = useState<Config>({});

  const showModal = useCallback(
    ({ key, component, data }: ShowModal) => {
      setModalsConfig((prevConfig) => ({
        ...prevConfig,
        [key]: { isOpen: true, component, data },
      }));
    },
    [setModalsConfig]
  );

  const hideModal = useCallback(
    ({ key, onClose }: HideModal) => {
      setModalsConfig((prevConfig) => ({
        ...prevConfig,
        [key]: { ...prevConfig[key], isOpen: false },
      }));

      if (onClose) onClose();
    },
    [setModalsConfig]
  );

  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
    }),
    [hideModal, showModal]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}

      {Object.keys(modalsConfig).map((key) => {
        const { component: Component, isOpen, data } = modalsConfig[key];
        return (
          isOpen && (
            <Component
              key={key}
              isOpen={isOpen}
              onClose={() => hideModal({ key })}
              {...data}
            />
          )
        );
      })}
    </ModalContext.Provider>
  );
};
