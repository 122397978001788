import React, { useState } from "react";
import { StackedLineChart as ChartIcon } from "@mui/icons-material";
import { GridSelectionModel } from "@mui/x-data-grid";
import { endOfMonth, startOfMonth } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import {
  DataGrid,
  PageTitle,
  FilterBar,
  useQueryPagination,
} from "../../components";
import { getCompanyClientIDs, toIntervalString } from "../../lib";
import { columns } from "./columns";
import { reportsQuery } from "./utils";

export const Reports = () => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [employeeID, setEmployeeID] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [dates, setDates] = useState<Interval | null>({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });

  const { data: company } = useQuery(getCompanyClientIDs(companyID));

  const { data, isError, refetch, isPending, pagination } = useQueryPagination(
    reportsQuery({
      dates,
      employeeID,
      companyID,
      logFilter: company?.clientFilter,
    })
  );
  const fileName = `${dates ? `${toIntervalString(dates)} - ` : ""}${
    company?.name ? `${company.name}_` : ""
  }Time_Report`;

  return (
    <>
      <PageTitle icon={<ChartIcon />} title="Reports" testID="reports" />

      <FilterBar
        company={{ value: companyID, onChange: setCompanyID }}
        employee={{ value: employeeID, onChange: setEmployeeID }}
        dates={{ value: dates, onChange: setDates, type: "range" }}
        testID="reports"
      />

      <DataGrid
        header={dates ? `Week of ${toIntervalString(dates)}` : undefined}
        csvOptions={{ fileName }}
        columns={columns}
        data={data?.items ?? []}
        emptyState={{
          title: "Timesheet Reports",
          description: "No available timesheet data in specified period",
          testID: "reports",
        }}
        errorState={{
          isError,
          onClick: refetch,
        }}
        loading={isPending}
        pagination={pagination}
        testID="reports"
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
      />
    </>
  );
};
