import React from "react";
import { Box, Typography } from "@mui/material";
import { UserAvatar } from "../UserAvatar";

export const Desktop = () => (
  <Box
    sx={{
      display: { xs: "none", md: "flex" },
      width: 1,
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    {/* TODO - Needs to be dynamic page title */}
    <Typography component="h1" variant="h6">
      Timesheets
    </Typography>

    <UserAvatar />
  </Box>
);
