/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://ivxf36bt2bf4hidj4vk3gegj2y.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:91eff5b7-fd98-4c27-936b-6e867c1e733e",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_t69URRIYj",
    "aws_user_pools_web_client_id": "2mh7fql04e6vrvimlg4np1ajik",
    "oauth": {
        "domain": "tds-prod.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:1234/,https://master.dm6pmstqyhyh5.amplifyapp.com/,https://prod.dm6pmstqyhyh5.amplifyapp.com/,https://www.erp.testdrivensolutions.co.uk/,https://www.dev.erp.testdrivensolutions.co.uk/",
        "redirectSignOut": "http://localhost:1234/,https://master.dm6pmstqyhyh5.amplifyapp.com/,https://prod.dm6pmstqyhyh5.amplifyapp.com/,https://www.erp.testdrivensolutions.co.uk/,https://www.dev.erp.testdrivensolutions.co.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
