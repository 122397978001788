import React, { FC } from "react";
import { TextField } from "@mui/material";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

type Props = Omit<MuiDatePickerProps<Date, Date | null>, "renderInput">;

export const DatePicker: FC<Props> = ({ label = "Select Date", ...rest }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <MuiDatePicker
      {...rest}
      label={label}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          inputProps={{ ...params.inputProps, readOnly: true }}
          InputLabelProps={{ shrink: true }}
          margin="dense"
          size="small"
        />
      )}
    />
  </LocalizationProvider>
);
