import React, { MouseEvent, useState } from "react";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import { useUser } from "../../hooks";
import { Menu } from "./Menu";

export const UserAvatar = () => {
  const user = useUser();
  const avatar = { alt: user?.fullName, src: user?.imgURL };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      {/* Mobile */}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Avatar sx={{ width: 56, height: 56 }} {...avatar} />
      </Box>

      {/* Desktop */}
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            data-testid="dt-account-menu-button"
          >
            <Avatar sx={{ width: 32, height: 32 }} {...avatar} />
          </IconButton>
        </Tooltip>

        <Menu anchorEl={anchorEl} open={open} close={handleClose} />
      </Box>
    </>
  );
};
