import React from "react";
import { Image, Text, StyleSheet, View } from "@react-pdf/renderer";
import mainLogo from "../../../../assets/tds_logo.png";

const styles = StyleSheet.create({
  /* stylelint-disable */
  container: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    textTransform: "uppercase",
    margin: 10,
  },
  logoColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  name: {
    fontSize: 24,
    fontFamily: "Lato Bold",
  },
  subtitle: {
    fontSize: 10,
    justifySelf: "flex-end",
    fontFamily: "Lato",
  },
  image: {
    margin: 10,
    width: 80,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  /* stylelint-enable */
});

export const Header = () => (
  <View style={styles.container}>
    <View style={styles.detailColumn}>
      <Text style={styles.name}>Weekly Timesheet</Text>
      <Text style={styles.subtitle}>Test Driven Solutions Ltd.</Text>
    </View>
    <View style={styles.logoColumn}>
      <Image src={mainLogo} style={styles.image} />
    </View>
  </View>
);
