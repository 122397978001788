/* eslint-disable no-sparse-arrays */
import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { format } from "date-fns";
import { CreateTimelogInput } from "../../../../API";
import { Value, WeekEntry } from "./WeekEntry";

const HEADER = [
  "Day",
  "Date",
  "Standard",
  "Overtime",
  "Sick",
  "Holiday",
  "Total",
];
const FOOTER = ["", "Total Hours"];

const styles = StyleSheet.create({
  /* stylelint-disable */
  container: {
    paddingTop: 25,
    paddingLeft: 15,
    flexDirection: "column",
  },
  date: {
    fontSize: 11,
    fontFamily: "Lato Italic",
  },
  /* stylelint-enable */
});

type Props = {
  header?: boolean;
  footer?: boolean;
  key: string | number;
  values: (string | number)[];
};

const formatValues = ({
  date: strDate,
  standard,
  overtime,
  sick,
  holiday,
}: CreateTimelogInput): { values: (string | number)[]; hours: number[] } => {
  const hours = [standard, overtime, sick, holiday].map((v) => v ?? 0);
  const total = hours.reduce((a, b) => a + b, 0);
  const date = new Date(strDate);
  return {
    values: [format(date, "EEEE"), date.toLocaleDateString(), ...hours, total],
    hours: [...hours, total],
  };
};

const WeekRow = ({ values, ...rest }: Props) => (
  <WeekEntry>
    {[...values].map((value, i) => (
      <Value {...rest} key={i} isNumber={typeof value === "number"}>
        {value}
      </Value>
    ))}
  </WeekEntry>
);

export const WeekSummary = ({ data }: { data: CreateTimelogInput[] }) => {
  let totals = [0, 0, 0, 0, 0];
  return (
    <View style={styles.container}>
      <WeekRow key={data.length} values={HEADER} header />
      {data.map((data, idx) => {
        const { values, hours } = formatValues(data);
        totals = totals.map((val, idx) => val + hours[idx]);
        return <WeekRow key={idx} values={values} />;
      })}
      <WeekRow key={data.length + 1} values={[...FOOTER, ...totals]} footer />
    </View>
  );
};
