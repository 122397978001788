import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  toDbDates,
  useEmployeeLib,
  useGetClient,
  gqlQuery,
  useTimelogLib,
} from "../../lib";
import { User, useUser } from "../../hooks";
import { getTimesheet } from "./utils";

type ReqParams = {
  id: string;
  week: Interval | null;
  user: User;
  nullEmployeeHandler: () => Promise<void>;
  dupeLogHandler: (id: string) => Promise<void>;
};

type QueryOptions = {
  id?: string;
  week: Interval | null;
};

const getTimesheetQuery = ({
  id,
  week,
  user,
  nullEmployeeHandler,
  dupeLogHandler,
}: ReqParams) => {
  if (week === null) {
    throw new Error("Cannot pull data, date range is undefined.");
  }
  if (!user) {
    throw new Error(
      "Error loading user details. If problems persist contact a manager to create a record manually."
    );
  }
  if (
    process.env.NODE_ENV === "production" &&
    !user.isGoogle &&
    !user.isAdmin
  ) {
    throw new Error("Sign in with Google to submit timesheets.");
  }

  const { queryFn, queryKey } = gqlQuery({
    req: "getEmployeeWithCompany",
    vars: { id, dateRange: toDbDates(week) },
    key: [id, week],
  });

  return {
    queryKey,
    queryFn: async () => {
      if (user.isAdmin && id === user.id) {
        return { timesheet: [], isApproved: false, clientIDs: [] };
      }
      const { data } = await queryFn();
      const employee = data?.getEmployee ?? null;

      if (employee === null) {
        await nullEmployeeHandler();
        throw new Error("Employee ID returns null record");
      }

      if (employee.timelogs) {
        employee.timelogs.items.forEach((log, idx, logs) => {
          if (log && idx && logs[idx - 1]?.date === log?.date) {
            dupeLogHandler(log.id);
            logs.splice(idx, 1);
          }
        });
      }
      return await getTimesheet({
        week,
        employee,
      });
    },
  };
};

export const useTimesheetQuery = ({ id, week }: QueryOptions) => {
  const { createEmployeeRecord } = useEmployeeLib();
  const { deleteTimelogById } = useTimelogLib();
  const user = useUser() as User;
  id = useMemo(() => id || user?.id, [id, user?.id]);

  const { data: emailRecord, isSuccess } = useQuery(
    gqlQuery({
      req: "listEmployeesWithCompanies",
      vars: {
        filter: {
          and: [{ email: { eq: user?.email }, id: { notContains: "google" } }],
        },
      },
    })
  );

  const nullEmployeeHandler = async () => {
    createEmployeeRecord(
      user?.input,
      {},
      emailRecord?.data?.listEmployees?.items[0] ?? undefined
    );
  };

  const { data, isError, isLoading, isFetching, isPreviousData, error } =
    useQuery({
      ...getTimesheetQuery({
        id,
        week,
        user,
        nullEmployeeHandler,
        dupeLogHandler: async (id: string) => {
          deleteTimelogById(id);
        },
      }),
      enabled: isSuccess,
    });

  const clientIDs = (data?.clientIDs ?? []).filter((id) => id.length);

  const contracts = useGetClient(clientIDs).map(({ id, company }) => ({
    label: company.name,
    value: id,
  }));

  return {
    ...data,
    contracts,
    error: error as Error,
    isError,
    isPending: isLoading || isPreviousData || isFetching,
  };
};
