/**
 * Util Cell for wrapping a tooltip around the cell content.
 * Useful for when the cell content is too long to fit the cell.
 */

import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import { Tooltip } from "../../Tooltip";

type Props = {
  children: ReactNode;
};

export const useContainerDimensions = (
  ref: React.MutableRefObject<HTMLInputElement | null>
) => {
  const [dimensions, setDimensions] = useState({
    scrollWidth: 0,
    clientWidth: 0,
  });

  const getDimensions = useCallback(
    () => ({
      scrollWidth: ref?.current?.scrollWidth ?? 0,
      clientWidth: ref?.current?.clientWidth ?? 0,
    }),
    [ref]
  );

  useEffect(() => {
    if (ref.current) setDimensions(getDimensions());
    const handleResize = () => setDimensions(getDimensions());
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, getDimensions]);

  return dimensions;
};

export const TooltipCell: FC<Props> = ({ children }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);
  const { scrollWidth, clientWidth } = useContainerDimensions(ref);

  useEffect(() => {
    setIsOverflow(scrollWidth > clientWidth);
  }, [scrollWidth, clientWidth]);

  return (
    <Tooltip
      title={children || ""}
      placement="top"
      disableHoverListener={!isOverflowed}
    >
      <Box
        ref={ref}
        sx={{
          display: "inherit",
          flex: "inherit",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
