export type Address = {
  country: string;
  line1: string;
  line2: string;
  line3: string;
};

export const OFFICES: { [key: string]: Address } = {
  GB: {
    country: "United Kingdom",
    line1: "Office 524A, Rotterdam House",
    line2: "116 Quayside",
    line3: "Newcastle Upon Tyne, NE1 3DY",
  },

  CA: {
    country: "Canada",
    line1: "10th Floor, Bankers Hall West Tower",
    line2: "888 3rd Street SW",
    line3: "Calgary, Alberta, T2P 5C5",
  },

  PL: {
    country: "Poland",
    line1: "Przyokopowa 33",
    line2: "01-208",
    line3: "Warszawa (Warsaw)",
  },
};
