import React from "react";
import { Amplify } from "aws-amplify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setDefaultOptions } from "date-fns";
import { enGB } from "date-fns/locale";
import { SnackbarProvider } from "notistack";
import awsconfig from "./aws-exports.js";
import { ModalProvider } from "./components";
import { Routing } from "./routes";
import { UserProvider } from "./hooks";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const redirectURI = isLocalhost
  ? "http://localhost:1234/"
  : `https://${window.location.hostname}/`;

const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: redirectURI,
    redirectSignOut: redirectURI,
  },
};

setDefaultOptions({ locale: enGB, weekStartsOn: 1 });
Amplify.configure(updatedAwsConfig);
const theme = createTheme();
const queryClient = new QueryClient();

export const App = () => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <SnackbarProvider>
          <ModalProvider>
            <Routing />
          </ModalProvider>
        </SnackbarProvider>
      </UserProvider>
    </QueryClientProvider>
  </ThemeProvider>
);
