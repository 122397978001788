import { downloadZip } from "client-zip";
import { pdf } from "@react-pdf/renderer";
import { nextFriday } from "date-fns";
import { Client, ClientContracts, Employee } from "../../../API";
import { filterNull } from "../../../lib";
import { buildTimesheet } from "../utils";
import { Address, OFFICES } from "../../../addresses";
import { TimesheetPDF } from "./TimesheetPDF";

type Props = {
  employees: Employee[];
  week: Interval;
  filterCompanyID: string;
};

type SheetProps = {
  employee: Employee;
  week: Interval;
  client: Client;
  address: Address;
  fileName: string[];
};

export const downloadAction = async ({
  employees,
  week,
  filterCompanyID,
}: Props) => {
  const friDate = nextFriday(week.start).toLocaleDateString("sv");
  const getFileName = async (
    { company: { name } }: Client,
    { firstName, lastName }: Employee
  ) => [
    `Timesheets-${friDate}`,
    name,
    `${firstName}${lastName}_TDS_Timesheet_${friDate}.pdf`,
  ];

  const pdfs = (
    await Promise.all(
      employees.map(async (employee) =>
        Promise.all(
          (
            await filterNull<ClientContracts>(
              employee.clients?.items,
              ({ client: { companyClientsId } }) =>
                filterCompanyID === "" || filterCompanyID === companyClientsId
            )
          ).map(async ({ client }) =>
            buildPDF({
              employee,
              client,
              week,
              address: OFFICES[client.company.country ?? "GB"],
              fileName: await getFileName(client, employee),
            })
          )
        )
      )
    )
  ).flat();

  // temporary link to download the Blob
  const link = document.createElement("a");
  link.href = URL.createObjectURL(await downloadZip(pdfs).blob());
  link.download = `Timesheets-${friDate}`;
  link.click();
  link.remove();
};
const buildPDF = async ({ fileName, ...input }: SheetProps) => ({
  name: fileName.join("/"),
  input: await pdf(
    TimesheetPDF({
      ...input,
      title: fileName[-1],
      timesheet: await buildTimesheet(input),
    })
  ).toBlob(),
});
