import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  /* stylelint-disable */
  entryContainer: {
    flexDirection: "row",
    flexGrow: 1,
    flexBasis: "auto",
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  header: {
    flex: 1,
    fontSize: 16,
    color: "black",
    textDecoration: "none",
    fontFamily: "Lato Bold",
    textAlign: "center",
    paddingBottom: 6,
  },
  item: {
    flex: 1,
    fontSize: 14,
    fontFamily: "Lato",
    paddingHorizontal: 3,
    paddingVertical: 7,
  },
  number: {
    textAlign: "right",
    borderLeft: 1,
  },
  footer: {
    border: 1,
    borderRight: 0,
    backgroundColor: "#9a9a9a",
  },
  /* stylelint-enable */
});

type ValueProps = {
  header?: boolean;
  footer?: boolean;
  isNumber: boolean;
  key: string | number;
  children: React.ReactNode;
};

export const WeekEntry = ({ children }: { children: React.ReactNode }) => (
  <View style={styles.rowContainer}>{children}</View>
);

export const Value = ({ header, footer, isNumber, children }: ValueProps) => (
  <View style={styles.entryContainer}>
    <Text
      style={[
        header ? styles.header : styles.item,
        footer && children !== "" ? styles.footer : {},
        isNumber ? styles.number : {},
      ]}
    >
      {children}
    </Text>
  </View>
);
