import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Employee } from "../../API";
import { ActionCell, ChipListCell } from "../../components";

type Props = ({ row }: { row: Employee }) => void;

export const columns = (
  onClick: Props
): GridColDef<Employee, string[], string>[] => [
  {
    field: "fullName",
    headerName: "Name",
    valueGetter: ({ row }) => [`${row.firstName} ${row.lastName}`],
    valueFormatter: ({ value }) => value[0],
    renderCell: ({ row, formattedValue }) => (
      <ActionCell onClick={() => onClick({ row })}>{formattedValue}</ActionCell>
    ),
    minWidth: 200,
    flex: 1,
    sortComparator: (v1, v2) =>
      v1.entries().next().value[1].localeCompare(v2.entries().next().value[1]),
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "companies",
    headerName: "Companies",
    valueGetter: ({ row: { clients } }) =>
      clients?.items.flatMap((item) => item?.client.company.name ?? []) ?? [],
    valueFormatter: ({ value }) => value.join(", "),
    renderCell: ({ value }) => <ChipListCell data={value ?? []} />,
    minWidth: 200,
    flex: 1,
  },
];

export const hiddenColumnsDesktop: { [key: string]: boolean } = {};

export const hiddenColumnsMobile: { [key: string]: boolean } = {
  ...hiddenColumnsDesktop,
};
