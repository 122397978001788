import { ElementType } from "react";

export type ShowModal = {
  component: ElementType;
  key: string;
  data?: { [key: string]: unknown; params?: Record<string, unknown> };
};

export type HideModal = {
  key: string;
  onClose?: () => void;
};

export type DefaultModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
