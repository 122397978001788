import React from "react";
import { AppBar as MuiAppBar, Toolbar } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";

type Props = {
  onClick: () => void;
  drawerWidth?: number;
};

export const AppBar = ({ onClick, drawerWidth = 240 }: Props) => (
  <MuiAppBar
    position="fixed"
    color="transparent"
    elevation={0}
    sx={{
      width: { md: `calc(100% - ${drawerWidth}px)` },
      ml: { md: `${drawerWidth}px` },
      bgcolor: grey[100],
    }}
    data-testid="app-bar"
  >
    <Toolbar>
      <Desktop />
      <Mobile onClick={onClick} />
    </Toolbar>
  </MuiAppBar>
);
