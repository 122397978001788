import React, { useEffect, useState } from "react";
import {
  AccessAlarm as AccessAlarmIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@mui/icons-material";
import { GridValidRowModel } from "@mui/x-data-grid";
import { endOfWeek, startOfWeek } from "date-fns";
import { useSnackbar } from "notistack";
import { CreateTimelogInput } from "../../API";
import {
  ApprovalIcon,
  DataGrid,
  FilterBar,
  PageTitle,
  TimesheetIcon,
} from "../../components";
import { useTimelogLib } from "../../lib";
import { useUser } from "../../hooks";
import { columns } from "./columns";
import { useTimesheetModal } from "./Modals";
import { useTimesheetQuery } from "./useTimesheetQuery";
import { sumTimesheet } from "./utils";

export type Option = { label: string; value: string };

export const Timesheet = () => {
  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [filterUser, setFilterUser] = useState(user?.id);
  const { setTimelogs } = useTimelogLib();
  const { showModal } = useTimesheetModal();
  const [status, setStatus] = useState<boolean | null>(true);
  const [week, setWeek] = useState<Interval | null>({
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date()),
  });
  const [showOnCall, setShowOnCall] = useState(false);

  const { timesheet, isApproved, contracts, isError, error, isPending } =
    useTimesheetQuery({ id: filterUser || user?.id, week });

  const isEditable: boolean = user?.isAdmin || !isApproved;

  const timesheetNotis = {
    onSuccess: () => {
      setStatus(true);
    },
    onError: () => {
      setStatus(false);
      enqueueSnackbar("Error saving timesheet, please reload.", {
        variant: "error",
      });
    },
  };

  const handleRowUpdate = ({
    id,
    standard,
    overtime,
    sick,
    holiday,
    onCall,
    ...rest
  }: GridValidRowModel) => {
    setStatus(null);
    const _ = (v: number | unknown) =>
      (typeof v === "number" ? v : -1) < 0 ? 0 : v;
    const data = {
      id: id?.at(1) ? id : undefined,
      standard: _(standard),
      overtime: _(overtime),
      sick: _(sick),
      holiday: _(holiday),
      onCall,
      ...rest,
    } as CreateTimelogInput;
    setTimelogs(data, timesheetNotis);
    return { ...data, id };
  };

  useEffect(() => {
    const hasOnCall = timesheet?.some((el) => el.onCall);
    setShowOnCall(!!hasOnCall);
  }, [timesheet]);

  const showOnCallColumn = {
    label: "On-Call",
    icon: !showOnCall ? (
      <RadioButtonUncheckedIcon />
    ) : (
      <CheckCircleOutlineIcon />
    ),
    onClick: () =>
      timesheet?.forEach((row) => {
        handleRowUpdate({ ...row, onCall: !showOnCall });
      }),
  };

  return (
    <>
      <PageTitle
        icon={<AccessAlarmIcon />}
        title="Timesheets"
        testID="timesheets"
        altIcon={<TimesheetIcon status={status} />}
        action={showOnCallColumn}
      />

      <FilterBar
        employee={
          user && user?.isAdmin
            ? { value: filterUser || user.id, onChange: setFilterUser }
            : undefined
        }
        dates={{
          value: week,
          onChange: setWeek,
          type: "week",
          InputIcon: <ApprovalIcon status={isApproved ?? null} />,
        }}
        testID="timesheets"
      />

      <DataGrid
        columns={columns({
          contracts,
          onOpenModal: ({ row }) => showModal({ contracts, row }),
          onUpdateOnCall: ({ row }) => handleRowUpdate(row),
          editable: isEditable,
          showOnCall,
        })}
        data={timesheet ?? []}
        loading={isPending}
        emptyState={{
          title: "Timesheets",
          description: isError ? error.message : "Select an employee to view",
          testID: "timesheets",
        }}
        processRowUpdate={handleRowUpdate}
        hideColumnSelector
        hideExport
        testID="timesheets"
        disableColumnMenu
        customFooterContent={
          timesheet?.length
            ? `Total hours for week: ${sumTimesheet(timesheet ?? []).total}`
            : " "
        }
      />
    </>
  );
};
