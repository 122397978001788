import React, { cloneElement } from "react";
import { Tooltip } from "@mui/material";

export type StatusProps<Status> = {
  status: Status;
  tooltip?: boolean;
};

type Props = {
  status: {
    label: string;
    icon: JSX.Element;
  };
  tooltip?: boolean;
};

export const StatusIcon = ({
  status: { label, icon },
  tooltip = true,
  ...rest
}: Props) => {
  const ele = cloneElement(icon, rest);
  return tooltip ? (
    <Tooltip title={label} placement="top">
      {ele}
    </Tooltip>
  ) : (
    ele
  );
};
