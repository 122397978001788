import { createContext } from "react";

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  isAdmin: boolean;
  isGoogle: boolean;
  imgURL: string;
  input: EmployeeProps;
};

// used to create base employee if undefined, req params only
type EmployeeProps = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export const UserContext = createContext<User | undefined>(undefined);
