import React, { FC, ReactNode } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { DefaultModalProps } from "./types";

type PrimaryAction = {
  label: string;
  isDanger?: boolean;
  testID: string;
  pending?: boolean;
};

type PrimaryButtonAction = PrimaryAction & {
  onClick: () => void;
};

type PrimarySubmitAction = PrimaryAction & {
  onSubmit: () => void;
};

export type ModalSkeleton = DefaultModalProps & {
  children: ReactNode;
  title: string;
  testID: string;
} & (
    | {
        isForm: true;
        primaryAction: PrimarySubmitAction;
      }
    | {
        isForm?: never;
        primaryAction?: PrimaryButtonAction;
      }
  );

export const ModalSkeleton: FC<ModalSkeleton> = ({
  children,
  title,
  isOpen,
  onClose,
  primaryAction,
  isForm,
  testID,
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth="md"
    fullWidth
    data-testid={`${testID}-modal`}
  >
    <DialogTitle>{title}</DialogTitle>

    {isForm ? (
      <form onSubmit={primaryAction.onSubmit} noValidate>
        <DialogContent>{children}</DialogContent>
        <Divider variant="middle" />
        <DialogActions sx={{ padding: 3 }}>
          <SecondaryButton
            onClick={onClose}
            data-testid={`${testID}-modal-cancel-button`}
          >
            Cancel
          </SecondaryButton>
          <Box sx={{ m: 1, position: "relative" }}>
            <PrimaryButton
              type="submit"
              disabled={primaryAction.pending}
              color={primaryAction.isDanger ? "error" : "primary"}
              data-testid={`${testID}-modal-${primaryAction.testID}-button`}
            >
              {primaryAction.label}
            </PrimaryButton>
            {primaryAction.pending && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
                color={primaryAction.isDanger ? "error" : "primary"}
              />
            )}
          </Box>
        </DialogActions>
      </form>
    ) : (
      <>
        <DialogContent>{children}</DialogContent>
        <Divider variant="middle" />
        <DialogActions sx={{ padding: 3 }}>
          <SecondaryButton
            onClick={onClose}
            data-testid={`${testID}-modal-cancel-button`}
          >
            Cancel
          </SecondaryButton>
          <Box sx={{ m: 1, position: "relative" }}>
            {primaryAction && (
              <PrimaryButton
                onClick={primaryAction?.onClick}
                type="button"
                disabled={primaryAction.pending}
                color={primaryAction.isDanger ? "error" : "primary"}
                data-testid={`${testID}-modal-${primaryAction.testID}-button`}
              >
                {primaryAction.label}
              </PrimaryButton>
            )}
            {primaryAction && primaryAction.pending && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
                color={primaryAction.isDanger ? "error" : "primary"}
              />
            )}
          </Box>
        </DialogActions>
      </>
    )}
  </Dialog>
);
