import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Company, CreateCompanyInput, Client, UpdateClientInput } from "../API";
import { ModalCreateClientInput } from "../pages";
import {
  ApiActions,
  useClientLib,
  filterNull,
  gqlMutation,
  toAWSDate,
  useContractsLib,
} from ".";

type SetCompanyInput = CreateCompanyInput & {
  clients: ModalCreateClientInput[];
  removeClients: Client[];
};

export const useCompanyLib = () => {
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () =>
      queryClient.invalidateQueries(["listCompaniesWithEmployees"]),
  };

  const { setClientRecords, cleanupClientRecords } = useClientLib();
  const { cleanupContracts } = useContractsLib();
  const { mutateAsync: createCompany } = useMutation({
    ...gqlMutation({ req: "createCompany" }),
    ...options,
  });
  const { mutateAsync: updateCompany } = useMutation({
    ...gqlMutation({ req: "updateCompany" }),
    ...options,
  });
  const { mutateAsync: deleteCompany } = useMutation({
    ...gqlMutation({ req: "deleteCompany" }),
    ...options,
  });
  const { mutateAsync: updateClient } = useMutation({
    ...gqlMutation({ req: "updateClient" }),
    ...options,
  });

  const setCompanyInactiveDate = async (
    {
      id,
      inactiveAt = null,
      clients = [],
    }: {
      id: string;
      inactiveAt?: Date | null;
      clients?: Client[];
    },
    actions: ApiActions
  ) => {
    if (!!inactiveAt && !!clients.length) {
      // remove contractors
      await Promise.all(
        (
          await filterNull<Client>(clients)
        ).map(({ id, employees }) =>
          cleanupContracts({ id, clientContracts: employees?.items ?? [] })
        )
      );

      // set all clients to inactive
      await Promise.all(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        clients.map(({ employees, id, companyClientsId, ...input }) =>
          updateClient({
            input: {
              ...input,
              id,
              companyClientsId,
              inactiveAt: toAWSDate(inactiveAt),
            } as UpdateClientInput,
          })
        )
      );
    }

    // set company to active/inactive
    await updateCompany(
      {
        input: { id, inactiveAt: toAWSDate(inactiveAt) },
      },
      actions
    );
  };

  const setCompanyRecords = async (
    { id, name, country, ...rest }: SetCompanyInput,
    actions: ApiActions
  ) => {
    id = await (id
      ? (
          await updateCompany({
            input: { id, name, country },
          })
        ).data?.updateCompany?.id
      : (
          await createCompany({ input: { name, country } })
        ).data?.createCompany?.id);

    if (id) setClientRecords({ ...rest, id, actions });
  };

  const cleanupCompanyRecords = async (
    company: Company,
    actions: ApiActions
  ) => {
    await Promise.all(
      (
        await filterNull<Client>(company.clients?.items)
      ).map(cleanupClientRecords)
    );
    return deleteCompany({ input: { id: company.id } }, actions);
  };

  return {
    setCompanyRecords,
    cleanupCompanyRecords,
    setCompanyInactiveDate,
  };
};
