import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import {
  Authenticator,
  Button,
  Image,
  Text,
  ThemeProvider,
  View,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import mainLogo from "../../assets/tds_logo.png";

interface stateType {
  from: { pathname: string };
}

export const Login: FC = () => {
  const theme = useTheme();
  const authTheme = {
    name: "Custom Auth Coloring",
    tokens: {
      colors: {
        background: {
          primary: {
            value: theme.palette.background.default,
          },
          secondary: {
            value: theme.palette.grey[50],
          },
        },
        font: {
          primary: { value: theme.palette.text.primary },
          interactive: { value: theme.palette.secondary.main },
        },
        brand: {
          primary: {
            "10": { value: theme.palette.primary.light },
            "80": { value: theme.palette.primary.main },
            "90": { value: theme.palette.primary.main },
            "100": { value: theme.palette.primary.dark },
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: theme.palette.secondary.dark,
              },
            },
            _hover: {
              color: {
                value: theme.palette.secondary.light,
              },
            },
            _active: {
              color: {
                value: theme.palette.secondary.main,
              },
            },
          },
        },
      },
    },
  };

  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const from = (location.state as stateType)?.from?.pathname || "/";

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  const components = {
    Header() {
      return (
        <View textAlign="center" padding="12px">
          <Image src={mainLogo} alt="logo" height={30} width={70} />
        </View>
      );
    },
    SignIn: {
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Text as="p" variation="info" fontSize={12} textAlign="center">
              To fill in your timesheet choose &apos;Sign in With Google&apos;
              and use your TDS Google account. Any issues, reach out on Slack.
            </Text>
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
              margin="12px 0"
            >
              Forgot your password?
            </Button>
          </View>
        );
      },
    },
  };

  return (
    <ThemeProvider theme={authTheme}>
      <Authenticator hideSignUp variation="modal" components={components} />
    </ThemeProvider>
  );
};
