import { format, isBefore, sub, toDate } from "date-fns";
import { gqlQuery } from "./apiService";

export enum HourCategories {
  "standard" = "standard",
  "overtime" = "overtime",
  "sick" = "sick",
  "holiday" = "holiday",
}

export const toIntervalString = ({ start, end }: Interval, limiter = " - ") =>
  `${format(start, "dd/MM/yyyy")}${limiter}${format(end, "dd/MM/yyyy")}`;

export const toDbDates = ({ start, end }: Interval) =>
  [start, end].map((date) => toDate(date).toLocaleDateString("sv"));

export const filterNull = async <T extends object>(
  arr: (T | null)[] | undefined,
  exFilter?: (item: T) => boolean
) =>
  exFilter === undefined
    ? (arr ?? []).filter((i): i is T => i !== null)
    : (arr ?? []).filter((i): i is T => i !== null).filter(exFilter);

export const getCompanyClientIDs = (id: string) => {
  const { queryFn: getCompany, ...rest } = gqlQuery({
    req: "getCompany",
    vars: { id },
    key: [id],
  });

  return {
    ...rest,
    enabled: !!id,
    queryFn: async () => {
      const { data } = await getCompany();
      const ids = await filterNull(data?.getCompany?.clients?.items);
      return {
        name: data?.getCompany?.name,
        clientFilter: ids.length
          ? {
              or: ids.map(({ id }) => ({ clientID: { contains: id } })),
            }
          : undefined,
      };
    },
  };
};

export const checkIfIsBefore = (
  inputDate: Date,
  interval: number,
  duration: string
): boolean => {
  // Calculate the interval to subtract from today's date
  const intervalObject = {
    [duration]: interval,
  };
  const intervalAgo = sub(new Date(), intervalObject);

  // Check if the date is before the interval ago date
  return isBefore(inputDate, intervalAgo);
};

export const toAWSDate = (date: Date | null): string | null =>
  date ? format(new Date(date), "yyyy-MM-dd") : null;
