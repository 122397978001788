import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import {
  Cached as CachedIcon,
  WarningAmber as WarningAmberIcon,
} from "@mui/icons-material";
import { PrimaryButton } from "../../Buttons";

type Props = {
  isError?: boolean;
  error?: Error;
  onClick: () => void;
};

export const ErrorState: FC<Props> = ({ onClick, error }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 1,
    }}
  >
    <Box
      sx={{
        display: "flex",
        width: 1,
        maxWidth: 500,
        flexDirection: "column",
        padding: 3,
        alignItems: "center",
        justifyContent: "center",
        border: 1,
        borderColor: "grey.100",
        backgroundColor: "grey.100",
        borderRadius: 1,
        gap: 2,
      }}
    >
      <WarningAmberIcon fontSize="large" color="error" />

      <Typography>
        {error ? error.message : "Oops, something went wrong loading the data."}
      </Typography>

      <PrimaryButton
        onClick={onClick}
        startIcon={<CachedIcon />}
        data-testid="grid-error-button"
      >
        Refresh Data
      </PrimaryButton>
    </Box>
  </Box>
);
