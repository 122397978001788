import React, { cloneElement, FC, ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { useResponsive } from "../../hooks";

type Action = {
  disabled?: boolean;
  label: string;
  icon: ReactElement;
  onClick: () => void;
};

type Props = {
  title: string;
  action?: Action;
  altAction?: Action;
  icon?: ReactElement;
  altIcon?: ReactElement;
  testID: string;
};

export const PageTitle: FC<Props> = ({
  title,
  icon,
  altIcon,
  action,
  altAction,
  testID,
}) => {
  const { isMd } = useResponsive();
  const tID = `${testID}-page-title`;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: isMd ? 3 : 5,
      }}
      data-testid={tID}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon &&
          cloneElement(icon, {
            sx: { mr: 1 },
            fontSize: isMd ? "small" : "large",
          })}

        <Typography
          component="h2"
          variant={isMd ? "h6" : "h4"}
          data-testid={`${tID}-heading`}
        >
          {title}
        </Typography>

        {altIcon &&
          cloneElement(altIcon, {
            sx: { ml: 1 },
            fontSize: isMd ? "small" : "large",
          })}
      </Box>
      <Box>
        {altAction && (
          <SecondaryButton
            sx={{ mr: 1 }}
            disabled={altAction.disabled}
            onClick={altAction.onClick}
            size={isMd ? "small" : "medium"}
            startIcon={altAction.icon}
            data-testid={`${tID}-alt-button`}
          >
            {altAction.label}
          </SecondaryButton>
        )}
        {action && (
          <PrimaryButton
            disabled={action.disabled}
            onClick={action.onClick}
            size={isMd ? "small" : "medium"}
            startIcon={action.icon}
            data-testid={`${tID}-button`}
          >
            {action.label}
          </PrimaryButton>
        )}
      </Box>
    </Box>
  );
};
