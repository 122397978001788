import React, { useCallback } from "react";
import { useMemo } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { User, UserContext } from "./UserContext";

type Attributes = {
  given_name?: string;
  family_name?: string;
  email: string;
  picture?: string;
  sub?: string;
  email_verified?: string;
  identities?: string;
};

export const UserProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value?: User;
}) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const checkRoles = useCallback(
    (role: string) =>
      (
        user?.getSignInUserSession()?.getAccessToken().payload[
          "cognito:groups"
        ] as string[]
      )?.includes(role),
    [user]
  );

  const newValue: User | undefined = useMemo(() => {
    const a = user?.attributes as Attributes;
    const id = user?.username;
    if (a && id) {
      const { fullName, ...names } = getName(a);
      const input = { id, email: a.email, ...names };
      return {
        ...input,
        fullName,
        imgURL: createImageElement(a.picture).src,
        isAdmin: checkRoles("admin"),
        isGoogle: id.slice(0, 6) === "google",
        input,
      };
    }
  }, [checkRoles, user]);

  return (
    <UserContext.Provider value={value ?? newValue}>
      {children}
    </UserContext.Provider>
  );
};

const createImageElement = (imageURL = "") => {
  const ele = document.createElement("img");
  ele.referrerPolicy = "no-referrer";
  ele.src = imageURL;
  return ele;
};

const getName = (a: Attributes) => {
  if (a.given_name && a.family_name) {
    return {
      firstName: a.given_name,
      lastName: a.family_name,
      fullName: `${a.given_name} ${a.family_name}`,
    };
  } else {
    const [firstName, lastName] = a.email
      .split(/[.@_]+/, 2)
      .map((name) => `${name[0].toUpperCase()}${name.slice(1)}`);
    return { firstName, lastName, fullName: `${firstName} ${lastName}` };
  }
};
