import { ComponentProps } from "react";
import { CreateTimelogInput } from "../../../API";
import { useModal } from "../../../components";
import { useTimelogLib } from "../../../lib";
import { Option } from "../Timesheet";
import { TimesheetModal } from "./TimesheetModal";

export const useTimesheetModal = () => {
  const { setTimelogs } = useTimelogLib();
  const { showModal, hideModal } = useModal<
    ComponentProps<typeof TimesheetModal>,
    { contracts: Option[]; row: CreateTimelogInput }
  >({
    component: TimesheetModal,
    data: {
      onSubmit: ({ data: { id, ...data } }) => {
        setTimelogs(
          {
            ...data,
            id: id?.at(1) ? id : undefined,
          },
          { onSettled: hideModal }
        );
      },
      testID: "add-time-entry",
    },
  });

  return {
    showModal,
  };
};
