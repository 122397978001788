import React from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { DatePicker } from "./DatePicker";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  inputFormat?: string;
  onChange: (date: Date | null) => void;
}

const DateControl = <T extends FieldValues>({
  name,
  control,
  label,
  inputFormat = "dd/MM/yyyy",
  onChange,
}: Props<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value } }) => (
      <DatePicker
        value={new Date(value ?? "")}
        label={label}
        inputFormat={inputFormat}
        onChange={onChange}
      />
    )}
  ></Controller>
);

export default DateControl;
