import React, { FC } from "react";
import { Box, IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { TdsLogo } from "../TdsLogo";

type Props = {
  onClick: () => void;
};

export const Mobile: FC<Props> = ({ onClick }) => (
  <Box
    sx={{
      display: { xs: "flex", md: "none" },
      width: 1,
      justifyContent: "space-between",
    }}
  >
    <TdsLogo testID="mob" />
    <IconButton
      aria-label="Navigation"
      edge="end"
      onClick={onClick}
      data-testid="mob-nav-menu-button"
    >
      <Menu />
    </IconButton>
  </Box>
);
