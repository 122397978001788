import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  ApprovalCell,
  ApprovalIcon,
  SummaryCell,
  UserCell,
} from "../../components";
import { HourCategories } from "../../lib";
import { ApprovalRow } from "./utils";

type Props = {
  approveTimesheet: ({
    logIds,
    approved,
  }: {
    logIds: string[];
    approved: boolean;
  }) => void;
};

const hourFields = Object.values(HourCategories).reduce(
  (fields: GridColDef<ApprovalRow>[], cat) => [
    ...fields,
    {
      field: cat,
      headerName: cat[0].toUpperCase() + cat.slice(1),
      type: "number",
      width: 75,
      valueGetter: ({ row }: { row: ApprovalRow }) => row[cat].value,
    },
  ],
  []
);

export const columns = ({
  approveTimesheet,
}: Props): GridColDef<ApprovalRow>[] => [
  {
    field: "approved",
    headerName: "Status",
    width: 60,
    align: "center",
    renderCell: ({ value }) => <ApprovalIcon status={value} />,
  },
  {
    field: "employee",
    headerName: "Employee",
    minWidth: 200,
    flex: 1,
    renderCell: ({ row, value }) => (
      <SummaryCell data={row}>
        <UserCell
          name={value ?? ""}
          clientIDs={row.total ? row.clientIDs : []}
        />
      </SummaryCell>
    ),
  },
  ...hourFields,
  {
    field: "total",
    headerName: "Total",
    type: "number",
    width: 75,
  },
  {
    field: "logIds",
    headerName: "Approval",
    type: "actions",
    minWidth: 200,
    renderCell: ({ value: logIds, row }) => (
      <ApprovalCell
        onApprove={() => {
          approveTimesheet({ logIds, approved: true });
          row.approved = "approved";
        }}
        onReject={() => {
          approveTimesheet({ logIds, approved: false });
          row.approved = "rejected";
        }}
      />
    ),
  },
];

export const hiddenColumnsDesktop: { [key: string]: boolean } = {
  overtime: false,
};

export const hiddenColumnsMobile: { [key: string]: boolean } = {
  ...hiddenColumnsDesktop,
  sick: false,
  holiday: false,
};
