import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContractsModalReturn } from "../components";
import { ClientContracts } from "../API";
import { ApiActions, filterNull, gqlMutation } from ".";

enum ContractType {
  CLIENTS = "Clients",
  EMPLOYEES = "Employees",
}

export const useContractsLib = () => {
  const queryClient = useQueryClient();
  const options = {
    onMutate: async () => {
      await queryClient.cancelQueries(["listClientContracts"]);
      await queryClient.cancelQueries(["listCompanies"]);
      await queryClient.cancelQueries(["listEmployeesWithCompanies"]);
      await queryClient.cancelQueries(["listClients"]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["listClientContracts"]);
      queryClient.invalidateQueries(["listCompanies"]);
      queryClient.invalidateQueries(["listEmployeesWithCompanies"]);
      queryClient.invalidateQueries(["listClients"]);
    },
  };
  const { mutateAsync: createContract } = useMutation({
    ...gqlMutation({ req: "createClientContracts" }),
    ...options,
  });
  const { mutateAsync: updateContract } = useMutation(
    gqlMutation({ req: "updateClientContracts" })
  );
  const { mutateAsync: deleteContract } = useMutation({
    ...gqlMutation({ req: "deleteClientContracts" }),
    ...options,
  });

  const moveContracts = async (employeeID: string, contractIDs: string[]) => {
    contractIDs.forEach((id) =>
      updateContract(
        { input: { id, employeeID } },
        {
          onSettled: () => {
            queryClient.invalidateQueries([
              "getEmployeeWithCompany",
              employeeID,
            ]);
          },
        }
      )
    );
  };

  const diffContracts = async ({
    assigning,
    contracts,
    selected,
    id,
  }: ContractsModalReturn) =>
    assigning === ContractType.CLIENTS
      ? {
          remove: contracts.filter(
            (item) => selected.indexOf(item.clientID) === -1
          ),
          create: selected
            .filter(
              (i) => contracts.map(({ clientID }) => clientID).indexOf(i) === -1
            )
            .map((i) => ({ clientID: i, employeeID: id })),
        }
      : assigning === ContractType.EMPLOYEES
      ? {
          remove: contracts.filter(
            (item) => selected.indexOf(item.employeeID) === -1
          ),
          create: selected
            .filter(
              (i) =>
                contracts.map(({ employeeID }) => employeeID).indexOf(i) === -1
            )
            .map((i) => ({ clientID: id, employeeID: i })),
        }
      : { create: [], remove: [] };

  const setContracts = async (
    data: ContractsModalReturn,
    actions: ApiActions
  ) => {
    const { create, remove } = await diffContracts(data);

    await Promise.all([
      ...remove.map(async ({ id }) => deleteContract({ input: { id } })),
      ...create.map(async (input) => createContract({ input })),
    ]);
    actions.onSettled?.();
  };

  const cleanupContracts = async ({
    id,
    clientContracts,
  }: {
    id: string;
    clientContracts: (ClientContracts | null)[];
  }) => {
    const contracts = await filterNull<ClientContracts>(clientContracts);
    await setContracts(
      { contracts, id, assigning: "Clients", selected: [] },
      {}
    );
  };

  return {
    moveContracts,
    setContracts,
    cleanupContracts,
  };
};
