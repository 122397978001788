import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { ClientContracts } from "../../API";
import { SelectField } from "../OptionField";
import { ModalSkeleton } from "./ModalSkeleton";
import { DefaultModalProps } from "./types";

export type ContractsModalParams = {
  id: string;
  selected: string[];
  contracts: ClientContracts[];
  options: Option[];
};

export type ContractsModalReturn = {
  id: string;
  selected: string[];
  contracts: ClientContracts[];
  assigning: string;
};

type Option = { label: string; value: string; header?: boolean };

type Props = DefaultModalProps & {
  title: string;
  onSubmit: (data: ContractsModalReturn) => void;
  testID: string;
  params: ContractsModalParams;
};

export const ContractsModal: FC<Props> = ({
  title,
  onSubmit,
  testID,
  params: { id, contracts, selected, options },
  ...rest
}) => {
  const [saving, setSaving] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      selected: selected,
    },
  });

  return (
    <ModalSkeleton
      {...rest}
      title={title}
      isForm
      primaryAction={{
        label: "Save",
        pending: saving,
        onSubmit: handleSubmit(({ selected }) => {
          setSaving(true);
          return onSubmit({
            assigning: title,
            id,
            selected,
            contracts,
          });
        }),
        testID: "save",
      }}
      testID={testID}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
          gap: 2,
        }}
      >
        <SelectField
          control={control}
          name="selected"
          label={`Assign ${title}`}
          multiple
          options={options}
          optional
          variant="outlined"
        />
      </Box>
    </ModalSkeleton>
  );
};
