import React, { FC } from "react";
import { Box } from "@mui/material";

type Props = {
  content: string;
};

export const Footer: FC<Props> = ({ content }) => (
  <Box
    sx={{
      pr: 11,
      display: content ? "flex" : "none",
      justifyContent: "flex-end",
    }}
  >
    {content}
  </Box>
);
