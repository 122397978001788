import React, { FC } from "react";
import { Typography } from "@mui/material";
import {
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useResponsive } from "../../../hooks";

export type ToolbarProps = {
  hideColumnSelector?: boolean;
  hideExport?: boolean;
  header?: string;
  csvOptions?: GridCsvExportOptions;
};

export const Toolbar: FC<ToolbarProps> = ({
  hideColumnSelector,
  hideExport,
  header,
  csvOptions,
}) => {
  const { isMd } = useResponsive();
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent:
          hideColumnSelector && !header ? "right" : "space-between",
        mb: hideColumnSelector && hideExport ? 0 : 2,
      }}
    >
      {!hideColumnSelector && (
        <GridToolbarColumnsButton data-testid="grid-column-selector-button" />
      )}
      {!!header && !isMd && <Typography variant={"h6"}>{header}</Typography>}
      {!hideExport && (
        <GridToolbarExport
          variant="outlined"
          csvOptions={{ allColumns: true, ...csvOptions }}
          printOptions={{ disableToolbarButton: true }}
          data-testid="grid-export-button"
        />
      )}
      {!!header && isMd && <Typography variant={"h6"}>{header}</Typography>}
    </GridToolbarContainer>
  );
};
