/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      country
      inactiveAt
      clients {
        items {
          id
          firstName
          lastName
          email
          inactiveAt
          createdAt
          updatedAt
          companyClientsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      country
      inactiveAt
      clients {
        items {
          id
          firstName
          lastName
          email
          inactiveAt
          createdAt
          updatedAt
          companyClientsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      country
      inactiveAt
      clients {
        items {
          id
          firstName
          lastName
          email
          inactiveAt
          createdAt
          updatedAt
          companyClientsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      company {
        id
        name
        country
        inactiveAt
        clients {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      firstName
      lastName
      email
      inactiveAt
      employees {
        items {
          id
          clientID
          employeeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      timelogs {
        items {
          id
          date
          standard
          overtime
          sick
          holiday
          notes
          approved
          onCall
          employeeID
          clientID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyClientsId
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      company {
        id
        name
        country
        inactiveAt
        clients {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      firstName
      lastName
      email
      inactiveAt
      employees {
        items {
          id
          clientID
          employeeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      timelogs {
        items {
          id
          date
          standard
          overtime
          sick
          holiday
          notes
          approved
          employeeID
          clientID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyClientsId
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      company {
        id
        name
        country
        inactiveAt
        clients {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      firstName
      lastName
      email
      inactiveAt
      employees {
        items {
          id
          clientID
          employeeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      timelogs {
        items {
          id
          date
          standard
          overtime
          sick
          holiday
          notes
          approved
          onCall
          employeeID
          clientID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyClientsId
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        items {
          id
          clientID
          employeeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      timelogs {
        items {
          id
          date
          standard
          overtime
          sick
          holiday
          notes
          approved
          onCall
          employeeID
          clientID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        items {
          id
          clientID
          employeeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      timelogs {
        items {
          id
          date
          standard
          overtime
          sick
          holiday
          notes
          approved
          onCall
          employeeID
          clientID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        items {
          id
          clientID
          employeeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      timelogs {
        items {
          id
          date
          standard
          overtime
          sick
          holiday
          notes
          approved
          onCall
          employeeID
          clientID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTimelog = /* GraphQL */ `
  mutation CreateTimelog(
    $input: CreateTimelogInput!
    $condition: ModelTimelogConditionInput
  ) {
    createTimelog(input: $input, condition: $condition) {
      id
      date
      standard
      overtime
      sick
      holiday
      notes
      approved
      employeeID
      clientID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTimelog = /* GraphQL */ `
  mutation UpdateTimelog(
    $input: UpdateTimelogInput!
    $condition: ModelTimelogConditionInput
  ) {
    updateTimelog(input: $input, condition: $condition) {
      id
      date
      standard
      overtime
      sick
      holiday
      notes
      approved
      employeeID
      clientID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTimelog = /* GraphQL */ `
  mutation DeleteTimelog(
    $input: DeleteTimelogInput!
    $condition: ModelTimelogConditionInput
  ) {
    deleteTimelog(input: $input, condition: $condition) {
      id
      date
      standard
      overtime
      sick
      holiday
      notes
      approved
      employeeID
      clientID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClientContracts = /* GraphQL */ `
  mutation CreateClientContracts(
    $input: CreateClientContractsInput!
    $condition: ModelClientContractsConditionInput
  ) {
    createClientContracts(input: $input, condition: $condition) {
      id
      clientID
      employeeID
      client {
        id
        company {
          id
          name
          country
          inactiveAt
          createdAt
          updatedAt
          __typename
        }
        firstName
        lastName
        email
        inactiveAt
        employees {
          nextToken
          __typename
        }
        timelogs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyClientsId
        __typename
      }
      employee {
        id
        firstName
        lastName
        email
        country
        region
        vacationAllowance
        inactiveAt
        clients {
          nextToken
          __typename
        }
        timelogs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClientContracts = /* GraphQL */ `
  mutation UpdateClientContracts(
    $input: UpdateClientContractsInput!
    $condition: ModelClientContractsConditionInput
  ) {
    updateClientContracts(input: $input, condition: $condition) {
      id
      clientID
      employeeID
      client {
        id
        company {
          id
          name
          country
          inactiveAt
          createdAt
          updatedAt
          __typename
        }
        firstName
        lastName
        email
        inactiveAt
        employees {
          nextToken
          __typename
        }
        timelogs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyClientsId
        __typename
      }
      employee {
        id
        firstName
        lastName
        email
        country
        region
        vacationAllowance
        inactiveAt
        clients {
          nextToken
          __typename
        }
        timelogs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClientContracts = /* GraphQL */ `
  mutation DeleteClientContracts(
    $input: DeleteClientContractsInput!
    $condition: ModelClientContractsConditionInput
  ) {
    deleteClientContracts(input: $input, condition: $condition) {
      id
      clientID
      employeeID
      client {
        id
        company {
          id
          name
          country
          inactiveAt
          createdAt
          updatedAt
          __typename
        }
        firstName
        lastName
        email
        inactiveAt
        employees {
          nextToken
          __typename
        }
        timelogs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyClientsId
        __typename
      }
      employee {
        id
        firstName
        lastName
        email
        country
        region
        vacationAllowance
        inactiveAt
        clients {
          nextToken
          __typename
        }
        timelogs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
