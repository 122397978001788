import React, { ElementType, FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useUser } from "../../hooks";
import { sortedLinks } from "./links";

const Nav = styled(List)<{ component?: ElementType }>({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

type Props = {
  onClose: () => void;
  testID: string;
};

export const NavList: FC<Props> = ({ onClose, testID }) => {
  const { pathname } = useLocation();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const isAdmin = useUser()?.isAdmin;
  const { Admin, ...rest } = sortedLinks;
  const links = isAdmin ? { ...rest, Admin } : rest;

  return (
    <Nav component="nav" disablePadding data-testid={`${testID}-nav`}>
      {Object.keys(links).map((item) => (
        <div key={item}>
          <ListSubheader component="div" sx={{ pl: 3 }}>
            {item}
          </ListSubheader>

          {links[item].map((link) => (
            <ListItemButton
              component={NavLink}
              to={link.to}
              selected={pathname === `/${link.to}`}
              key={link.name}
              onClick={() => onClose()}
              data-testid={`${testID}-nav-link-${link.name.toLowerCase()}`}
            >
              <ListItemIcon sx={{ fontSize: 20 }}>{link.icon}</ListItemIcon>
              <ListItemText primary={link.name} />
            </ListItemButton>
          ))}
        </div>
      ))}

      <Box sx={{ display: { xs: "block", md: "none" }, mt: 3 }}>
        <ListItemButton
          component={"a"}
          onClick={() => {
            onClose();
            signOut();
          }}
          data-testid="mob-nav-link-logout"
        >
          <ListItemIcon sx={{ fontSize: 20 }}>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </Box>
    </Nav>
  );
};
