import { Employee, ModelTimelogFilterInput, Timelog } from "../../API";
import {
  filterNull,
  gqlQuery,
  HourCategories,
  QueryType,
  toDbDates,
} from "../../lib";

type QueryProps = {
  employeeID: string;
  companyID: string;
  week: Interval | null;
  logFilter?: ModelTimelogFilterInput | null | undefined;
};

const emptyApproval = {
  log: {
    standard: { value: 0, dates: [] },
    overtime: { value: 0, dates: [] },
    sick: { value: 0, dates: [] },
    holiday: { value: 0, dates: [] },
  },
  approved: false,
  clientIDs: [],
  logIds: [],
};

export const approvalQuery =
  ({ week, employeeID, companyID, logFilter }: QueryProps) =>
  (input: Omit<QueryType, "req">) => {
    if (week === null) {
      throw new Error(`Invalid date range. Value is ${week}`);
    }
    const dateRange = toDbDates(week);
    const limit = 1000;
    const { queryFn, queryKey } = gqlQuery({
      req: "listEmployeesWithTimelogs",
      vars: {
        limit,
        ...input.vars,
        ...(employeeID.length > 0 && { employeeID }),
        ...(companyID.length > 0 && { companyID }),
        logFilter,
        dateRange,
      },
      key:
        employeeID || companyID
          ? [dateRange, { companyID, employeeID }, ...(input.key ?? [])]
          : [dateRange, ...(input.key ?? [])],
    });

    return {
      queryKey,
      queryFn: async () => {
        const { data, errors } = await queryFn();
        const employees = await filterNull(
          data?.listEmployees?.items ?? [],
          (e) => !!(e.clients?.items.length || e.timelogs?.items.length)
        );
        return {
          employees,
          items: await formatApprovalData(employees, !!logFilter),
          nextToken: data?.listEmployees?.nextToken ?? undefined,
          errors,
        };
      },
      enabled: !companyID || !!logFilter,
    };
  };

const formatApprovalData = async (
  employees: Employee[],
  filterClients: boolean
): Promise<ApprovalRow[]> =>
  Promise.all(
    (filterClients
      ? employees.filter((e) => (e.timelogs?.items.length ?? 0) > 0)
      : employees
    ).map(buildApprovalRow)
  );

const buildApprovalRow = async ({
  timelogs,
  id,
  ...data
}: Employee): Promise<ApprovalRow> => {
  const employee = `${data?.firstName} ${data?.lastName}`;

  const { log, approved, clientIDs, logIds } = (
    await filterNull<Timelog>(timelogs?.items ?? [])
  ).reduce(buildApprovalItem, emptyApproval);

  return {
    id,
    approved: approvedToString(approved),
    employee,
    clientIDs: [...new Set(clientIDs)],
    ...log,
    total: Object.values(log).reduce((a, b) => a + b.value, 0),
    logIds,
  };
};

const buildApprovalItem = (
  { log, approved, clientIDs, logIds }: ApprovalItem,
  timelog: Timelog
): ApprovalItem => ({
  log: buildLogs(timelog, log),
  approved: approved === null ? null : timelog.approved ?? null,
  clientIDs: timelog.clientID ? [...clientIDs, timelog.clientID] : clientIDs,
  logIds: [...logIds, timelog.id],
});

const buildLogs = (log: Timelog, init: DateHours) =>
  Object.values(HourCategories).reduce(
    (curr, cat) => ({
      ...curr,
      [cat]: {
        value: curr[cat].value + (log[cat] ?? 0),
        dates: curr[cat].dates.concat({
          date: log.date,
          hours: log[cat] ?? 0,
        }),
      },
    }),
    init
  );

const approvedToString = (val: boolean | null) =>
  val === null ? "pending" : val ? "approved" : "rejected";

export type ApprovalRow = DateHours & {
  id: string;
  approved: string;
  employee: string;
  clientIDs: string[];
  total: number;
  logIds: string[];
};

export type DateHours = {
  [cat in HourCategories]: {
    value: number;
    dates: {
      date: string;
      hours: number;
    }[];
  };
};

type ApprovalItem = {
  log: DateHours;
  approved: boolean | null;
  clientIDs: string[];
  logIds: string[];
};
