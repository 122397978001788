import React, { useState, useCallback, useEffect } from "react";
import { GridValidRowModel } from "@mui/x-data-grid";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { QueryType } from "../../lib/types";
import { PaginationMode } from "..";
import { filterNull } from "../../lib";
import { Employee } from "../../API";

// Integrate into a gql limit variable
// NOTE: limits are applied before filters, so it may appear less was
// fetched than intended
const PAGE_SIZE = 100;

// (input: Omit<QueryType, "req">) => gqlQuery({});
type QueryOptions = (input: Omit<QueryType, "req">) => {
  queryKey: QueryType["key"];
  queryFn: () => Promise<{
    employees?: Employee[];
    items?: (GridValidRowModel | null)[];
    nextToken?: string | null;
    errors?: unknown[];
  }>;
  enabled?: boolean;
};

export const useQueryPagination = (query: QueryOptions) => {
  const queryClient = useQueryClient();
  const pageToken = React.useRef<{ [page: number]: string }>({});
  const [page, setPage] = useState(0);

  const input = useCallback(
    (page: number) => ({
      vars: { nextToken: pageToken.current[page - 1] },
      key: [page],
    }),
    []
  );

  const rowsQuery = useCallback(
    (page: number) => {
      const { queryKey: key, queryFn, ...rest } = query(input(page));
      return {
        ...rest,
        queryKey: key ? [...key, page] : [page],
        queryFn: async () => {
          const { items, nextToken, ...rest } = await queryFn();
          return {
            ...rest,
            items: await filterNull(items ?? []),
            nextToken: nextToken ?? undefined,
          };
        },
      };
    },
    [input, query]
  );

  const { data, isError, isLoading, refetch, isFetching, isPreviousData } =
    useQuery({
      ...rowsQuery(page),
      keepPreviousData: true,
      staleTime: 5000,
    });

  // Prefetch next page
  useEffect(() => {
    if (data?.nextToken) {
      pageToken.current[page] = data.nextToken;
      queryClient.prefetchQuery(rowsQuery(page + 1));
    }
  }, [data?.nextToken, input, page, query, queryClient, rowsQuery]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(data?.items.length || 0);

  // With Dynamo total rows are unknown w/o expensive query
  // Adds 1 to row count if nextToken is defined to allow pagination
  useEffect(() => {
    setRowCountState((prev) => {
      if (!pageToken.current[page]) {
        return page * PAGE_SIZE + (data?.items.length ?? 0);
      } else {
        return prev > (page + 1) * PAGE_SIZE
          ? prev
          : (page + 1) * PAGE_SIZE + 1;
      }
    });
  }, [data?.items.length, page, setRowCountState]);

  const handlePageChange = (newPage: number) => {
    if (newPage === 0 || pageToken.current[newPage - 1]) {
      setPage(newPage);
    }
  };

  return {
    data,
    isError,
    refetch,
    isPending: isLoading || isPreviousData || isFetching,
    pagination: {
      page,
      paginationMode: PaginationMode.SERVER,
      rowCount: rowCountState,
      onPageChange: handlePageChange,
    },
  };
};
