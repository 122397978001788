import React from "react";
import { Dashboard as DashboardIcon } from "@mui/icons-material";
import { PageTitle } from "../../components";
import { useUser } from "../../hooks";

export const Main = () => {
  const u = useUser();

  return (
    <>
      <PageTitle
        icon={<DashboardIcon />}
        title="Dashboard"
        testID="dashboard"
      />

      {u && <h1>Welcome back {u.fullName}!</h1>}
      {u && <h4>Your current TDS email is {u.email}!</h4>}
    </>
  );
};
