import React, { ReactElement, useMemo } from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { Tooltip } from "../Tooltip";
import { TooltipCell } from "./Cells";

export type RowAction = {
  disabled?: boolean;
  icon: ReactElement;
  label: string;
  onClick: (params: GridRowParams) => void;
};

type Props = {
  columns: GridColDef[];
  rowActions?: RowAction[];
};

export const useColumns = ({ columns, rowActions }: Props) =>
  useMemo(
    () => [
      ...(columns
        ? columns.map((column) =>
            column.type !== "singleSelect"
              ? {
                  renderCell: ({ value }: GridRenderCellParams) => (
                    <TooltipCell>{value}</TooltipCell>
                  ),
                  ...column,
                }
              : column
          )
        : []),
      ...(rowActions
        ? [
            {
              field: "actions",
              type: "actions",
              headerName: "",
              cellClassName: "actions",
              width: rowActions.length > 1 ? 50 * rowActions.length : 75,
              getActions: (params: GridRowParams) =>
                rowActions.map(({ label, onClick, icon, disabled }, idx) => {
                  const Action = (
                    <GridActionsCellItem
                      disabled={disabled}
                      label={label}
                      onClick={() => onClick(params)}
                      icon={icon}
                      data-testid={`grid-${label.toLowerCase()}-button`}
                    />
                  );
                  return disabled ? (
                    Action
                  ) : (
                    <Tooltip title={label} placement="top" key={idx}>
                      {Action}
                    </Tooltip>
                  );
                }),
            },
          ]
        : []),
    ],
    [columns, rowActions]
  );
