import React, { forwardRef } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useGetClient } from "../../../lib";

type Props = {
  name: string;
  info?: string;
  clientIDs: string[];
  image?: string;
};

const SubText = ({ info }: Pick<Props, "info">) =>
  info ? (
    <Typography variant="overline" sx={{ display: "flex", lineHeight: 1 }}>
      <InfoOutlinedIcon color="inherit" fontSize="inherit" sx={{ mr: 0.25 }} />
      {info}
    </Typography>
  ) : null;

const TextArea = ({ name, info }: Pick<Props, "name" | "info">) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
    <Typography variant="button" sx={{ lineHeight: 1 }}>
      {name}
    </Typography>
    <SubText info={info} />
  </Box>
);

const UserComponent = (
  { name, info, clientIDs = [], image = "/", ...rest }: Props,
  ref?: React.Ref<unknown>
) => {
  const companies = useGetClient(clientIDs).map(({ company }) => company.name);
  const props = { name, info: info ?? companies.join() };
  return (
    <Box
      {...rest}
      ref={ref}
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
    >
      <Avatar
        sx={{ width: 24, height: 24 }}
        alt={name.toUpperCase()}
        srcSet={image}
      />
      <TextArea {...props} />
    </Box>
  );
};

export const UserCell = forwardRef(UserComponent);
