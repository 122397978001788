import React, { FC } from "react";
import { LinearProgress, Skeleton } from "@mui/material";
import { GridRowsProp } from "@mui/x-data-grid";

type Props = {
  data: GridRowsProp;
  rows?: number;
};

export const LoadingState: FC<Props> = ({ data = [], rows = 5 }) => (
  <>
    {data.length ? (
      <LinearProgress sx={{ postition: "relative", zIndex: 1 }} />
    ) : (
      [...Array(rows)].map((_, idx) => (
        <Skeleton
          animation="wave"
          height={52}
          key={idx}
          sx={{ mb: 6.5, bgcolor: "grey.50" }}
          variant="rectangular"
        />
      ))
    )}
  </>
);
