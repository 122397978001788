const m = {
  L: {
    "0": [
      [1.75347045673, 0, 0],
      [0.03341656453, 4.66925680415, 6283.0758499914],
      [0.00034894275, 4.62610242189, 12566.1516999828],
      [0.00003417572, 2.82886579754, 3.523118349],
      [0.00003497056, 2.74411783405, 5753.3848848968],
      [0.00003135899, 3.62767041756, 77713.7714681205],
      [0.00002676218, 4.41808345438, 7860.4193924392],
      [0.00002342691, 6.13516214446, 3930.2096962196],
      [0.00001273165, 2.03709657878, 529.6909650946],
      [0.00001324294, 0.74246341673, 11506.7697697936],
      [0.00000901854, 2.04505446477, 26.2983197998],
      [0.00001199167, 1.10962946234, 1577.3435424478],
      [0.00000857223, 3.50849152283, 398.1490034082],
      [0.00000779786, 1.17882681962, 5223.6939198022],
      [0.0000099025, 5.23268072088, 5884.9268465832],
      [0.00000753141, 2.53339052847, 5507.5532386674],
      [0.00000505267, 4.58292599973, 18849.2275499742],
      [0.00000492392, 4.20505711826, 775.522611324],
      [0.00000356672, 2.91954114478, 0.0673103028],
      [0.00000284125, 1.89869240932, 796.2980068164],
      [0.00000242879, 0.34481445893, 5486.777843175],
      [0.00000317087, 5.84901948512, 11790.6290886588],
      [0.00000271112, 0.31486255375, 10977.078804699],
      [0.00000206217, 4.80646631478, 2544.3144198834],
      [0.00000205478, 1.86953770281, 5573.1428014331],
      [0.00000202318, 2.45767790232, 6069.7767545534],
      [0.00000126225, 1.08295459501, 20.7753954924],
      [0.00000155516, 0.83306084617, 213.299095438],
      [0.00000115132, 0.64544911683, 0.9803210682],
      [0.00000102851, 0.63599845579, 4694.0029547076],
      [0.00000101724, 4.2667980198, 7.1135470008],
      [9.9206e-7, 6.20992926918, 2146.1654164752],
      [0.00000132212, 3.41118292683, 2942.4634232916],
      [9.7607e-7, 0.68101342359, 155.4203994342],
      [8.5128e-7, 1.29870764804, 6275.9623029906],
      [7.4651e-7, 1.755089133, 5088.6288397668],
      [0.00000101895, 0.97569280312, 15720.8387848784],
      [8.4711e-7, 3.67080093031, 71430.69561812909],
      [7.3547e-7, 4.67926633877, 801.8209311238],
      [7.3874e-7, 3.50319414955, 3154.6870848956],
      [7.8757e-7, 3.03697458703, 12036.4607348882],
      [7.9637e-7, 1.80791287082, 17260.1546546904],
      [8.5803e-7, 5.9832263126, 161000.6857376741],
      [5.6963e-7, 2.78430458592, 6286.5989683404],
      [6.1148e-7, 1.81839892984, 7084.8967811152],
      [6.9627e-7, 0.83297621398, 9437.762934887],
      [5.6116e-7, 4.38694865354, 14143.4952424306],
      [6.2449e-7, 3.97763912806, 8827.3902698748],
      [5.1145e-7, 0.28306832879, 5856.4776591154],
      [5.5577e-7, 3.47006059924, 6279.5527316424],
      [4.1036e-7, 5.36817592855, 8429.2412664666],
      [5.1605e-7, 1.33282739866, 1748.016413067],
      [5.1992e-7, 0.18914947184, 12139.5535091068],
      [4.9e-7, 0.48735014197, 1194.4470102246],
      [3.92e-7, 6.16833020996, 10447.3878396044],
      [3.557e-7, 1.775968892, 6812.766815086],
      [3.677e-7, 6.04133863162, 10213.285546211],
      [3.6596e-7, 2.56957481827, 1059.3819301892],
      [3.3296e-7, 0.59310278598, 17789.845619785],
      [3.5954e-7, 1.70875808777, 2352.8661537718],
      [4.0938e-7, 2.39850938714, 19651.048481098],
      [3.0047e-7, 2.73975124088, 1349.8674096588],
      [3.0412e-7, 0.44294464169, 83996.84731811189],
      [2.3663e-7, 0.48473622521, 8031.0922630584],
      [2.3574e-7, 2.06528133162, 3340.6124266998],
      [2.1089e-7, 4.14825468851, 951.7184062506],
      [2.4738e-7, 0.21484762138, 3.5904286518],
      [2.5352e-7, 3.16470891653, 4690.4798363586],
      [2.2823e-7, 5.22195230819, 4705.7323075436],
      [2.1419e-7, 1.42563910473, 16730.4636895958],
      [2.1891e-7, 5.55594302779, 553.5694028424],
      [1.7481e-7, 4.56052900312, 135.0650800354],
      [1.9927e-7, 5.22209149316, 12168.0026965746],
      [1.986e-7, 5.77470242235, 6309.3741697912],
      [2.03e-7, 0.37133792946, 283.8593188652],
      [1.4421e-7, 4.19315052005, 242.728603974],
      [1.6225e-7, 5.98837767951, 11769.8536931664],
      [1.5077e-7, 4.1956716337, 6256.7775301916],
      [1.9124e-7, 3.82219958698, 23581.2581773176],
      [1.8888e-7, 5.38626892076, 149854.4001348079],
      [1.4346e-7, 3.72355084422, 38.0276726358],
      [1.7898e-7, 2.21490566029, 13367.9726311066],
      [1.2054e-7, 2.62229602614, 955.5997416086],
      [1.1287e-7, 0.17739329984, 4164.311989613],
      [1.3973e-7, 4.40134615007, 6681.2248533996],
      [1.3621e-7, 1.88934516495, 7632.9432596502],
      [1.2503e-7, 1.13052412208, 5.5229243074],
      [1.0498e-7, 5.35909979317, 1592.5960136328],
      [9.803e-8, 0.99948172646, 11371.7046897582],
      [9.22e-8, 4.57138585348, 4292.3308329504],
      [1.0327e-7, 6.19982170609, 6438.4962494256],
      [1.2003e-7, 1.00351462266, 632.7837393132],
      [1.0827e-7, 0.32734523824, 103.0927742186],
      [8.356e-8, 4.53902748706, 25132.3033999656],
      [1.0005e-7, 6.0291496328, 5746.271337896],
      [8.409e-8, 3.29946177848, 7234.794256242],
      [8.006e-8, 5.82145271855, 28.4491874678],
      [1.0523e-7, 0.93870455544, 11926.2544136688],
      [7.686e-8, 3.1214364064, 7238.6755916],
      [9.378e-8, 2.62413793196, 5760.4984318976],
      [8.127e-8, 6.11227839253, 4732.0306273434],
      [9.232e-8, 0.48344234496, 522.5774180938],
      [9.802e-8, 5.24413877132, 27511.4678735372],
      [7.871e-8, 0.99590133077, 5643.1785636774],
      [8.123e-8, 6.27053020099, 426.598190876],
      [9.048e-8, 5.33686323585, 6386.16862421],
      [8.621e-8, 4.16537179089, 7058.5984613154],
      [6.297e-8, 4.71723143652, 6836.6452528338],
      [7.575e-8, 3.97381357237, 11499.6562227928],
      [7.756e-8, 2.95728422442, 23013.5395395872],
      [7.314e-8, 0.60652522715, 11513.8833167944],
      [5.955e-8, 2.87641047954, 6283.14316029419],
      [6.534e-8, 5.79046406784, 18073.7049386502],
      [7.188e-8, 3.99831461988, 74.7815985673],
      [7.346e-8, 4.38582423903, 316.3918696566],
      [5.413e-8, 5.39199023275, 419.4846438752],
      [5.127e-8, 2.36059551778, 10973.55568635],
      [7.056e-8, 0.32258442532, 263.0839233728],
      [6.624e-8, 3.6647416584, 17298.1823273262],
      [6.762e-8, 5.91131766896, 90955.5516944961],
      [4.938e-8, 5.73672172371, 9917.6968745098],
      [5.547e-8, 2.45152589382, 12352.8526045448],
      [5.958e-8, 3.3205134466, 6283.0085396886],
      [4.471e-8, 2.06386138131, 7079.3738568078],
      [6.153e-8, 1.45823347458, 233141.3144043615],
      [4.348e-8, 4.42338625285, 5216.5803728014],
      [6.124e-8, 1.07494838623, 19804.8272915828],
      [4.488e-8, 3.65285033073, 206.1855484372],
      [4.02e-8, 0.83995823171, 20.3553193988],
      [5.188e-8, 4.06503864016, 6208.2942514241],
      [5.307e-8, 0.38216728132, 31441.6775697568],
      [3.785e-8, 2.34369213733, 3.881335358],
      [4.497e-8, 3.27230792447, 11015.1064773348],
      [4.132e-8, 0.92129851256, 3738.761430108],
      [3.521e-8, 5.9784480361, 3894.1818295422],
      [4.215e-8, 1.90601721876, 245.8316462294],
      [3.701e-8, 5.03067498875, 536.8045120954],
      [3.866e-8, 1.82632980909, 11856.2186514245],
      [3.652e-8, 1.01840564429, 16200.7727245012],
      [3.39e-8, 0.97784870142, 8635.9420037632],
      [3.737e-8, 2.9537891957, 3128.3887650958],
      [3.507e-8, 3.71291946317, 6290.1893969922],
      [3.086e-8, 3.64646921512, 10.6366653498],
      [3.397e-8, 1.10589356888, 14712.317116458],
      [3.334e-8, 0.83684903082, 6496.3749454294],
      [2.805e-8, 2.58503711584, 14314.1681130498],
      [3.65e-8, 1.08344142571, 88860.05707098669],
      [3.388e-8, 3.20182380957, 5120.6011455836],
      [3.252e-8, 3.47857474229, 6133.5126528568],
      [2.553e-8, 3.9486902726, 1990.745017041],
      [3.52e-8, 2.05559692878, 244287.60000722768],
      [2.565e-8, 1.56072409371, 23543.23050468179],
      [2.621e-8, 3.85639359951, 266.6070417218],
      [2.954e-8, 3.39692614359, 9225.539273283],
      [2.876e-8, 6.02633318445, 154717.6098876827],
      [2.395e-8, 1.16130078696, 10984.1923516998],
      [3.161e-8, 1.32798862116, 10873.9860304804],
      [3.163e-8, 5.08946546862, 21228.3920235458],
      [2.361e-8, 4.27212461943, 6040.3472460174],
      [3.03e-8, 1.80210001168, 35371.8872659764],
      [2.343e-8, 3.57688971514, 10969.9652576982],
      [2.618e-8, 2.57870151918, 22483.84857449259],
      [2.113e-8, 3.71711179417, 65147.6197681377],
      [2.019e-8, 0.81393923319, 170.6728706192],
      [2.003e-8, 0.38091017375, 6172.869528772],
      [2.506e-8, 3.74378169126, 10575.4066829418],
      [2.381e-8, 0.10581361289, 7.046236698],
      [1.949e-8, 4.86892513469, 36.0278666774],
      [2.074e-8, 4.22802468213, 5650.2921106782],
      [1.924e-8, 5.59460549844, 6282.0955289232],
      [1.949e-8, 1.06999605576, 5230.807466803],
      [1.988e-8, 5.19734705445, 6262.300454499],
      [1.887e-8, 3.74365662683, 23.8784377478],
      [1.787e-8, 1.25929659066, 12559.038152982],
      [1.883e-8, 1.90364058477, 15.252471185],
      [1.816e-8, 3.68083794819, 15110.4661198662],
      [1.701e-8, 4.41109562589, 110.2063212194],
      [1.99e-8, 3.93295788548, 6206.8097787158],
      [2.103e-8, 0.75354936681, 13521.7514415914],
      [1.774e-8, 0.48750515837, 1551.045222648],
      [1.882e-8, 0.86685462305, 22003.9146348698],
      [1.924e-8, 1.22901099088, 709.9330485583],
      [2.073e-8, 4.62531597856, 6037.244203762],
      [1.924e-8, 0.60231842492, 6284.0561710596],
      [1.596e-8, 3.98332879712, 13916.0191096416],
      [1.664e-8, 4.41947015623, 8662.240323563],
      [1.971e-8, 1.04560686192, 18209.33026366019],
      [1.942e-8, 4.31335979989, 6244.9428143536],
      [1.476e-8, 0.93274523818, 2379.1644735716],
      [1.81e-8, 0.49112137707, 1.4844727083],
      [1.346e-8, 1.51574753411, 4136.9104335162],
      [1.528e-8, 5.61833568587, 6127.6554505572],
      [1.791e-8, 3.22191142746, 39302.096962196],
      [1.747e-8, 3.05595292589, 18319.5365848796],
      [1.432e-8, 4.51123984264, 20426.571092422],
      [1.695e-8, 0.22049418623, 25158.6017197654],
      [1.242e-8, 4.46665354536, 17256.6315363414],
      [1.463e-8, 4.69248613506, 14945.3161735544],
      [1.205e-8, 1.86911906771, 4590.910180489],
      [1.19e-8, 2.74169967367, 12569.6748183318],
      [1.222e-8, 5.18120087481, 5333.9002410216],
      [1.39e-8, 5.42888623322, 143571.32428481648],
      [1.473e-8, 1.70487100866, 11712.9553182308],
      [1.362e-8, 2.61069503292, 6062.6632075526],
      [1.148e-8, 6.0300843061, 3634.6210245184],
      [1.198e-8, 5.15296117339, 10177.2576795336],
      [1.266e-8, 0.11422490557, 18422.62935909819],
      [1.411e-8, 1.09910890045, 3496.032826134],
      [1.349e-8, 2.99804623019, 17654.7805397496],
      [1.253e-8, 2.79844902576, 167283.7615876655],
      [1.311e-8, 1.609410743, 5481.2549188676],
      [1.079e-8, 6.20304501787, 3.2863574178],
      [1.181e-8, 1.20653777627, 131.5419616864],
      [1.254e-8, 5.45103277799, 6076.8903015542],
      [1.036e-8, 2.32136959491, 7342.4577801806],
      [1.117e-8, 0.38842340979, 949.1756089698],
      [9.66e-9, 3.18352079941, 11087.2851259184],
      [1.171e-8, 3.39635167732, 12562.6285816338],
      [1.121e-8, 0.72631814699, 220.4126424388],
      [1.024e-8, 2.19381113265, 11403.676995575],
      [8.88e-9, 3.91167196431, 4686.8894077068],
      [9.1e-9, 1.98802695087, 735.8765135318],
      [8.23e-9, 0.48822202854, 24072.9214697764],
      [1.096e-8, 6.17377835617, 5436.9930152402],
      [9.08e-9, 0.44959148878, 7477.522860216],
      [9.74e-9, 1.52996313552, 9623.6882766912],
      [8.4e-9, 1.79540573407, 5429.8794682394],
      [7.78e-9, 6.17703744517, 38.1330356378],
      [7.76e-9, 4.09859968447, 14.2270940016],
      [1.068e-8, 4.64209577648, 43232.3066584156],
      [9.54e-9, 1.49985885818, 1162.4747044078],
      [9.07e-9, 0.86986870809, 10344.2950653858],
      [9.31e-9, 4.06049877517, 28766.924424484],
      [7.39e-9, 5.04368192034, 639.897286314],
      [9.65e-9, 3.44286716197, 1589.0728952838],
      [7.63e-9, 5.86304776787, 16858.4825329332],
      [9.53e-9, 4.20801492835, 11190.377900137],
      [7.08e-9, 1.72432323967, 13095.8426650774],
      [9.69e-9, 1.64437243011, 29088.811415985],
      [7.17e-9, 0.16688678895, 11.729352836],
      [9.62e-9, 3.53101876172, 12416.5885028482],
      [7.45e-9, 5.77741082302, 12592.4500197826],
      [6.72e-9, 1.91091228744, 3.9321532631],
      [6.71e-9, 5.46240758839, 18052.9295431578],
      [6.75e-9, 6.28311649798, 4535.0594369244],
      [6.84e-9, 0.39975011401, 5849.3641121146],
      [7.99e-9, 0.29859056777, 12132.439962106],
      [7.58e-9, 0.96370719224, 1052.2683831884],
      [7.82e-9, 5.33875702541, 13517.8701062334],
      [7.3e-9, 1.70114998543, 17267.26820169119],
      [7.49e-9, 2.59607005624, 11609.8625440122],
      [7.34e-9, 2.7842049778, 640.8776073822],
      [6.88e-9, 5.15097673557, 16496.3613962024],
      [7.7e-9, 1.62459252416, 4701.1165017084],
      [6.33e-9, 2.20588443066, 25934.1243310894],
      [7.6e-9, 4.21317219403, 377.3736079158],
      [5.84e-9, 2.13420121623, 10557.5941608238],
      [5.72e-9, 0.24649745829, 9779.1086761254],
      [5.73e-9, 3.16435264609, 533.2140834436],
      [6.85e-9, 3.19344289472, 12146.6670561076],
      [6.75e-9, 0.96179234176, 10454.5013866052],
      [6.48e-9, 1.46327342554, 6268.8487559898],
      [5.89e-9, 2.50543543638, 3097.88382272579],
      [5.51e-9, 5.28106257475, 9388.0059094152],
      [6.96e-9, 3.6534215555, 4804.209275927],
      [6.69e-9, 2.5103005926, 2388.8940204492],
      [5.5e-9, 0.06883090057, 20199.094959633],
      [6.29e-9, 4.13350997495, 45892.73043315699],
      [6.78e-9, 6.09190163533, 135.62532501],
      [5.93e-9, 1.50129499103, 226858.23855437007],
      [5.42e-9, 3.58582033525, 6148.010769956],
      [5.99e-9, 6.12058050643, 18875.525869774],
      [6.82e-9, 5.0221361683, 17253.04110768959],
      [5.65e-9, 4.29309214275, 11933.3679606696],
      [4.86e-9, 0.77746204893, 27.4015560968],
      [5.03e-9, 0.58974557727, 15671.0817594066],
      [6.16e-9, 4.06539884128, 227.476132789],
      [5.37e-9, 2.15064382406, 21954.15760939799],
      [6.69e-9, 6.06995500278, 47162.5163546352],
      [5.4e-9, 2.83444221432, 5326.7866940208],
      [4.74e-9, 0.40346826846, 6915.8595893046],
      [5.32e-9, 5.26131065063, 10988.808157535],
      [5.82e-9, 3.24533095664, 153.7788104848],
      [6.41e-9, 3.24711790399, 2107.0345075424],
      [6.19e-9, 3.08302108547, 33019.0211122046],
      [4.66e-9, 3.14982369789, 10440.2742926036],
      [4.66e-9, 0.90708835651, 5966.6839803348],
      [5.28e-9, 0.8192645447, 813.5502839598],
      [6.03e-9, 3.81378921927, 316428.22867391503],
      [5.59e-9, 1.8189498573, 17996.0311682222],
      [4.37e-9, 2.28631745987, 6303.8512454838],
      [5.18e-9, 4.86068318058, 20597.2439630412],
      [4.24e-9, 6.23520018697, 6489.2613984286],
      [5.18e-9, 6.17617826756, 0.2438174835],
      [4.04e-9, 5.72804304258, 5642.1982426092],
      [4.58e-9, 1.34117773914, 6287.0080032545],
      [5.48e-9, 5.6845445832, 155427.542936241],
      [5.47e-9, 1.03391472434, 3646.3503773544],
      [4.28e-9, 4.6980968782, 846.0828347512],
      [4.13e-9, 6.0252069939, 6279.4854213396],
      [5.34e-9, 3.03030638223, 66567.48586525429],
      [3.83e-9, 1.4905558804, 19800.9459562248],
      [4.11e-9, 5.28384176408, 18451.07854656599],
      [3.52e-9, 4.68891600525, 4907.3020501456],
      [4.8e-9, 5.36572651091, 348.924420448],
      [3.44e-9, 5.89157452889, 6546.1597733642],
      [3.4e-9, 0.37557440365, 13119.72110282519],
      [4.34e-9, 4.98417856239, 6702.5604938666],
      [3.32e-9, 2.6890934443, 29296.6153895786],
      [4.48e-9, 2.16478480251, 5905.7022420756],
      [3.44e-9, 2.06546633735, 49.7570254718],
      [3.15e-9, 1.24023810969, 4061.2192153944],
      [3.24e-9, 2.30897526929, 5017.508371365],
      [4.13e-9, 0.17171692945, 6286.6662786432],
      [4.31e-9, 3.8660110138, 12489.8856287072],
      [3.49e-9, 4.55372493131, 4933.2084403326],
      [3.23e-9, 0.41971136084, 10770.8932562618],
      [3.41e-9, 2.68612860807, 11.0457002639],
      [3.16e-9, 3.52966641606, 17782.7320727842],
      [3.15e-9, 5.63357264999, 568.8218740274],
      [3.4e-9, 3.83571212349, 10660.6869350424],
      [2.96e-9, 0.62703270489, 20995.3929664494],
      [4.05e-9, 1.00084965393, 16460.33352952499],
      [4.14e-9, 1.21998752076, 51092.7260508548],
      [3.36e-9, 4.71465945215, 6179.9830757728],
      [3.61e-9, 3.71235613733, 28237.2334593894],
      [3.27e-9, 1.05606504715, 11919.140866668],
      [3.27e-9, 6.1422242098, 6254.6266625236],
      [2.68e-9, 2.47224339737, 664.75604513],
      [2.69e-9, 1.86210872453, 23141.5583829246],
      [3.45e-9, 0.93461290184, 6058.7310542895],
      [3.53e-9, 4.50033650657, 36949.2308084242],
      [3.44e-9, 6.26166140367, 24356.7807886416],
      [3e-9, 4.46964001975, 6418.1409300268],
      [2.6e-9, 4.04967464725, 6525.8044539654],
      [2.98e-9, 2.20018811054, 156137.47598479927],
      [2.53e-9, 3.49930797865, 29864.334027309],
      [2.54e-9, 2.44883530154, 5331.3574437408],
      [2.96e-9, 0.84341183907, 5729.506447149],
      [2.41e-9, 2.00721298729, 16737.5772365966],
      [3.11e-9, 1.23668016336, 6281.5913772831],
      [3.32e-9, 3.55576945724, 7668.6374249425],
      [2.36e-9, 2.47437156031, 6245.0481773556],
      [2.64e-9, 4.43924412283, 12964.300703391],
      [2.57e-9, 1.79654471948, 11080.1715789176],
      [2.6e-9, 3.3307759606, 5888.4499649322],
      [2.85e-9, 0.3088636143, 11823.1616394502],
      [2.9e-9, 5.70141882483, 77.673770428],
      [2.55e-9, 4.00939662024, 5881.4037282342],
      [2.53e-9, 4.73318512715, 16723.350142595],
      [2.28e-9, 0.95333661324, 5540.0857894588],
      [2.81e-9, 1.29199646396, 22805.7355659936],
      [3.19e-9, 1.38633229189, 163096.18036118348],
      [2.24e-9, 1.65156322696, 10027.9031957292],
      [2.26e-9, 0.34125379653, 17796.9591667858],
      [2.36e-9, 4.19817431922, 19.66976089979],
      [2.81e-9, 4.14114899916, 12539.853380183],
      [2.75e-9, 5.50306930248, 32.5325507914],
      [2.23e-9, 5.23334210294, 56.8983749356],
      [2.17e-9, 6.08598789777, 6805.6532680852],
      [2.28e-9, 5.17114391778, 11720.0688652316],
      [2.74e-9, 4.50716805713, 6016.4688082696],
      [2.45e-9, 3.96486270306, 22.7752014508],
      [2.2e-9, 4.7207808197, 6.62855890001],
      [2.07e-9, 5.71701403951, 41.5507909848],
      [2.04e-9, 3.9122741125, 2699.7348193176],
      [2.09e-9, 0.86881969024, 6321.1035226272],
      [2e-9, 2.11984442601, 4274.5183108324],
      [2e-9, 5.39839888151, 6019.9919266186],
      [2.09e-9, 5.67606291663, 11293.4706743556],
      [2.52e-9, 1.6496572935, 9380.9596727172],
      [2.75e-9, 5.04826903506, 73.297125859],
      [2.08e-9, 1.88207277133, 11300.5842213564],
      [2.06e-9, 5.0728488933, 6277.552925684],
      [2.72e-9, 0.74640924904, 1975.492545856],
      [1.99e-9, 3.30813142103, 22743.4093795164],
      [2.69e-9, 4.48560812155, 64471.99124174489],
      [1.92e-9, 2.17463565107, 5863.5912061162],
      [2.28e-9, 5.85373115869, 128.0188433374],
      [2.61e-9, 2.64321183295, 55022.9357470744],
      [1.96e-9, 2.4853762232, 16062.1845261168],
      [1.87e-9, 1.3189176028, 29826.3063546732],
      [2.2e-9, 5.75012110079, 29.429508536],
      [1.87e-9, 4.03230554718, 467.9649903544],
      [2e-9, 5.60555262896, 1066.49547719],
      [2.31e-9, 1.09802712785, 12341.8069042809],
      [1.98e-9, 0.29474229005, 149.5631971346],
      [2.49e-9, 5.1047702317, 7875.6718636242],
      [1.79e-9, 0.87066197995, 12721.572099417],
      [2.03e-9, 1.56914310573, 28286.9904848612],
      [1.98e-9, 3.54061588502, 30.914125635],
      [1.71e-9, 3.45366018621, 5327.4761083828],
      [1.83e-9, 0.72325421604, 6272.0301497275],
      [2.16e-9, 2.97175184412, 19402.7969528166],
      [1.68e-9, 2.51559879907, 23937.856389741],
      [1.95e-9, 0.09045393425, 156.4007205024],
      [2.16e-9, 0.42162375972, 23539.7073863328],
      [1.89e-9, 0.37542530191, 9814.6041002912],
      [2.07e-9, 2.01752547259, 238004.5241572363],
      [2.18e-9, 2.36835893645, 16627.3709153772],
      [1.66e-9, 4.23182960518, 16840.67001081519],
      [2e-9, 2.02153258098, 16097.6799502826],
      [1.69e-9, 0.91318727, 95.9792272178],
      [2.11e-9, 5.73370637657, 151.8972810852],
      [2.04e-9, 0.42643085174, 515.463871093],
      [2.12e-9, 3.00223140894, 12043.574281889],
      [1.92e-9, 5.46153589821, 6379.0550772092],
      [1.6e-9, 6.23798383332, 202.2533951741],
      [2.15e-9, 0.20889073407, 5621.8429232104],
      [1.5e-9, 3.12999753018, 799.8211251654],
      [1.87e-9, 2.12345787867, 491.6632924588],
      [1.92e-9, 1.33928820063, 394.6258850592],
      [1.49e-9, 2.65697593276, 21.335640467],
      [1.46e-9, 5.58021191726, 412.3710968744],
      [1.56e-9, 3.7565767638, 12323.4230960088],
      [1.43e-9, 3.28248547724, 29.8214381488],
      [1.44e-9, 1.07862546598, 1265.5674786264],
      [1.48e-9, 0.2338663109, 10021.8372800994],
      [1.64e-9, 0.94288727597, 14919.0178537546],
      [1.93e-9, 5.92751083827, 40879.4405046438],
      [1.4e-9, 4.97612440269, 158.9435177832],
      [1.48e-9, 2.61651818006, 17157.0618804718],
      [1.4e-9, 3.66947933935, 26084.0218062162],
      [1.47e-9, 5.09968173403, 661.232926781],
      [1.47e-9, 1.36976712162, 4171.4255366138],
      [1.34e-9, 4.79432636012, 111.1866422876],
      [1.4e-9, 1.27748013377, 107.6635239386],
      [1.71e-9, 2.77586207403, 26735.9452622132],
      [1.83e-9, 5.43418358741, 369.6998159404],
      [1.34e-9, 3.09132862833, 17.812522118],
      [1.32e-9, 3.05633896779, 22490.9621214934],
      [1.81e-9, 4.22950689891, 966.9708774356],
      [1.66e-9, 3.67660435776, 15508.6151232744],
      [1.52e-9, 5.28885813387, 12669.2444742014],
      [1.5e-9, 5.86819430895, 97238.62754448749],
      [1.45e-9, 5.07330784304, 87.30820453981],
      [1.33e-9, 5.65471067133, 31.9723058168],
      [1.24e-9, 2.83326216907, 12566.2190102856],
      [1.4e-9, 5.84212721453, 22476.73502749179],
      [1.34e-9, 3.12858101887, 32217.2001810808],
      [1.37e-9, 0.86487461904, 9924.8104215106],
      [1.72e-9, 1.98369595114, 174242.4659640497],
      [1.7e-9, 4.41115280254, 327574.51427678124],
      [1.28e-9, 4.49087631612, 31415.379249957],
      [1.51e-9, 0.46542092001, 39609.6545831656],
      [1.53e-9, 3.78801830344, 17363.24742890899],
      [1.65e-9, 5.31654110459, 16943.7627850338],
      [1.65e-9, 4.06747587817, 58953.145443294],
      [1.18e-9, 0.63846333239, 6.0659156298],
      [1.59e-9, 0.86086959274, 221995.02880149524],
      [1.19e-9, 5.96432932413, 1385.8952763362],
      [1.14e-9, 5.16516114595, 25685.872802808],
      [1.12e-9, 4.92889233335, 56.8032621698],
      [1.19e-9, 2.40626699328, 18635.9284545362],
      [1.15e-9, 0.23374479051, 418.9243989006],
      [1.13e-9, 2.7938757674, 6272.4391846416],
      [1.22e-9, 0.936940724, 24492.40611365159],
      [1.33e-9, 4.87155573413, 22345.2603761082],
      [1.13e-9, 3.80362889046, 6293.7125153412],
      [1.3e-9, 3.72996018683, 12573.2652469836],
      [1.07e-9, 3.40227152756, 21393.5419698576],
      [1.22e-9, 1.00385670948, 95143.1329209781],
      [1.4e-9, 1.094130757, 44809.6502008634],
      [1.12e-9, 6.05462382871, 433.7117378768],
      [1.23e-9, 4.55640196386, 239424.39025435288],
      [1.04e-9, 1.54931540602, 127.9515330346],
      [1.11e-9, 3.04186517428, 8982.810669309],
      [1.02e-9, 4.12448497391, 15664.03552270859],
      [1.07e-9, 4.67919356465, 77690.75950573849],
      [1.18e-9, 4.5232017012, 19004.6479494084],
      [1.07e-9, 5.71774478555, 77736.78343050249],
      [1.03e-9, 4.79332126649, 33794.5437235286],
      [1.43e-9, 1.81201813018, 4214.0690150848],
      [1.02e-9, 3.7581778657, 58864.5439181463],
      [1.25e-9, 1.14419195615, 625.6701923124],
      [1.24e-9, 3.27736513892, 12566.08438968],
      [1.1e-9, 1.08705709966, 2787.0430238574],
      [1.02e-9, 4.75119578149, 12242.6462833254],
      [1.01e-9, 4.91289409429, 401.6721217572],
      [1.38e-9, 2.89578979744, 9411.4646150872],
      [1.29e-9, 1.23516042371, 12029.3471878874],
      [1.38e-9, 2.45654707076, 7576.560073574],
      [9.8e-10, 5.4477176502, 29026.48522950779],
      [1.34e-9, 1.43105174912, 86464.6133168312],
      [1.08e-9, 0.9898977494, 5636.0650166766],
      [1.17e-9, 5.17362847134, 34520.3093093808],
      [9.7e-10, 3.34717130592, 16310.9790457206],
      [1.07e-9, 2.94547931851, 24602.61243487099],
      [9.8e-10, 4.37041908717, 34513.2630726828],
      [1.25e-9, 2.72182830814, 24065.80792277559],
      [1.11e-9, 0.58899131543, 6303.4311693902],
      [1.02e-9, 0.66938025772, 10239.5838660108],
      [1.19e-9, 1.21666517886, 1478.8665740644],
      [1.11e-9, 1.04321934681, 16522.6597160022],
      [9.4e-10, 4.31076339857, 26880.3198130326],
      [9.8e-10, 4.14248433763, 6599.467719648],
      [9.5e-10, 2.89807657534, 34911.412076091],
      [9.7e-10, 0.89642320201, 71980.63357473118],
      [9.5e-10, 0.65717727948, 6288.5987742988],
      [1.16e-9, 4.19967201116, 206.7007372966],
      [9.5e-10, 1.78315464297, 18139.2945014159],
      [9.9e-10, 1.37437847718, 1039.0266107904],
      [1.26e-9, 3.21642544972, 305281.9430710488],
      [9.4e-10, 0.6899503186, 7834.1210726394],
      [9.5e-10, 5.58111421744, 3104.9300594238],
      [1.08e-9, 0.52696637156, 276.7457718644],
      [1.24e-9, 3.43899862683, 172146.9713405403],
      [8.7e-10, 1.18764938806, 18842.11400297339],
      [8.7e-10, 0.09094166389, 15141.390794312],
      [1.08e-9, 1.03363414379, 82576.9812209953],
      [1.19e-9, 2.86729109648, 90394.82301305079],
      [1.04e-9, 3.39218586218, 290.972865866],
      [9.4e-10, 5.68284937444, 32367.0976562076],
      [1.17e-9, 0.78475956902, 83286.91426955358],
      [1.05e-9, 3.96551057233, 6357.8574485587],
      [9.4e-10, 4.03443174853, 13341.6743113068],
      [9.6e-10, 0.92742567538, 1062.9050485382],
      [8.9e-10, 4.45371820659, 792.7748884674],
      [8.7e-10, 0.40013481685, 90279.92316810328],
      [1.13e-9, 2.48165313368, 48739.859897083],
      [8.7e-10, 3.43122851097, 27707.5424942948],
      [1.01e-9, 5.32081603011, 2301.58581590939],
      [8.4e-10, 0.67020912458, 28628.3362260996],
      [8.2e-10, 0.87060089842, 10241.2022911672],
      [8.6e-10, 4.6206479229, 36147.4098773004],
      [8e-10, 4.77649625396, 6819.8803620868],
      [9.5e-10, 2.87032913492, 23020.65308658799],
      [1.07e-9, 5.77864921649, 34115.1140692746],
      [1.1e-9, 3.32898859416, 72140.6286666874],
      [8.7e-10, 4.40657711727, 142.1786270362],
      [1.03e-9, 4.2250672681, 30666.1549584328],
      [8.2e-10, 3.89404392552, 5547.1993364596],
      [1.09e-9, 1.94546065204, 24279.10701821359],
      [8.7e-10, 4.32472045435, 742.9900605326],
      [1.07e-9, 4.91580912547, 277.0349937414],
      [8.8e-10, 2.10180817713, 26482.1708096244],
      [8.6e-10, 4.01895021483, 12491.3701014155],
      [1.06e-9, 5.49092372854, 62883.3551395136],
      [8e-10, 6.1978570495, 6709.6740408674],
      [1.05e-9, 2.44166529175, 6298.3283211764],
      [8.3e-10, 4.90662164029, 51.28033786241],
      [7.4e-10, 2.34622575625, 7018.9523635232],
      [7.8e-10, 6.06947270265, 148434.53403769128],
      [7.9e-10, 3.03048221644, 838.9692877504],
      [7.3e-10, 3.05008665738, 567.7186377304],
      [1.02e-9, 3.59223815483, 22380.755800274],
      [8.4e-10, 0.46604373274, 45.1412196366],
      [9.2e-10, 4.12917744733, 18216.443810661],
      [9.1e-10, 0.49382398887, 6453.7487206106],
      [7.3e-10, 1.72446569088, 21424.4666443034],
      [6.9e-10, 1.4937267773, 21548.9623692918],
      [6.9e-10, 4.73181018058, 8858.3149443206],
      [7.7e-10, 0.47683782532, 11520.9968637952],
      [7.8e-10, 5.4394263099, 15265.8865193004],
      [7.7e-10, 2.53773750372, 76.2660712756],
      [6.9e-10, 2.53932635192, 9910.583327509],
      [7.5e-10, 0.1559472921, 23006.42599258639],
      [7.8e-10, 2.41004950269, 6393.2821712108],
      [6.7e-10, 5.05152846816, 57375.8019008462],
      [7.7e-10, 3.9107560486, 24383.0791084414],
      [6.7e-10, 0.78239147387, 12779.4507954208],
      [7.1e-10, 1.24817900687, 10419.9862835076],
      [6.7e-10, 5.7785122776, 6311.5250374592],
      [6.1e-10, 2.66705754411, 18852.7506683232],
      [7.9e-10, 1.89137330427, 6528.9074962208],
      [8.4e-10, 5.11523704221, 50317.2034395308],
      [5.9e-10, 3.38290426621, 35707.7100829074],
      [6.5e-10, 4.66767908854, 26709.6469424134],
      [7.9e-10, 5.59773848156, 71960.38658322369],
      [6.1e-10, 3.30733768968, 18845.7044316252],
      [5.7e-10, 3.90831298022, 5999.2165311262],
      [5.8e-10, 3.92572820333, 30348.883772767],
      [6.1e-10, 0.05695045822, 7856.89627409019],
      [6.1e-10, 5.63297960691, 7863.9425107882],
      [6.8e-10, 2.53986117507, 20452.8694122218],
      [5.3e-10, 5.44021645443, 32370.9789915656],
      [5.9e-10, 4.95695131145, 11925.2740926006],
      [5.9e-10, 5.34668243273, 137288.2484348251],
      [5.6e-10, 4.05779957425, 17892.93839400359],
      [5.3e-10, 3.94182462468, 18624.8827542723],
      [4.9e-10, 2.11782803206, 22594.05489571199],
      [6.2e-10, 2.47154086715, 12345.739057544],
      [4.9e-10, 5.7659693738, 18606.4989460002],
      [5.2e-10, 6.20902099741, 21947.1113727],
      [5.1e-10, 3.13287981215, 33326.5787331742],
      [5.7e-10, 6.17003357597, 61306.0115970658],
      [6.4e-10, 5.66596451842, 34596.3646546524],
      [6.6e-10, 3.922623355, 69853.3520756813],
      [5.3e-10, 5.51119362049, 77710.24834977149],
      [5.3e-10, 4.88573986965, 77717.29458646949],
      [4.8e-10, 2.71399112516, 20760.4270331914],
      [4.6e-10, 6.08376164442, 29822.7832363242],
      [5.2e-10, 0.3139748138, 37724.7534197482],
      [4.5e-10, 5.53587248663, 6262.7205305926],
      [5.9e-10, 2.45437896854, 69166.430989505],
      [6e-10, 5.24261569842, 56600.2792895222],
      [5.1e-10, 6.15794342172, 11616.976091013],
      [6e-10, 1.74758109828, 44034.1275895394],
      [4.9e-10, 5.08973141046, 33990.6183442862],
      [4.4e-10, 3.28270864884, 29424.634232916],
      [5.2e-10, 5.34866947943, 28313.288804661],
      [5.9e-10, 2.12384971916, 36173.7081971002],
      [5e-10, 0.46480931695, 25287.7237993998],
      [4.4e-10, 2.0550351721, 63658.8777508376],
      [4.4e-10, 1.71009200258, 13362.4497067992],
      [4.1e-10, 3.91721318316, 6279.1945146334],
      [4.2e-10, 1.52106001448, 23550.34405168259],
      [4.2e-10, 3.10938258068, 84672.47584450469],
      [4.1e-10, 3.78863023321, 35050.00027447539],
      [4.2e-10, 5.57543459263, 38500.2760310722],
      [4e-10, 5.55145719363, 12565.1713789146],
      [5.4e-10, 0.889354921, 50290.905119731],
      [5.5e-10, 2.50268487636, 77828.671313068],
      [5.4e-10, 4.16257918787, 22910.44676536859],
      [4.1e-10, 2.32141215064, 6286.9571853494],
      [4.5e-10, 3.18590576311, 45585.1728121874],
      [4.5e-10, 2.24348941683, 23646.32327890039],
      [4.3e-10, 3.8789211095, 6549.6828917132],
      [4.8e-10, 2.11364139445, 31570.7996493912],
      [4.3e-10, 5.55099558987, 12359.9661515456],
      [4.1e-10, 4.88191569433, 23536.11695768099],
      [4.9e-10, 2.44790922235, 13613.804277336],
      [4.7e-10, 3.5818169391, 4797.0957289262]
    ],
    "1": [
      [6283.0758499914, 0, 0],
      [0.00206058863, 2.67823455808, 6283.0758499914],
      [0.00004303419, 2.63512233481, 12566.1516999828],
      [0.00000425264, 1.59046982018, 3.523118349],
      [0.00000109017, 2.96631010675, 1577.3435424478],
      [9.3479e-7, 2.59211109542, 18849.2275499742],
      [0.00000119305, 5.79555765566, 26.2983197998],
      [7.2121e-7, 1.13840581212, 529.6909650946],
      [6.7784e-7, 1.87453300345, 398.1490034082],
      [6.735e-7, 4.40932832004, 5507.5532386674],
      [5.9045e-7, 2.88815790631, 5223.6939198022],
      [5.5976e-7, 2.17471740035, 155.4203994342],
      [4.5411e-7, 0.39799502896, 796.2980068164],
      [3.6298e-7, 0.46875437227, 775.522611324],
      [2.8962e-7, 2.64732254645, 7.1135470008],
      [1.9097e-7, 1.84628376049, 5486.777843175],
      [2.0844e-7, 5.34138275149, 0.9803210682],
      [1.8508e-7, 4.96855179468, 213.299095438],
      [1.6233e-7, 0.03216587315, 2544.3144198834],
      [1.7293e-7, 2.9911676063, 6275.9623029906],
      [1.5832e-7, 1.43049301283, 2146.1654164752],
      [1.4608e-7, 1.2046979369, 10977.078804699],
      [1.1877e-7, 3.25805082007, 5088.6288397668],
      [1.1514e-7, 2.07502080082, 4694.0029547076],
      [9.721e-8, 4.2392586526, 1349.8674096588],
      [9.969e-8, 1.30263423409, 6286.5989683404],
      [9.452e-8, 2.69956827011, 242.728603974],
      [1.2461e-7, 2.83432282119, 1748.016413067],
      [1.1808e-7, 5.27379760438, 1194.4470102246],
      [8.577e-8, 5.6447608598, 951.7184062506],
      [1.0641e-7, 0.76614722966, 553.5694028424],
      [7.576e-8, 5.30056172859, 2352.8661537718],
      [5.764e-8, 1.77228445837, 1059.3819301892],
      [6.385e-8, 2.65034514038, 9437.762934887],
      [5.223e-8, 5.66135782131, 71430.69561812909],
      [5.315e-8, 0.91110018969, 3154.6870848956],
      [6.101e-8, 4.66633726278, 4690.4798363586],
      [4.335e-8, 0.23934560382, 6812.766815086],
      [5.041e-8, 1.42489704722, 6438.4962494256],
      [4.259e-8, 0.77355543889, 10447.3878396044],
      [5.2e-8, 1.85528830215, 801.8209311238],
      [3.744e-8, 2.00119905572, 8031.0922630584],
      [3.553e-8, 2.42789590229, 14143.4952424306],
      [3.372e-8, 3.86210786421, 1592.5960136328],
      [3.38e-8, 0.88545388924, 12036.4607348882],
      [3.196e-8, 3.19713328141, 4705.7323075436],
      [3.221e-8, 0.6160104899, 8429.2412664666],
      [4.132e-8, 5.23992584671, 7084.8967811152],
      [2.97e-8, 6.07029819073, 4292.3308329504],
      [2.9e-8, 2.32464208411, 20.3553193988],
      [3.504e-8, 4.79976712702, 6279.5527316424],
      [2.95e-8, 1.43108874817, 5746.271337896],
      [2.697e-8, 4.80365209201, 7234.794256242],
      [2.531e-8, 6.22289990904, 6836.6452528338],
      [2.745e-8, 0.93466065396, 5760.4984318976],
      [3.25e-8, 3.39951915286, 7632.9432596502],
      [2.278e-8, 5.00339914806, 17789.845619785],
      [2.076e-8, 3.95551309007, 10213.285546211],
      [2.061e-8, 2.2240771919, 5856.4776591154],
      [2.252e-8, 5.67166717686, 11499.6562227928],
      [2.148e-8, 5.20182663314, 11513.8833167944],
      [1.886e-8, 0.53198539077, 3340.6124266998],
      [1.875e-8, 4.73511969924, 83996.84731811189],
      [2.06e-8, 2.54985307819, 25132.3033999656],
      [1.794e-8, 1.47435300254, 4164.311989613],
      [1.778e-8, 3.02473091781, 5.5229243074],
      [2.036e-8, 0.90908165072, 6256.7775301916],
      [2.064e-8, 2.27051326957, 522.5774180938],
      [1.773e-8, 3.03090500693, 5753.3848848968],
      [1.569e-8, 6.12406216872, 5216.5803728014],
      [1.59e-8, 4.63713748247, 3.2863574178],
      [1.533e-8, 4.20305593883, 13367.9726311066],
      [1.427e-8, 1.19087535126, 3894.1818295422],
      [1.376e-8, 4.24955891338, 426.598190876],
      [1.375e-8, 3.09301252193, 135.0650800354],
      [1.308e-8, 3.0849213889, 5643.1785636774],
      [1.34e-8, 5.76513167968, 6040.3472460174],
      [1.25e-8, 3.07748196332, 11926.2544136688],
      [1.551e-8, 3.07664090662, 6681.2248533996],
      [1.148e-8, 3.24144202282, 12168.0026965746],
      [1.268e-8, 2.09201189992, 6290.1893969922],
      [1.248e-8, 3.44506939791, 536.8045120954],
      [1.118e-8, 2.31830078762, 16730.4636895958],
      [1.105e-8, 5.31966001019, 23.8784377478],
      [1.012e-8, 3.74953487087, 7860.4193924392],
      [1.025e-8, 2.4468377161, 1990.745017041],
      [9.62e-9, 0.81771017882, 3.881335358],
      [9.11e-9, 0.41724352112, 7079.3738568078],
      [1.091e-8, 3.98233608618, 11506.7697697936],
      [9.57e-9, 4.07671436153, 6127.6554505572],
      [8.35e-9, 5.28348689371, 11790.6290886588],
      [8.02e-9, 3.88779080089, 10973.55568635],
      [7.73e-9, 2.41044394817, 1589.0728952838],
      [7.58e-9, 1.30034365873, 103.0927742186],
      [7.49e-9, 4.96281442361, 6496.3749454294],
      [7.65e-9, 3.36312388424, 36.0278666774],
      [9.15e-9, 5.41549763095, 206.1855484372],
      [7.76e-9, 2.57589060224, 11371.7046897582],
      [7.72e-9, 3.98363364977, 955.5997416086],
      [7.49e-9, 5.17900231417, 10969.9652576982],
      [8.06e-9, 0.34226117299, 9917.6968745098],
      [7.28e-9, 5.20962563787, 38.0276726358],
      [6.85e-9, 2.77592961854, 20.7753954924],
      [6.36e-9, 4.28242193632, 28.4491874678],
      [6.08e-9, 5.63278510221, 10984.1923516998],
      [7.04e-9, 5.60739437733, 3738.761430108],
      [6.85e-9, 0.38876148682, 15.252471185],
      [6.01e-9, 0.73489602442, 419.4846438752],
      [7.16e-9, 2.65286869987, 6309.3741697912],
      [5.84e-9, 5.54508741381, 17298.1823273262],
      [6.28e-9, 1.11733054796, 7058.5984613154],
      [6.88e-9, 2.59684132401, 3496.032826134],
      [4.85e-9, 0.44470714066, 12352.8526045448],
      [5.62e-9, 2.82510352358, 3930.2096962196],
      [5.97e-9, 5.27675789973, 10575.4066829418],
      [5.83e-9, 3.1893153986, 4732.0306273434],
      [5.26e-9, 5.01737745304, 5884.9268465832],
      [5.4e-9, 1.29182747488, 640.8776073822],
      [4.81e-9, 5.49721461067, 5230.807466803],
      [4.06e-9, 5.21253018484, 220.4126424388],
      [3.95e-9, 1.87489912123, 16200.7727245012],
      [3.67e-9, 0.88533542945, 6283.14316029419],
      [3.69e-9, 3.84778078192, 18073.7049386502],
      [3.79e-9, 0.37991716505, 10177.2576795336],
      [3.56e-9, 3.84152910109, 11712.9553182308],
      [3.74e-9, 5.01577520608, 7.046236698],
      [3.81e-9, 4.30250406634, 6062.6632075526],
      [4.71e-9, 0.86388942467, 6069.7767545534],
      [3.67e-9, 1.3294383993, 6283.0085396886],
      [4.6e-9, 5.19667219582, 6284.0561710596],
      [3.33e-9, 5.54250425107, 4686.8894077068],
      [3.41e-9, 4.36524495363, 7238.6755916],
      [3.36e-9, 4.00205876835, 3097.88382272579],
      [3.59e-9, 6.22679790284, 245.8316462294],
      [3.07e-9, 2.35299010924, 170.6728706192],
      [3.43e-9, 3.77164927142, 6076.8903015542],
      [2.96e-9, 5.44138799494, 17260.1546546904],
      [3.28e-9, 0.13817705132, 11015.1064773348],
      [2.69e-9, 1.13308244952, 12569.6748183318],
      [2.63e-9, 0.0055073751, 4136.9104335162],
      [2.82e-9, 5.04399588559, 7477.522860216],
      [2.8e-9, 3.13703211405, 12559.038152982],
      [2.59e-9, 0.93882269388, 5642.1982426092],
      [2.92e-9, 1.98426314297, 12132.439962106],
      [2.47e-9, 3.84244798673, 5429.8794682394],
      [3.19e-9, 5.0417014879, 90617.7374312997],
      [2.45e-9, 5.70469737024, 65147.6197681377],
      [3.18e-9, 1.35581968834, 78051.5857313169],
      [2.41e-9, 0.99469787369, 3634.6210245184],
      [2.46e-9, 3.06168069393, 110.2063212194],
      [2.39e-9, 6.11854529589, 11856.2186514245],
      [2.67e-9, 0.65297608414, 21228.3920235458],
      [2.62e-9, 1.51070507866, 12146.6670561076],
      [2.3e-9, 1.75923794017, 9779.1086761254],
      [2.23e-9, 2.00967043606, 6172.869528772],
      [2.46e-9, 1.10411690861, 6282.0955289232],
      [2.14e-9, 4.03840492266, 14314.1681130498],
      [2.12e-9, 2.13695623228, 5849.3641121146],
      [2.07e-9, 3.07724246401, 11.729352836],
      [2.07e-9, 6.10303325026, 23543.23050468179],
      [2.66e-9, 1.00720021877, 2388.8940204492],
      [2.17e-9, 6.27840212312, 17267.26820169119],
      [2.31e-9, 5.37372783468, 13916.0191096416],
      [2.04e-9, 2.34615348695, 266.6070417218],
      [1.95e-9, 5.55015549753, 6133.5126528568],
      [2.03e-9, 4.65616806503, 24072.9214697764],
      [1.88e-9, 2.52682282169, 6525.8044539654],
      [1.77e-9, 1.73426919199, 154717.6098876827],
      [1.87e-9, 4.76501318048, 4535.0594369244],
      [1.86e-9, 4.63080493407, 10440.2742926036],
      [1.83e-9, 3.20060840641, 8635.9420037632],
      [1.72e-9, 1.45551703877, 9225.539273283],
      [1.62e-9, 3.30665137166, 639.897286314],
      [1.68e-9, 2.17671416605, 27.4015560968],
      [1.6e-9, 1.6816871275, 15110.4661198662],
      [1.94e-9, 2.79243768345, 7342.4577801806],
      [1.83e-9, 0.56273524797, 13517.8701062334],
      [1.72e-9, 5.97039514134, 4701.1165017084],
      [1.79e-9, 3.58450811616, 87.30820453981],
      [1.52e-9, 2.84070476839, 5650.2921106782],
      [1.56e-9, 1.07156076421, 18319.5365848796],
      [1.82e-9, 0.44053620124, 17253.04110768959],
      [1.42e-9, 1.4629013752, 11087.2851259184],
      [1.31e-9, 5.40912137746, 2699.7348193176],
      [1.44e-9, 2.07312089638, 25158.6017197654],
      [1.47e-9, 6.15107800602, 9623.6882766912],
      [1.41e-9, 5.55739979498, 10454.5013866052],
      [1.35e-9, 0.0609812943, 16723.350142595],
      [1.23e-9, 5.81194797368, 17256.6315363414],
      [1.24e-9, 2.36269386269, 4933.2084403326],
      [1.26e-9, 3.47483886466, 22483.84857449259],
      [1.59e-9, 5.63944722033, 5729.506447149],
      [1.37e-9, 1.93811728826, 20426.571092422],
      [1.23e-9, 3.92815962825, 17996.0311682222],
      [1.48e-9, 3.02542567608, 1551.045222648],
      [1.21e-9, 0.05537321071, 13095.8426650774],
      [1.2e-9, 5.91904349732, 6206.8097787158],
      [1.34e-9, 3.11122937825, 21954.15760939799],
      [1.19e-9, 5.52143897201, 709.9330485583],
      [1.22e-9, 3.00840036775, 19800.9459562248],
      [1.27e-9, 1.37534182407, 14945.3161735544],
      [1.41e-9, 2.56886299638, 1052.2683831884],
      [1.23e-9, 2.83671175442, 11919.140866668],
      [1.18e-9, 0.81918292547, 5331.3574437408],
      [1.51e-9, 2.68728567951, 11769.8536931664],
      [1.19e-9, 5.08654046247, 5481.2549188676],
      [1.13e-9, 4.42675663942, 18422.62935909819],
      [1.53e-9, 2.46021790779, 11933.3679606696],
      [1.08e-9, 1.04936452151, 11403.676995575],
      [1.28e-9, 0.99810456461, 8827.3902698748],
      [1.44e-9, 2.54869747042, 227.476132789],
      [1.5e-9, 4.50631437136, 2379.1644735716],
      [1.09e-9, 0.29269062317, 16737.5772365966],
      [1.22e-9, 4.23040027813, 29.429508536],
      [1.11e-9, 5.16970710025, 17782.7320727842],
      [1.05e-9, 1.61738153441, 13119.72110282519],
      [1e-9, 3.52204690579, 18052.9295431578],
      [1.08e-9, 1.08493117155, 16858.4825329332],
      [1.35e-9, 3.2016061697, 6262.300454499],
      [1.06e-9, 1.96085069786, 74.7815985673],
      [1.29e-9, 4.85949366504, 16496.3613962024],
      [1.1e-9, 2.30605777952, 16460.33352952499],
      [9.7e-10, 3.5091894021, 5333.9002410216],
      [9.9e-10, 3.56417337974, 735.8765135318],
      [9.6e-10, 3.40918487598, 15720.8387848784],
      [9.4e-10, 5.01601027363, 3128.3887650958],
      [9.7e-10, 1.65579893894, 533.2140834436],
      [9.2e-10, 0.89219199493, 29296.6153895786],
      [1.23e-9, 3.16062062663, 9380.9596727172],
      [1.02e-9, 1.20493500565, 23020.65308658799],
      [8.8e-10, 2.21265504437, 12721.572099417],
      [8.9e-10, 1.5432266957, 20199.094959633],
      [1.21e-9, 6.19860353182, 9388.0059094152],
      [8.9e-10, 4.08082274765, 22805.7355659936],
      [9.8e-10, 1.09176668094, 12043.574281889],
      [8.6e-10, 1.13649001466, 143571.32428481648],
      [8.8e-10, 5.96980472191, 107.6635239386],
      [8.2e-10, 5.01561173481, 22003.9146348698],
      [9.4e-10, 1.69615700473, 23006.42599258639],
      [8.1e-10, 3.00664741995, 2118.7638603784],
      [9.8e-10, 1.39215287161, 8662.240323563],
      [8e-10, 5.16340988714, 17796.9591667858],
      [8.2e-10, 5.86893959287, 2787.0430238574],
      [7.7e-10, 0.80723694712, 167283.7615876655],
      [9.1e-10, 5.74902425304, 21424.4666443034],
      [7.6e-10, 5.67183650604, 14.2270940016],
      [8.1e-10, 6.16619455699, 1039.0266107904],
      [7.6e-10, 3.21449884756, 111.1866422876],
      [7.8e-10, 1.37531518377, 21947.1113727],
      [7.4e-10, 3.58814195051, 11609.8625440122],
      [7.7e-10, 4.84846488388, 22743.4093795164],
      [9e-10, 1.48869013606, 15671.0817594066],
      [8.2e-10, 3.48618398216, 29088.811415985],
      [7.1e-10, 2.2159156119, 12029.3471878874],
      [6.9e-10, 1.93625656075, 135.62532501],
      [7e-10, 2.66552760898, 18875.525869774],
      [6.9e-10, 5.41478093731, 26735.9452622132],
      [7.9e-10, 5.15158156951, 12323.4230960088],
      [7.8e-10, 4.17014063638, 1066.49547719],
      [6.5e-10, 5.64584720343, 12139.5535091068],
      [7.1e-10, 3.89804774037, 22779.4372461938],
      [6.3e-10, 4.53968787714, 8982.810669309],
      [7.6e-10, 3.29088891716, 2942.4634232916],
      [6.9e-10, 0.94232113005, 14919.0178537546],
      [6.3e-10, 4.0918653549, 16062.1845261168],
      [6.5e-10, 3.34580407184, 51.28033786241],
      [6.5e-10, 5.75757544877, 52670.0695933026],
      [5.7e-10, 5.25050277534, 20995.3929664494],
      [6.1e-10, 1.92290673861, 6805.6532680852],
      [6.1e-10, 0.08878901558, 13362.4497067992],
      [7.6e-10, 1.86947679415, 25287.7237993998],
      [5.6e-10, 4.25396542622, 6709.6740408674],
      [5.8e-10, 4.79429715781, 6286.3622074092],
      [7.3e-10, 0.53299090807, 2301.58581590939],
      [7e-10, 4.31243357502, 19402.7969528166],
      [6.7e-10, 2.53852336668, 377.3736079158],
      [5.6e-10, 3.20816844695, 24889.5747959916],
      [5.4e-10, 5.17336469511, 26084.0218062162],
      [5.3e-10, 3.17675406016, 18451.07854656599],
      [5.3e-10, 3.61529270216, 77.673770428],
      [5.3e-10, 0.45467549335, 30666.1549584328],
      [5.3e-10, 2.97761644192, 21548.9623692918],
      [6.1e-10, 0.14805728543, 23013.5395395872],
      [5.1e-10, 3.32803972907, 56.8983749356],
      [5.2e-10, 3.41304011355, 23141.5583829246],
      [5.8e-10, 3.13638677202, 309.2783226558],
      [5.4e-10, 1.60896548545, 13341.6743113068],
      [5.3e-10, 5.81426394852, 16193.65917750039],
      [6.7e-10, 6.27917920454, 22345.2603761082],
      [5e-10, 0.42577644151, 25685.872802808],
      [4.8e-10, 0.70204553352, 1162.4747044078],
      [6.6e-10, 3.64350022359, 15265.8865193004],
      [5e-10, 5.7438291744, 19.66976089979],
      [5.4e-10, 1.97277370837, 23581.2581773176],
      [5.1e-10, 1.23713196525, 12539.853380183],
      [4.6e-10, 5.41431704639, 33019.0211122046],
      [4.6e-10, 4.80640843261, 19651.048481098],
      [5e-10, 1.23847511223, 22476.73502749179],
      [5.1e-10, 4.91913434178, 12592.4500197826],
      [4.6e-10, 2.41369976086, 98068.53671630539],
      [4.5e-10, 3.45227074337, 30774.5016425748],
      [4.5e-10, 4.39659083856, 433.7117378768],
      [4.5e-10, 3.71921056816, 18209.33026366019],
      [4.4e-10, 2.47683925106, 24356.7807886416],
      [4.9e-10, 2.17835058609, 13521.7514415914],
      [4.6e-10, 0.26142733448, 11.0457002639],
      [4.5e-10, 2.46230645202, 51868.2486621788],
      [4.3e-10, 4.29458463014, 28230.18722269139],
      [4.8e-10, 0.89551707131, 56600.2792895222],
      [4.2e-10, 3.63410684699, 4590.910180489],
      [4.9e-10, 3.17757670967, 6303.8512454838],
      [4.3e-10, 4.93350349236, 10021.8372800994],
      [5.2e-10, 3.65410195699, 7872.1487452752],
      [4.1e-10, 4.82166756935, 10988.808157535],
      [4e-10, 1.81891629936, 34596.3646546524],
      [4.3e-10, 1.94164978061, 1903.4368125012],
      [4.1e-10, 0.74461854136, 23937.856389741],
      [4.5e-10, 5.4557501753, 60530.4889857418],
      [5e-10, 5.67355640472, 18216.443810661],
      [4e-10, 0.04502010161, 38526.574350872],
      [5.3e-10, 3.64807615995, 11925.2740926006],
      [4.2e-10, 5.19292937193, 19004.6479494084],
      [4.1e-10, 0.94309683296, 9924.8104215106],
      [3.9e-10, 4.61184303844, 95.9792272178],
      [4.9e-10, 2.05532526216, 12573.2652469836],
      [4.5e-10, 3.73717824543, 7875.6718636242],
      [4.3e-10, 1.14078465002, 49.7570254718],
      [3.9e-10, 1.70539366023, 32217.2001810808],
      [3.7e-10, 1.29390383811, 310.8407988684],
      [3.8e-10, 0.9597092595, 664.75604513],
      [3.9e-10, 0.85957361635, 16522.6597160022],
      [4e-10, 1.00170796001, 36949.2308084242],
      [4e-10, 3.78164718776, 55798.4583583984],
      [3.7e-10, 5.42237070904, 6286.6662786432],
      [3.6e-10, 1.68167662194, 10344.2950653858],
      [4e-10, 5.13217319067, 15664.03552270859],
      [4.9e-10, 3.62741283878, 77713.7714681205],
      [4.5e-10, 6.15877872538, 28286.9904848612],
      [3.6e-10, 3.32158458257, 16207.886271502],
      [3.5e-10, 5.83917764292, 6321.1035226272],
      [3.6e-10, 1.80784164083, 6279.7894925736],
      [3.5e-10, 4.60279245362, 28237.2334593894],
      [3.3e-10, 0.47301775923, 18635.9284545362],
      [3.5e-10, 4.36571027474, 48739.859897083],
      [3.1e-10, 3.06828028412, 6819.8803620868],
      [3.1e-10, 2.4020319848, 28628.3362260996],
      [3.4e-10, 1.90096411242, 12964.300703391],
      [2.9e-10, 6.09291010354, 18606.4989460002],
      [2.8e-10, 3.42046112698, 6288.5987742988],
      [2.8e-10, 3.437361406, 34115.1140692746],
      [2.9e-10, 1.48920816078, 6489.2613984286],
      [2.8e-10, 3.07474749886, 29822.7832363242],
      [3.8e-10, 2.44608264663, 31415.379249957],
      [2.8e-10, 2.98392582088, 6277.552925684],
      [2.7e-10, 5.03556015623, 12779.4507954208],
      [2.7e-10, 5.40812977287, 26087.9031415742],
      [3.8e-10, 5.56439937893, 27832.0382192832],
      [2.6e-10, 3.87685883153, 6262.7205305926],
      [2.7e-10, 6.1556539284, 28759.81087748319],
      [2.5e-10, 5.17122153205, 6915.8595893046],
      [2.7e-10, 4.03132006944, 9910.583327509],
      [3.3e-10, 3.97763407373, 12410.7313005486],
      [2.4e-10, 5.31307120044, 29026.48522950779],
      [2.5e-10, 0.81055213297, 36173.7081971002],
      [2.4e-10, 1.2870610131, 24491.4257925834],
      [2.1e-10, 2.02548478742, 28766.924424484],
      [2.1e-10, 6.07545114034, 18139.2945014159],
      [2.2e-10, 2.31199937131, 6303.4311693902],
      [2.3e-10, 1.35235057478, 49515.382508407],
      [2.3e-10, 2.92765926961, 65236.2212932854],
      [1.9e-10, 0.03636659763, 29864.334027309],
      [2e-10, 0.91374066194, 45585.1728121874],
      [2.1e-10, 4.45003013294, 22490.9621214934],
      [2.2e-10, 1.97119365888, 34513.2630726828],
      [2e-10, 4.11682669951, 17157.0618804718],
      [2.4e-10, 1.40243942415, 14712.317116458],
      [2.2e-10, 2.19759737115, 31570.7996493912],
      [2.1e-10, 1.48739821208, 61306.0115970658],
      [2.5e-10, 5.71465573409, 25934.1243310894]
    ],
    "2": [
      [0.00008721859, 1.07253635559, 6283.0758499914],
      [0.0000099099, 3.14159265359, 0],
      [0.00000294833, 0.43717350256, 12566.1516999828],
      [2.7338e-7, 0.05295636147, 3.523118349],
      [1.6333e-7, 5.18820215724, 26.2983197998],
      [1.5745e-7, 3.68504712183, 155.4203994342],
      [9.425e-8, 0.29667114694, 18849.2275499742],
      [8.938e-8, 2.05706319592, 77713.7714681205],
      [6.94e-8, 0.82691541038, 775.522611324],
      [5.061e-8, 4.6624323168, 1577.3435424478],
      [4.06e-8, 1.03067032318, 7.1135470008],
      [3.464e-8, 5.14021224609, 796.2980068164],
      [3.172e-8, 6.05479318507, 5507.5532386674],
      [3.02e-8, 1.19240008524, 242.728603974],
      [2.885e-8, 6.11705865396, 529.6909650946],
      [3.809e-8, 3.44043369494, 5573.1428014331],
      [2.719e-8, 0.30363248164, 398.1490034082],
      [2.365e-8, 4.37666117992, 5223.6939198022],
      [2.538e-8, 2.27966434314, 553.5694028424],
      [2.078e-8, 3.75435095487, 0.9803210682],
      [1.675e-8, 0.90149951436, 951.7184062506],
      [1.534e-8, 5.75895831192, 1349.8674096588],
      [1.224e-8, 2.97285792195, 2146.1654164752],
      [1.449e-8, 4.36401639552, 1748.016413067],
      [1.341e-8, 3.72019379666, 1194.4470102246],
      [1.253e-8, 2.9488872631, 6438.4962494256],
      [9.99e-9, 5.98665341008, 6286.5989683404],
      [9.17e-9, 4.79722195184, 5088.6288397668],
      [8.29e-9, 3.31021398862, 213.299095438],
      [1.102e-8, 1.27094359244, 161000.6857376741],
      [7.64e-9, 3.41231607038, 5486.777843175],
      [1.046e-8, 0.60374190029, 3154.6870848956],
      [8.87e-9, 5.23364022595, 7084.8967811152],
      [6.44e-9, 1.59974355582, 2544.3144198834],
      [6.81e-9, 3.42742947469, 4694.0029547076],
      [6.06e-9, 2.47688996663, 10977.078804699],
      [7.06e-9, 6.19369692903, 4690.4798363586],
      [6.43e-9, 1.98119869589, 801.8209311238],
      [5.02e-9, 1.44415463861, 6836.6452528338],
      [4.9e-9, 2.33889753806, 1592.5960136328],
      [4.58e-9, 1.30867922972, 4292.3308329504],
      [4.31e-9, 0.03542536476, 7234.794256242],
      [3.49e-9, 0.98779272263, 6040.3472460174],
      [3.85e-9, 1.57065592218, 71430.69561812909],
      [3.71e-9, 3.16149051601, 6309.3741697912],
      [3.48e-9, 0.64980950594, 1059.3819301892],
      [4.58e-9, 3.81505682017, 149854.4001348079],
      [3.02e-9, 1.91723873447, 10447.3878396044],
      [3.06e-9, 3.55405857884, 8031.0922630584],
      [3.95e-9, 4.93742673052, 7632.9432596502],
      [3.14e-9, 3.18058352846, 2352.8661537718],
      [2.81e-9, 4.41751404023, 9437.762934887],
      [2.76e-9, 2.71075791682, 3894.1818295422],
      [2.98e-9, 2.52045757001, 6127.6554505572],
      [2.72e-9, 0.24370191144, 25132.3033999656],
      [2.51e-9, 0.55449375765, 6279.5527316424],
      [2.24e-9, 1.40790249012, 4705.7323075436],
      [2.58e-9, 5.29510765044, 6812.766815086],
      [1.78e-9, 0.92636669742, 1990.745017041],
      [2.17e-9, 0.68543630022, 6256.7775301916],
      [1.54e-9, 0.77808632062, 14143.4952424306],
      [1.5e-9, 2.40636982736, 426.598190876],
      [1.96e-9, 6.06877865012, 640.8776073822],
      [1.37e-9, 2.21947617717, 8429.2412664666],
      [1.27e-9, 5.47380312768, 12036.4607348882],
      [1.21e-9, 3.32740512021, 17789.845619785],
      [1.23e-9, 2.16004509785, 10213.285546211],
      [1.16e-9, 0.49705139709, 7058.5984613154],
      [1.38e-9, 2.36181661472, 11506.7697697936],
      [1.01e-9, 0.86299995919, 6290.1893969922],
      [1.18e-9, 5.82317768355, 7860.4193924392],
      [9.2e-10, 5.11639978593, 7079.3738568078],
      [1.25e-9, 2.65424538513, 88860.05707098669],
      [8.8e-10, 4.3118823616, 83996.84731811189],
      [8.4e-10, 3.57682769713, 16730.4636895958],
      [9.7e-10, 5.58011309774, 13367.9726311066],
      [1.02e-9, 2.05853060226, 87.30820453981],
      [8e-10, 4.73827128421, 11926.2544136688],
      [8e-10, 5.41344057121, 10973.55568635],
      [1.06e-9, 4.10978997399, 3496.032826134],
      [7.5e-10, 4.89166898876, 5643.1785636774],
      [1e-9, 3.62645659087, 244287.60000722768],
      [9.6e-10, 1.39443577787, 6681.2248533996],
      [6.9e-10, 1.88399189965, 10177.2576795336],
      [8.7e-10, 0.40842153208, 11015.1064773348],
      [6.6e-10, 0.99444831932, 6525.8044539654],
      [6.6e-10, 1.42471816453, 9917.6968745098],
      [6.7e-10, 5.5124099707, 3097.88382272579],
      [7.6e-10, 2.7156469351, 4164.311989613],
      [8.5e-10, 0.4965552367, 10575.4066829418],
      [7.7e-10, 3.51693861509, 11856.2186514245],
      [6.2e-10, 3.6258762869, 16496.3613962024],
      [5.4e-10, 5.25957420065, 3340.6124266998],
      [5.3e-10, 1.10902178415, 8635.9420037632],
      [4.9e-10, 5.65761054625, 20426.571092422],
      [6.4e-10, 5.79211164779, 2388.8940204492],
      [4.6e-10, 5.45092696155, 6275.9623029906],
      [5.7e-10, 4.97077155798, 14945.3161735544],
      [4.3e-10, 3.30685683359, 9779.1086761254],
      [4.2e-10, 1.61412785248, 12168.0026965746],
      [4.9e-10, 3.92715473768, 5729.506447149],
      [4.2e-10, 0.6348125893, 2699.7348193176],
      [5.6e-10, 4.34038639086, 90955.5516944961],
      [4e-10, 5.66871428338, 11712.9553182308],
      [3.9e-10, 3.10911294009, 16200.7727245012],
      [4e-10, 5.71338386146, 709.9330485583],
      [5.3e-10, 6.17067257683, 233141.3144043615],
      [3.7e-10, 0.32095173508, 24356.7807886416],
      [3.5e-10, 0.95557073457, 17298.1823273262],
      [3.5e-10, 0.64913397996, 25158.6017197654],
      [4.1e-10, 1.53850422484, 65147.6197681377],
      [3.5e-10, 0.77655626359, 13916.0191096416],
      [3.1e-10, 5.35897350775, 5331.3574437408],
      [3e-10, 4.48114682755, 23543.23050468179],
      [3.2e-10, 3.45976963453, 7477.522860216],
      [2.9e-10, 3.46648040769, 13119.72110282519],
      [3.8e-10, 2.90863974625, 12721.572099417],
      [2.9e-10, 3.13390968321, 4136.9104335162],
      [3.5e-10, 3.79717126309, 143571.32428481648],
      [2.7e-10, 0.95726093828, 12559.038152982],
      [2.6e-10, 4.9532687703, 5753.3848848968],
      [3.2e-10, 3.49943896928, 6284.0561710596],
      [2.6e-10, 4.59276256636, 5884.9268465832],
      [2.6e-10, 1.53958920253, 154717.6098876827],
      [2.3e-10, 4.88012908735, 13095.8426650774],
      [2.3e-10, 0.35935706511, 31415.379249957],
      [2.4e-10, 5.11515116629, 18319.5365848796],
      [2.1e-10, 5.73872879912, 12569.6748183318],
      [2.1e-10, 1.32901200081, 10988.808157535],
      [1.9e-10, 2.24263229491, 18073.7049386502],
      [1.9e-10, 3.14253175605, 6496.3749454294],
      [2.1e-10, 2.80122025076, 6282.0955289232],
      [2.3e-10, 0.14288760398, 6283.0085396886],
      [1.9e-10, 4.28209473754, 3930.2096962196],
      [1.6e-10, 0.25933207663, 11790.6290886588],
      [1.5e-10, 6.0962350124, 13517.8701062334],
      [2e-10, 5.06358906224, 6283.14316029419],
      [1.5e-10, 1.0768066315, 4933.2084403326],
      [1.9e-10, 5.74000581249, 3128.3887650958],
      [1.6e-10, 6.18924226747, 7342.4577801806],
      [1.3e-10, 1.69105044945, 4535.0594369244],
      [1.5e-10, 3.36968394452, 17260.1546546904],
      [1e-10, 3.78885035015, 22003.9146348698],
      [1.1e-10, 2.12851973876, 7875.6718636242]
    ],
    "3": [
      [0.00000289058, 5.84173149732, 6283.0758499914],
      [2.0712e-7, 6.0498393902, 12566.1516999828],
      [2.962e-8, 5.1956057957, 155.4203994342],
      [2.527e-8, 3.14159265359, 0],
      [1.288e-8, 4.7219761197, 3.523118349],
      [6.35e-9, 5.96904899168, 242.728603974],
      [5.7e-9, 5.54182903238, 18849.2275499742],
      [4.02e-9, 3.78606612895, 553.5694028424],
      [7.2e-10, 4.37131884946, 6286.5989683404],
      [6.7e-10, 0.91133898967, 6127.6554505572],
      [3.7e-10, 5.28611190997, 6438.4962494256],
      [2.1e-10, 2.94917211527, 6309.3741697912],
      [1.5e-10, 3.63037493932, 71430.69561812909],
      [1.1e-10, 4.83261533939, 25132.3033999656],
      [1.1e-10, 5.84259014283, 6525.8044539654],
      [1.2e-10, 3.82296977522, 7058.5984613154],
      [1.3e-10, 2.39991715131, 5729.506447149],
      [8e-11, 0.55390332094, 6040.3472460174],
      [8e-11, 1.46298993048, 11856.2186514245],
      [8e-11, 5.07535888338, 6256.7775301916],
      [6e-11, 2.88803526743, 5507.5532386674],
      [5e-11, 3.87019253131, 12036.4607348882],
      [5e-11, 2.70838853362, 83996.84731811189]
    ],
    "4": [
      [7.714e-8, 4.14117321449, 6283.0758499914],
      [1.016e-8, 3.27573644241, 12566.1516999828],
      [4.2e-9, 0.41892851415, 155.4203994342],
      [4.7e-10, 3.50591071186, 18849.2275499742],
      [4.1e-10, 3.14032562331, 3.523118349],
      [3.5e-10, 5.0111077, 5573.1428014331],
      [1e-10, 5.64816633449, 6127.6554505572],
      [1.3e-10, 0.48609240774, 77713.7714681205],
      [7e-11, 2.84139222289, 161000.6857376741],
      [4e-11, 3.6550904707, 25132.3033999656],
      [2e-11, 0.54880603487, 6438.4962494256]
    ],
    "5": [
      [1.72e-9, 2.74854172392, 6283.0758499914],
      [5e-10, 2.01352986713, 155.4203994342],
      [2.8e-10, 2.93369985477, 12566.1516999828],
      [5e-11, 1.93829214518, 18849.2275499742]
    ]
  },
  B: {
    "0": [
      [0.0000027962, 3.19870156017, 84334.66158130829],
      [0.00000101643, 5.42248619256, 5507.5532386674],
      [8.0445e-7, 3.88013204458, 5223.6939198022],
      [4.3806e-7, 3.70444689759, 2352.8661537718],
      [3.1933e-7, 4.00026369781, 1577.3435424478],
      [2.2724e-7, 3.9847383156, 1047.7473117547],
      [1.6392e-7, 3.56456119782, 5856.4776591154],
      [1.8141e-7, 4.98367470262, 6283.0758499914],
      [1.4443e-7, 3.70275614915, 9437.762934887],
      [1.4304e-7, 3.41117857526, 10213.285546211],
      [1.1246e-7, 4.82820690527, 14143.4952424306],
      [1.09e-7, 2.08574562329, 6812.766815086],
      [9.714e-8, 3.47303947751, 4694.0029547076],
      [1.0367e-7, 4.05663927945, 71092.88135493269],
      [8.775e-8, 4.44016515666, 5753.3848848968],
      [8.366e-8, 4.99251512183, 7084.8967811152],
      [6.921e-8, 4.32559054073, 6275.9623029906],
      [9.145e-8, 1.14182646613, 6620.8901131878],
      [7.194e-8, 3.60193205744, 529.6909650946],
      [7.698e-8, 5.55425745881, 167621.5758508619],
      [5.285e-8, 2.48446991536, 4705.7323075436],
      [5.208e-8, 6.24992674532, 18073.7049386502],
      [4.529e-8, 2.33827747356, 6309.3741697912],
      [5.579e-8, 4.41023653719, 7860.4193924392],
      [4.743e-8, 0.70995680136, 5884.9268465832],
      [4.301e-8, 1.10255777773, 6681.2248533996],
      [3.849e-8, 1.82229412532, 5486.777843175],
      [4.093e-8, 5.11700141197, 13367.9726311066],
      [3.681e-8, 0.43793170336, 3154.6870848956],
      [3.42e-8, 5.42034800952, 6069.7767545534],
      [3.617e-8, 6.04641937568, 3930.2096962196],
      [3.67e-8, 4.58210192227, 12194.0329146209],
      [2.918e-8, 1.95463881136, 10977.078804699],
      [2.797e-8, 5.61259274877, 11790.6290886588],
      [2.502e-8, 0.60499729368, 6496.3749454294],
      [2.319e-8, 5.01648216088, 1059.3819301892],
      [2.684e-8, 1.39470396487, 22003.9146348698],
      [2.428e-8, 3.24183056545, 78051.5857313169],
      [2.12e-8, 4.30691000285, 5643.1785636774],
      [2.257e-8, 3.15557225087, 90617.7374312997],
      [1.813e-8, 3.75574218286, 3340.6124266998],
      [2.226e-8, 2.79699346673, 12036.4607348882],
      [1.888e-8, 0.86991545944, 8635.9420037632],
      [1.517e-8, 1.9585205571, 398.1490034082],
      [1.581e-8, 3.19976230948, 5088.6288397668],
      [1.421e-8, 6.25530883828, 2544.3144198834],
      [1.595e-8, 0.25619915132, 17298.1823273262],
      [1.391e-8, 4.69964175574, 7058.5984613154],
      [1.478e-8, 2.81808207569, 25934.1243310894],
      [1.481e-8, 3.6582355461, 11506.7697697936],
      [1.693e-8, 4.95689385293, 156475.2902479957],
      [1.183e-8, 1.29343060777, 775.522611324],
      [1.114e-8, 2.37889311847, 3738.761430108],
      [9.94e-9, 4.30088900425, 9225.539273283],
      [9.24e-9, 3.06451026809, 4164.311989613],
      [8.67e-9, 0.55606931068, 8429.2412664666],
      [9.88e-9, 5.97286104208, 7079.3738568078],
      [8.24e-9, 1.50984806177, 10447.3878396044],
      [9.15e-9, 0.12635654605, 11015.1064773348],
      [7.42e-9, 1.99159139281, 26087.9031415742],
      [1.039e-8, 3.14159265359, 0],
      [8.5e-9, 4.24120016095, 29864.334027309],
      [7.55e-9, 2.89631873314, 4732.0306273434],
      [7.14e-9, 1.37548118605, 2146.1654164752],
      [7.08e-9, 1.91406542362, 8031.0922630584],
      [7.46e-9, 0.57893808622, 796.2980068164],
      [8.02e-9, 5.12339137235, 2942.4634232916],
      [7.51e-9, 1.67479850166, 21228.3920235458],
      [6.02e-9, 4.099765389, 64809.80550494129],
      [5.94e-9, 3.49580704849, 16496.3613962024],
      [5.92e-9, 4.59481504319, 4690.4798363586],
      [5.3e-9, 5.73979295194, 8827.3902698748],
      [5.03e-9, 5.66433137112, 33794.5437235286],
      [4.83e-9, 1.57106522322, 801.8209311238],
      [4.38e-9, 0.0670773372, 3128.3887650958],
      [4.23e-9, 2.86944596145, 12566.1516999828],
      [5.04e-9, 3.2620766916, 7632.9432596502],
      [5.52e-9, 1.02926440457, 239762.20451754928],
      [4.27e-9, 3.67434378208, 213.299095438],
      [4.04e-9, 1.4619329736, 15720.8387848784],
      [5.03e-9, 4.85802444134, 6290.1893969922],
      [4.17e-9, 0.81920713533, 5216.5803728014],
      [3.65e-9, 0.01002966145, 12168.0026965746],
      [3.63e-9, 1.28376436579, 6206.8097787158],
      [3.53e-9, 4.7005913311, 7234.794256242],
      [4.15e-9, 0.96862624176, 4136.9104335162],
      [3.87e-9, 3.09145061418, 25158.6017197654],
      [3.73e-9, 2.65119262808, 7342.4577801806],
      [3.61e-9, 2.97762937735, 9623.6882766912],
      [4.18e-9, 3.75759994446, 5230.807466803],
      [3.96e-9, 1.22507712354, 6438.4962494256],
      [3.22e-9, 1.21162178805, 8662.240323563],
      [2.84e-9, 5.64170320179, 1589.0728952838],
      [3.79e-9, 1.72248432756, 14945.3161735544],
      [3.2e-9, 3.94161159658, 7330.8231617461],
      [3.13e-9, 5.47602376451, 1194.4470102246],
      [2.92e-9, 1.38971327568, 11769.8536931664],
      [3.05e-9, 0.80429352049, 37724.7534197482],
      [2.57e-9, 5.81382810029, 426.598190876],
      [2.65e-9, 6.10358507671, 6836.6452528338],
      [2.5e-9, 4.56452895547, 7477.522860216],
      [2.66e-9, 2.62926282354, 7238.6755916],
      [2.63e-9, 6.22089501237, 6133.5126528568],
      [3.06e-9, 2.79682380532, 1748.016413067],
      [2.36e-9, 2.46093023707, 11371.7046897582],
      [3.16e-9, 1.62662805006, 250908.4901204155],
      [2.16e-9, 3.68721275185, 5849.3641121146],
      [2.3e-9, 0.36165162947, 5863.5912061162],
      [2.33e-9, 5.03509933618, 20426.571092422],
      [2e-9, 5.86073159059, 4535.0594369244],
      [2.77e-9, 4.65400292395, 82239.1669577989],
      [2.09e-9, 3.72323200803, 10973.55568635],
      [1.99e-9, 5.05186622555, 5429.8794682394],
      [2.56e-9, 2.40923279886, 19651.048481098],
      [2.1e-9, 4.50691909144, 29088.811415985],
      [1.81e-9, 6.00294783127, 4292.3308329504],
      [2.49e-9, 0.12900984422, 154379.7956244863],
      [2.09e-9, 3.87759458541, 17789.845619785],
      [2.25e-9, 3.18339652605, 18875.525869774],
      [1.91e-9, 4.53897489216, 18477.1087646123],
      [1.72e-9, 2.0969418273, 13095.8426650774],
      [1.82e-9, 3.16107943487, 16730.4636895958],
      [1.88e-9, 2.22746128596, 41654.9631159678],
      [1.64e-9, 5.18686274999, 5481.2549188676],
      [1.6e-9, 2.4929885502, 12592.4500197826],
      [1.55e-9, 1.59595438224, 10021.8372800994],
      [1.35e-9, 0.21349051305, 10988.808157535],
      [1.78e-9, 3.80375178044, 23581.2581773176],
      [1.23e-9, 1.66800739151, 15110.4661198662],
      [1.22e-9, 2.72678272224, 18849.2275499742],
      [1.26e-9, 1.17675512872, 14919.0178537546],
      [1.42e-9, 3.95053440992, 337.8142631964],
      [1.16e-9, 6.06340906212, 6709.6740408674],
      [1.37e-9, 3.52143246244, 12139.5535091068],
      [1.36e-9, 2.92179113491, 32217.2001810808],
      [1.1e-9, 3.51203379263, 18052.9295431578],
      [1.47e-9, 4.63371971408, 22805.7355659936],
      [1.08e-9, 5.45280815225, 7.1135470008],
      [1.48e-9, 0.65447253687, 95480.9471841745],
      [1.19e-9, 5.92110458985, 33019.0211122046],
      [1.1e-9, 5.34824206403, 639.897286314],
      [1.06e-9, 3.71081682614, 14314.1681130498],
      [1.39e-9, 6.17607198262, 24356.7807886416],
      [1.18e-9, 5.59738712949, 161338.5000008705],
      [1.17e-9, 3.6506527164, 45585.1728121874],
      [1.27e-9, 4.74596574209, 49515.382508407],
      [1.2e-9, 1.04211499785, 6915.8595893046],
      [1.2e-9, 5.60638811846, 5650.2921106782],
      [1.15e-9, 3.10668213303, 14712.317116458],
      [9.9e-10, 0.6901893993, 12779.4507954208],
      [9.7e-10, 1.07908724794, 9917.6968745098],
      [9.3e-10, 2.62295197146, 17260.1546546904],
      [9.9e-10, 4.45774681732, 4933.2084403326],
      [1.23e-9, 1.37488921994, 28286.9904848612],
      [1.21e-9, 5.19767249869, 27511.4678735372],
      [1.05e-9, 0.87192268229, 77375.95720492408],
      [8.7e-10, 3.9363781295, 17654.7805397496],
      [1.22e-9, 2.2395606868, 83997.09113559539],
      [8.7e-10, 4.18201600921, 22779.4372461938],
      [1.04e-9, 4.59580877285, 1349.8674096588],
      [1.02e-9, 2.83545248595, 12352.8526045448],
      [1.02e-9, 3.97386522171, 10818.1352869158],
      [1.01e-9, 4.32892825818, 36147.4098773004],
      [9.4e-10, 5.00001709338, 150192.2143980043],
      [7.7e-10, 3.971993693, 1592.5960136328],
      [1e-9, 6.07733097102, 26735.9452622132],
      [8.6e-10, 5.2602963825, 28313.288804661],
      [9.3e-10, 4.31900620254, 44809.6502008634],
      [7.6e-10, 6.22743405935, 13521.7514415914],
      [7.2e-10, 1.55820597747, 6256.7775301916],
      [8.2e-10, 4.95202664555, 10575.4066829418],
      [8.2e-10, 1.69647647075, 1990.745017041],
      [7.5e-10, 2.29836095644, 3634.6210245184],
      [7.5e-10, 2.66367876557, 16200.7727245012],
      [8.7e-10, 0.26630214764, 31441.6775697568],
      [7.7e-10, 2.25530952876, 5235.3285382367],
      [7.6e-10, 1.09869730334, 12903.9659631792],
      [5.8e-10, 4.28246137794, 12559.038152982],
      [6.4e-10, 5.51112829602, 173904.65170085328],
      [5.6e-10, 2.60133794851, 73188.3759784421],
      [5.5e-10, 5.81483150022, 143233.51002162008],
      [5.4e-10, 3.38482031504, 323049.11878710287],
      [3.9e-10, 3.28500401937, 71768.50988132549],
      [3.9e-10, 3.11239910096, 96900.81328129109]
    ],
    "1": [
      [0.00227777722, 3.4137662053, 6283.0758499914],
      [0.00003805678, 3.37063423795, 12566.1516999828],
      [0.00003619589, 0, 0],
      [7.1542e-7, 3.32777549735, 18849.2275499742],
      [7.655e-8, 1.79489607186, 5223.6939198022],
      [8.107e-8, 3.89190403643, 5507.5532386674],
      [6.456e-8, 5.1978942475, 2352.8661537718],
      [3.894e-8, 2.15568517178, 6279.5527316424],
      [3.892e-8, 1.53021064904, 6286.5989683404],
      [3.897e-8, 4.87293945629, 10213.285546211],
      [3.812e-8, 1.43523182316, 12036.4607348882],
      [3.577e-8, 2.32913869227, 83996.84731811189],
      [3.57e-8, 4.92637739003, 71430.69561812909],
      [3.494e-8, 2.20864641831, 529.6909650946],
      [2.421e-8, 6.22876183393, 7860.4193924392],
      [2.056e-8, 3.06747139741, 14143.4952424306],
      [1.399e-8, 0.50107877909, 6309.3741697912],
      [1.417e-8, 3.28454570977, 25132.3033999656],
      [1.544e-8, 1.82062047625, 5856.4776591154],
      [1.457e-8, 1.75339303307, 5884.9268465832],
      [1.497e-8, 2.19673914456, 9437.762934887],
      [1.549e-8, 5.73650061398, 17789.845619785],
      [1.277e-8, 3.9672171733, 4705.7323075436],
      [1.038e-8, 2.9481870117, 6256.7775301916],
      [1.018e-8, 2.24114547164, 6681.2248533996],
      [1.021e-8, 1.4679502613, 11790.6290886588],
      [9.16e-9, 3.72965830745, 1059.3819301892],
      [1.156e-8, 6.04591336669, 398.1490034082],
      [1.24e-8, 0.77195902957, 6812.766815086],
      [1.115e-8, 3.92255876225, 12168.0026965746],
      [9.08e-9, 1.78447918237, 3930.2096962196],
      [8.33e-9, 0.09941579828, 11506.7697697936],
      [7.9e-9, 2.45555993228, 775.522611324],
      [6.66e-9, 4.40761401665, 5753.3848848968],
      [5.2e-9, 1.60179602491, 4694.0029547076],
      [3.82e-9, 0.26754532042, 1577.3435424478],
      [4.05e-9, 1.97558286872, 6283.0085396886],
      [4.05e-9, 1.53147989887, 6283.14316029419],
      [3.88e-9, 2.59563818411, 7058.5984613154],
      [3.41e-9, 3.61275156842, 13367.9726311066],
      [2.8e-9, 4.63052251735, 796.2980068164],
      [2.76e-9, 0.43350778219, 7079.3738568078],
      [3.11e-9, 5.28219636656, 17260.1546546904],
      [2.75e-9, 5.27597553634, 11769.8536931664],
      [2.86e-9, 2.98639716345, 6275.9623029906],
      [2.51e-9, 2.81315684448, 6290.1893969922],
      [2.39e-9, 5.77837903893, 10977.078804699],
      [2.28e-9, 0.14375973844, 3738.761430108],
      [2.27e-9, 2.51020991853, 7084.8967811152],
      [2.11e-9, 4.7202946547, 6496.3749454294],
      [2.34e-9, 3.1153527401, 709.9330485583],
      [2.34e-9, 0.5707581762, 11856.2186514245],
      [2.37e-9, 2.38791907394, 213.299095438],
      [2.27e-9, 1.12059781634, 12352.8526045448],
      [1.69e-9, 3.20148089605, 5486.777843175],
      [1.59e-9, 3.14604135756, 8827.3902698748],
      [1.6e-9, 1.14784478002, 5643.1785636774],
      [1.44e-9, 5.23285657431, 78051.5857313169],
      [1.44e-9, 1.16454654602, 90617.7374312997],
      [1.44e-9, 3.81203756929, 6262.300454499],
      [1.43e-9, 5.97809021355, 6303.8512454838],
      [1.38e-9, 4.29829933273, 1589.0728952838],
      [1.61e-9, 3.03298851492, 20426.571092422],
      [1.65e-9, 5.09134896587, 17298.1823273262],
      [1.28e-9, 4.41590143012, 6127.6554505572],
      [1.58e-9, 2.00984506334, 5230.807466803],
      [1.3e-9, 1.44170683802, 12569.6748183318],
      [1.25e-9, 1.69950379498, 3128.3887650958],
      [1.52e-9, 4.96946111415, 65147.6197681377],
      [1.31e-9, 4.24961399629, 6282.0955289232],
      [1.31e-9, 5.54051222995, 6284.0561710596],
      [1.61e-9, 3.32421999691, 6283.3196674749],
      [1.23e-9, 2.93221463795, 15720.8387848784],
      [1.52e-9, 1.56488157456, 18319.5365848796],
      [1.14e-9, 1.89110005546, 18073.7049386502],
      [1.13e-9, 4.95214866677, 4136.9104335162],
      [1.11e-9, 4.79699611405, 8429.2412664666],
      [1.18e-9, 3.06184958762, 22003.9146348698],
      [1.28e-9, 2.09693027395, 12562.6285816338],
      [1.38e-9, 0.84648544836, 6069.7767545534],
      [1e-9, 0.41938790104, 5481.2549188676],
      [1.01e-9, 4.43791289246, 19651.048481098],
      [1.16e-9, 1.87002428935, 77713.7714681205],
      [9.5e-10, 0.68638183645, 3340.6124266998],
      [1.04e-9, 4.90822646457, 8635.9420037632],
      [1.14e-9, 0.36008214928, 23543.23050468179],
      [9.1e-10, 1.1761121931, 9225.539273283],
      [9.1e-10, 2.74682631104, 5216.5803728014],
      [8.6e-10, 3.09315282195, 1194.4470102246],
      [9.3e-10, 6.2764351319, 12559.038152982],
      [8.7e-10, 3.64926989547, 7.1135470008],
      [8.3e-10, 0.37008971174, 11371.7046897582],
      [9.8e-10, 4.68473306376, 167283.7615876655],
      [9.8e-10, 0.99865886857, 154717.6098876827],
      [8.6e-10, 4.41152307486, 25934.1243310894],
      [9.8e-10, 4.98209568932, 16496.3613962024],
      [8.5e-10, 2.35438163823, 3154.6870848956],
      [8.3e-10, 0.24859477658, 21228.3920235458],
      [7.6e-10, 5.46661600296, 29864.334027309],
      [6.9e-10, 3.22045334237, 801.8209311238],
      [6.8e-10, 2.19928242745, 37724.7534197482],
      [7e-10, 5.33711014698, 2544.3144198834],
      [5.5e-10, 5.37872238211, 11015.1064773348],
      [5.1e-10, 5.03600618727, 5863.5912061162],
      [5.9e-10, 3.54984809612, 4535.0594369244],
      [5.3e-10, 1.50812064137, 7342.4577801806],
      [4.4e-10, 1.33712241647, 4164.311989613],
      [5.5e-10, 5.38460045253, 7477.522860216],
      [4.9e-10, 5.61844340512, 24072.9214697764],
      [5.7e-10, 6.17973522898, 8031.0922630584],
      [5.2e-10, 4.24379064407, 5088.6288397668],
      [4.1e-10, 3.41960196246, 26087.9031415742],
      [5.2e-10, 1.68150981131, 25158.6017197654],
      [5.3e-10, 0.11041408311, 29088.811415985],
      [4.3e-10, 0.53354396923, 2146.1654164752],
      [5e-10, 0.60270799844, 33794.5437235286],
      [4.5e-10, 1.69577010458, 6702.5604938666],
      [3.7e-10, 6.09033460795, 64809.80550494129],
      [4.4e-10, 2.67993061247, 15110.4661198662],
      [4.7e-10, 3.62555676035, 41654.9631159678],
      [3.6e-10, 0.47219666269, 13095.8426650774],
      [3.5e-10, 5.99520523215, 23581.2581773176],
      [4.3e-10, 4.08726331262, 156137.47598479927],
      [4.3e-10, 5.17376496602, 18422.62935909819],
      [3.4e-10, 2.14460100274, 4933.2084403326],
      [3e-10, 4.12992793541, 426.598190876],
      [3.6e-10, 2.28596930002, 90279.92316810328],
      [3.2e-10, 0.81117747619, 26.2983197998],
      [2.8e-10, 4.07036560467, 14712.317116458],
      [2.5e-10, 1.92905243842, 1748.016413067],
      [2.4e-10, 5.06152880842, 16730.4636895958],
      [2.3e-10, 3.24047012195, 31415.379249957],
      [2.7e-10, 3.90672018305, 18451.07854656599],
      [2.1e-10, 3.51419811826, 10447.3878396044]
    ],
    "2": [
      [0.00009721424, 5.1519280992, 6283.0758499914],
      [0.00000233002, 3.14159265359, 0],
      [0.00000134188, 0.64406212977, 12566.1516999828],
      [6.504e-8, 1.07333397797, 18849.2275499742],
      [1.662e-8, 1.62746869551, 84334.66158130829],
      [6.35e-9, 3.51985338656, 6279.5527316424],
      [4.92e-9, 2.41382223971, 1047.7473117547],
      [3.07e-9, 6.10181422085, 5223.6939198022],
      [3.22e-9, 0.37660897389, 6286.5989683404],
      [3.26e-9, 2.35727931602, 5507.5532386674],
      [2.74e-9, 1.65307581765, 7860.4193924392],
      [2.28e-9, 1.14082932988, 25132.3033999656],
      [2.02e-9, 0.4983668253, 2352.8661537718],
      [2.01e-9, 0.155527656, 10213.285546211],
      [1.67e-9, 3.98005254015, 529.6909650946],
      [1.7e-9, 5.28668290523, 6256.7775301916],
      [1.66e-9, 3.04613930284, 12036.4607348882],
      [1.53e-9, 4.06779216239, 83996.84731811189],
      [1.5e-9, 3.18772213951, 71430.69561812909],
      [1.2e-9, 3.13558669517, 5884.9268465832],
      [1.23e-9, 4.17102530625, 6309.3741697912],
      [1e-9, 1.46356761368, 11506.7697697936],
      [1e-9, 3.00322421365, 11790.6290886588],
      [7.7e-10, 1.65643898948, 4705.7323075436],
      [6.8e-10, 6.03791904123, 6812.766815086],
      [7.5e-10, 4.85191600582, 14143.4952424306],
      [7.9e-10, 4.12628805658, 5753.3848848968],
      [6.4e-10, 2.7194416046, 6127.6554505572],
      [6.3e-10, 0.78655326011, 6438.4962494256],
      [7.5e-10, 0.84213523741, 167621.5758508619],
      [5.1e-10, 4.55059044701, 5486.777843175],
      [5e-10, 5.29314320585, 7079.3738568078],
      [4.5e-10, 0.33147576416, 775.522611324],
      [4.3e-10, 3.6179371164, 1577.3435424478],
      [5.7e-10, 6.15295833679, 12194.0329146209],
      [4.5e-10, 1.18274698508, 17789.845619785],
      [4.7e-10, 1.11643162773, 398.1490034082],
      [4.7e-10, 5.5298423843, 12168.0026965746],
      [4.2e-10, 2.6437530062, 10988.808157535],
      [3.7e-10, 0.31226891972, 6681.2248533996],
      [4.6e-10, 3.38617099014, 156475.2902479957],
      [3.3e-10, 1.26266496002, 6290.1893969922],
      [3.3e-10, 2.25067065498, 6275.9623029906],
      [2.7e-10, 1.72859626293, 1059.3819301892],
      [2.8e-10, 2.88681054153, 796.2980068164],
      [3e-10, 5.52021264181, 7058.5984613154],
      [2.1e-10, 6.22275008403, 6069.7767545534],
      [2.4e-10, 5.83690442827, 6282.0955289232],
      [2.4e-10, 3.95322179797, 6284.0561710596],
      [2.1e-10, 4.87022458966, 3738.761430108],
      [2.1e-10, 3.58048145762, 6496.3749454294],
      [2.2e-10, 1.97770318395, 7.1135470008],
      [1.9e-10, 3.4597988877, 4136.9104335162],
      [1.5e-10, 2.81379880771, 1589.0728952838],
      [1.5e-10, 1.61313151838, 1194.4470102246],
      [1.5e-10, 0.63083434831, 11769.8536931664],
      [1.9e-10, 0.28082047767, 17260.1546546904],
      [1.7e-10, 1.35165136146, 78051.5857313169],
      [1.5e-10, 2.3094159956, 11856.2186514245],
      [1.2e-10, 1.12997370513, 90617.7374312997],
      [1.2e-10, 0.00611601597, 18073.7049386502],
      [1.2e-10, 1.70058636365, 12559.038152982]
    ],
    "3": [
      [0.00000275993, 0.59480097092, 6283.0758499914],
      [1.7034e-7, 3.14159265359, 0],
      [3.617e-8, 0.11750575325, 12566.1516999828],
      [3.39e-9, 5.66087461682, 18849.2275499742],
      [5.6e-10, 5.02765554835, 6279.5527316424],
      [1.9e-10, 5.99007646261, 6256.7775301916],
      [1.8e-10, 3.80004734567, 6309.3741697912],
      [1.8e-10, 1.21049250774, 6127.6554505572],
      [1.8e-10, 2.29734567137, 6438.4962494256],
      [1.5e-10, 4.72881467263, 6286.5989683404],
      [1.2e-10, 0.41481671808, 83996.84731811189],
      [1.3e-10, 5.54637369296, 25132.3033999656],
      [1e-10, 2.91937214232, 71430.69561812909],
      [6e-11, 2.1417324121, 11856.2186514245]
    ],
    "4": [
      [5.745e-8, 2.26734029843, 6283.0758499914],
      [8.7e-9, 0, 0],
      [1.19e-9, 4.26807972611, 12566.1516999828],
      [1.7e-10, 4.0742262044, 18849.2275499742],
      [5e-11, 0.84308705203, 1047.7473117547],
      [5e-11, 0.05711572303, 84334.66158130829]
    ],
    "5": [
      [1.14e-9, 4.31455980099, 6283.0758499914],
      [2.4e-10, 0, 0]
    ]
  },
  R: {
    "0": [
      [1.00013988784, 0, 0],
      [0.01670699632, 3.09846350258, 6283.0758499914],
      [0.00013956024, 3.05524609456, 12566.1516999828],
      [0.0000308372, 5.19846674381, 77713.7714681205],
      [0.00001628463, 1.17387558054, 5753.3848848968],
      [0.00001575572, 2.84685214877, 7860.4193924392],
      [0.00000924799, 5.45292236722, 11506.7697697936],
      [0.00000542439, 4.56409151453, 3930.2096962196],
      [0.0000047211, 3.66100022149, 5884.9268465832],
      [0.0000032878, 5.89983686142, 5223.6939198022],
      [0.00000345969, 0.96368627272, 5507.5532386674],
      [0.00000306784, 0.29867139512, 5573.1428014331],
      [0.00000174844, 3.01193636733, 18849.2275499742],
      [0.00000243181, 4.2734953079, 11790.6290886588],
      [0.00000211836, 5.84714461348, 1577.3435424478],
      [0.0000018574, 5.02199710705, 10977.078804699],
      [0.00000109835, 5.0551063586, 5486.777843175],
      [9.8316e-7, 0.88681311278, 6069.7767545534],
      [8.65e-7, 5.68956418946, 15720.8387848784],
      [8.5831e-7, 1.27079125277, 161000.6857376741],
      [6.2917e-7, 0.92177053978, 529.6909650946],
      [5.7056e-7, 2.01374292245, 83996.84731811189],
      [6.4908e-7, 0.27251341435, 17260.1546546904],
      [4.9384e-7, 3.24501240359, 2544.3144198834],
      [5.5736e-7, 5.2415979917, 71430.69561812909],
      [4.252e-7, 6.01110257982, 6275.9623029906],
      [4.6966e-7, 2.57799853213, 775.522611324],
      [3.8963e-7, 5.36063832897, 4694.0029547076],
      [4.4666e-7, 5.53715663816, 9437.762934887],
      [3.5661e-7, 1.67447135798, 12036.4607348882],
      [3.1922e-7, 0.18368299942, 5088.6288397668],
      [3.1846e-7, 1.77775642078, 398.1490034082],
      [3.3193e-7, 0.24370221704, 7084.8967811152],
      [3.8245e-7, 2.39255343973, 8827.3902698748],
      [2.8468e-7, 1.21344887533, 6286.5989683404],
      [3.7486e-7, 0.82961281844, 19651.048481098],
      [3.6957e-7, 4.90107587287, 12139.5535091068],
      [3.4537e-7, 1.84270693281, 2942.4634232916],
      [2.6275e-7, 4.58896863104, 10447.3878396044],
      [2.4596e-7, 3.78660838036, 8429.2412664666],
      [2.3587e-7, 0.26866098169, 796.2980068164],
      [2.7795e-7, 1.89934427832, 6279.5527316424],
      [2.3927e-7, 4.99598548145, 5856.4776591154],
      [2.0345e-7, 4.65282190725, 2146.1654164752],
      [2.3287e-7, 2.80783632869, 14143.4952424306],
      [2.2099e-7, 1.95002636847, 3154.6870848956],
      [1.9509e-7, 5.38233922479, 2352.8661537718],
      [1.7958e-7, 0.1987136996, 6812.766815086],
      [1.7178e-7, 4.43322156854, 10213.285546211],
      [1.619e-7, 5.23159323213, 17789.845619785],
      [1.7315e-7, 6.15224075188, 16730.4636895958],
      [1.3814e-7, 5.18962074032, 8031.0922630584],
      [1.8834e-7, 0.67280058021, 149854.4001348079],
      [1.833e-7, 2.25348717053, 23581.2581773176],
      [1.3639e-7, 3.68511810757, 4705.7323075436],
      [1.3142e-7, 0.65267698994, 13367.9726311066],
      [1.0414e-7, 4.33285688501, 11769.8536931664],
      [9.978e-8, 4.20126336356, 6309.3741697912],
      [1.017e-7, 1.59366684542, 4690.4798363586],
      [7.564e-8, 2.62560597391, 6256.7775301916],
      [9.654e-8, 3.67583728703, 27511.4678735372],
      [6.743e-8, 0.56269927047, 3340.6124266998],
      [8.743e-8, 6.06359123461, 1748.016413067],
      [7.786e-8, 3.67371235367, 12168.0026965746],
      [6.633e-8, 5.66149277789, 11371.7046897582],
      [7.712e-8, 0.31242577788, 7632.9432596502],
      [6.586e-8, 3.13580054586, 801.8209311238],
      [7.46e-8, 5.6475806666, 11926.2544136688],
      [6.933e-8, 2.92384586372, 6681.2248533996],
      [6.805e-8, 1.42327153767, 23013.5395395872],
      [6.118e-8, 5.13395999022, 1194.4470102246],
      [6.477e-8, 2.64986648493, 19804.8272915828],
      [5.233e-8, 4.62432817299, 6438.4962494256],
      [6.147e-8, 3.02863936662, 233141.3144043615],
      [4.608e-8, 1.72194702724, 7234.794256242],
      [4.221e-8, 1.55697533726, 7238.6755916],
      [5.31e-8, 2.40821524293, 11499.6562227928],
      [5.128e-8, 5.3239896569, 11513.8833167944],
      [4.77e-8, 0.2555431173, 11856.2186514245],
      [5.519e-8, 2.09089153789, 17298.1823273262],
      [5.625e-8, 4.34052903053, 90955.5516944961],
      [4.578e-8, 4.4656964157, 5746.271337896],
      [3.788e-8, 4.9072829481, 4164.311989613],
      [5.337e-8, 5.09957905103, 31441.6775697568],
      [3.967e-8, 1.20054555175, 1349.8674096588],
      [4.005e-8, 3.02853885902, 1059.3819301892],
      [3.48e-8, 0.76066308841, 10973.55568635],
      [4.232e-8, 1.05485713117, 5760.4984318976],
      [4.582e-8, 3.76570026763, 6386.16862421],
      [3.335e-8, 3.13829943354, 6836.6452528338],
      [3.42e-8, 3.00043974511, 4292.3308329504],
      [3.595e-8, 5.70703236079, 5643.1785636774],
      [3.236e-8, 4.16387400645, 9917.6968745098],
      [4.154e-8, 2.59940749519, 7058.5984613154],
      [3.362e-8, 4.54577164994, 4732.0306273434],
      [2.978e-8, 1.3056126882, 6283.14316029419],
      [2.765e-8, 0.51311975671, 26.2983197998],
      [2.807e-8, 5.66230537649, 8635.9420037632],
      [2.927e-8, 5.7378783408, 16200.7727245012],
      [3.167e-8, 1.691817599, 11015.1064773348],
      [2.598e-8, 2.96244118358, 25132.3033999656],
      [3.519e-8, 3.62639325753, 244287.60000722768],
      [2.676e-8, 4.20727719487, 18073.7049386502],
      [2.978e-8, 1.74971565805, 6283.0085396886],
      [2.287e-8, 1.06976449088, 14314.1681130498],
      [2.863e-8, 5.92838917309, 14712.317116458],
      [3.071e-8, 0.23793217, 35371.8872659764],
      [2.656e-8, 0.89959301615, 12352.8526045448],
      [2.415e-8, 2.799751768, 709.9330485583],
      [2.811e-8, 3.51513864541, 21228.3920235458],
      [1.977e-8, 2.61358297551, 951.7184062506],
      [2.548e-8, 2.47684686575, 6208.2942514241],
      [1.999e-8, 0.56090396506, 7079.3738568078],
      [2.305e-8, 1.05376463592, 22483.84857449259],
      [1.855e-8, 2.86093570752, 5216.5803728014],
      [2.157e-8, 1.31395211105, 154717.6098876827],
      [1.97e-8, 4.36931551625, 167283.7615876655],
      [1.754e-8, 2.14452400686, 6290.1893969922],
      [1.628e-8, 5.85704450617, 10984.1923516998],
      [2.154e-8, 6.03828353794, 10873.9860304804],
      [1.714e-8, 3.70158195222, 1592.5960136328],
      [1.541e-8, 6.21599512982, 23543.23050468179],
      [1.602e-8, 1.99860679677, 10969.9652576982],
      [1.712e-8, 1.34295218697, 3128.3887650958],
      [1.647e-8, 5.54948299069, 6496.3749454294],
      [1.495e-8, 5.43980459648, 155.4203994342],
      [1.827e-8, 5.91227480351, 3738.761430108],
      [1.726e-8, 2.16765465036, 10575.4066829418],
      [1.532e-8, 5.35683107063, 13521.7514415914],
      [1.824e-8, 1.66056145084, 39302.096962196],
      [1.605e-8, 1.90930973224, 6133.5126528568],
      [1.282e-8, 2.46013372544, 13916.0191096416],
      [1.211e-8, 4.4136063155, 3894.1818295422],
      [1.394e-8, 1.7780192925, 9225.539273283],
      [1.571e-8, 4.95512957606, 25158.6017197654],
      [1.205e-8, 1.19212756308, 3.523118349],
      [1.132e-8, 2.69830084955, 6040.3472460174],
      [1.504e-8, 5.77577388271, 18209.33026366019],
      [1.393e-8, 1.62625077326, 5120.6011455836],
      [1.081e-8, 2.93726744446, 17256.6315363414],
      [1.232e-8, 0.71651766504, 143571.32428481648],
      [1.087e-8, 0.99769687961, 955.5997416086],
      [1.068e-8, 5.28472576591, 65147.6197681377],
      [1.169e-8, 3.11663802316, 14945.3161735544],
      [9.75e-9, 5.1088726078, 6172.869528772],
      [1.202e-8, 4.02992510403, 553.5694028424],
      [9.79e-9, 2.00000879106, 15110.4661198662],
      [9.62e-9, 4.023807714, 6282.0955289232],
      [9.99e-9, 3.6264300279, 6262.300454499],
      [1.03e-8, 5.84987815239, 213.299095438],
      [1.014e-8, 2.84227679965, 8662.240323563],
      [1.185e-8, 1.51330629149, 17654.7805397496],
      [9.67e-9, 2.67081017562, 5650.2921106782],
      [1.222e-8, 2.65423784904, 88860.05707098669],
      [9.86e-9, 2.36212814824, 6206.8097787158],
      [1.034e-8, 0.13634950642, 11712.9553182308],
      [1.103e-8, 3.08477302937, 43232.3066584156],
      [7.81e-9, 2.53374971725, 16496.3613962024],
      [1.019e-8, 3.04569392376, 6037.244203762],
      [7.95e-9, 5.80662989126, 5230.807466803],
      [8.13e-9, 3.57702871938, 10177.2576795336],
      [9.62e-9, 5.31470594766, 6284.0561710596],
      [7.17e-9, 5.95797471837, 12559.038152982],
      [9.67e-9, 2.74413738053, 6244.9428143536],
      [9.21e-9, 0.1016016083, 29088.811415985],
      [7.19e-9, 5.91788189939, 4136.9104335162],
      [6.88e-9, 3.89489045092, 1589.0728952838],
      [7.72e-9, 4.05505380285, 6127.6554505572],
      [7.06e-9, 5.49323197725, 22003.9146348698],
      [6.65e-9, 1.60002747134, 11087.2851259184],
      [6.9e-9, 4.50539825729, 426.598190876],
      [8.54e-9, 3.2610464506, 20426.571092422],
      [6.56e-9, 4.3241018294, 16858.4825329332],
      [8.4e-9, 2.59572585212, 28766.924424484],
      [6.86e-9, 0.61944033771, 11403.676995575],
      [7e-9, 3.40901412473, 7.1135470008],
      [7.28e-9, 0.04050185963, 5481.2549188676],
      [6.53e-9, 1.0386945123, 6062.6632075526],
      [5.59e-9, 4.79221805695, 20199.094959633],
      [6.33e-9, 5.70229959167, 45892.73043315699],
      [5.91e-9, 6.10986487621, 9623.6882766912],
      [5.2e-9, 3.62310356479, 5333.9002410216],
      [6.02e-9, 5.58381898589, 10344.2950653858],
      [4.96e-9, 2.21027756314, 1990.745017041],
      [6.91e-9, 1.96733114988, 12416.5885028482],
      [6.4e-9, 1.59062417043, 18319.5365848796],
      [6.25e-9, 3.82358168221, 13517.8701062334],
      [4.75e-9, 1.1702590418, 12569.6748183318],
      [6.6e-9, 5.08498512995, 283.8593188652],
      [6.64e-9, 4.50029469969, 47162.5163546352],
      [5.69e-9, 0.16318535463, 17267.26820169119],
      [5.68e-9, 3.86100969474, 6076.8903015542],
      [4.62e-9, 0.26368763517, 4590.910180489],
      [5.35e-9, 4.83225423196, 18422.62935909819],
      [4.66e-9, 0.75873879417, 7342.4577801806],
      [5.41e-9, 3.07212190556, 226858.23855437007],
      [6.1e-9, 1.53597089605, 33019.0211122046],
      [6.17e-9, 2.62356328726, 11190.377900137],
      [5.48e-9, 4.55798855803, 18875.525869774],
      [6.33e-9, 4.60110281228, 66567.48586525429],
      [5.87e-9, 5.78087907808, 632.7837393132],
      [6.03e-9, 5.38458554802, 316428.22867391503],
      [5.25e-9, 5.01522072363, 12132.439962106],
      [4.69e-9, 0.59975173763, 21954.15760939799],
      [5.48e-9, 3.50627043672, 17253.04110768959],
      [5.02e-9, 0.98804327589, 11609.8625440122],
      [5.68e-9, 1.98497313089, 7668.6374249425],
      [4.82e-9, 1.62460405687, 12146.6670561076],
      [3.91e-9, 3.68718382972, 18052.9295431578],
      [4.57e-9, 3.7721489661, 156137.47598479927],
      [4.01e-9, 5.2922154024, 15671.0817594066],
      [4.69e-9, 1.80963351735, 12562.6285816338],
      [5.14e-9, 3.37031288919, 20597.2439630412],
      [4.52e-9, 5.66811219778, 10454.5013866052],
      [3.75e-9, 4.98528185039, 9779.1086761254],
      [5.23e-9, 0.97215560834, 155427.542936241],
      [4.03e-9, 5.1394818977, 1551.045222648],
      [3.72e-9, 3.69883738807, 9388.0059094152],
      [3.67e-9, 4.43875659833, 4535.0594369244],
      [4.06e-9, 4.20863156497, 12592.4500197826],
      [3.62e-9, 2.55099560446, 242.728603974],
      [4.71e-9, 4.61907324819, 5436.9930152402],
      [3.88e-9, 4.960209284, 24356.7807886416],
      [4.41e-9, 5.83872966262, 3496.032826134],
      [3.49e-9, 6.16307810648, 19800.9459562248],
      [3.56e-9, 0.2381908124, 5429.8794682394],
      [3.46e-9, 5.60809622572, 2379.1644735716],
      [3.8e-9, 2.72105213132, 11933.3679606696],
      [4.32e-9, 0.24215988572, 17996.0311682222],
      [3.78e-9, 5.22516848076, 7477.522860216],
      [3.37e-9, 5.10885555836, 5849.3641121146],
      [3.15e-9, 0.57827745123, 10557.5941608238],
      [3.18e-9, 4.4994900732, 3634.6210245184],
      [3.23e-9, 1.55850824803, 10440.2742926036],
      [3.14e-9, 5.77154773334, 20.7753954924],
      [3.03e-9, 2.34615580398, 4686.8894077068],
      [4.14e-9, 5.9323760231, 51092.7260508548],
      [3.62e-9, 2.17561997119, 28237.2334593894],
      [2.88e-9, 0.18377405421, 13095.8426650774],
      [2.77e-9, 5.1295220503, 13119.72110282519],
      [3.25e-9, 6.18608287927, 6268.8487559898],
      [2.73e-9, 0.30522428863, 23141.5583829246],
      [2.67e-9, 5.76152585786, 5966.6839803348],
      [3.45e-9, 2.94246040875, 36949.2308084242],
      [2.53e-9, 5.20994580359, 24072.9214697764],
      [3.42e-9, 5.76212804329, 16460.33352952499],
      [3.07e-9, 6.01039067183, 22805.7355659936],
      [2.61e-9, 2.00304796059, 6148.010769956],
      [2.38e-9, 5.08241964961, 6915.8595893046],
      [2.49e-9, 2.94762789744, 135.0650800354],
      [3.06e-9, 3.89765478921, 10988.808157535],
      [3.08e-9, 0.05451027736, 4701.1165017084],
      [3.19e-9, 2.95712862064, 163096.18036118348],
      [2.72e-9, 2.07967681309, 4804.209275927],
      [2.09e-9, 4.43768461442, 6546.1597733642],
      [2.17e-9, 0.73691592312, 6303.8512454838],
      [2.03e-9, 0.32033085531, 25934.1243310894],
      [2.05e-9, 5.22936478995, 20995.3929664494],
      [2.13e-9, 0.20671418919, 28286.9904848612],
      [1.97e-9, 0.4828613129, 16737.5772365966],
      [2.3e-9, 6.06567392849, 6287.0080032545],
      [2.19e-9, 1.291942163, 5326.7866940208],
      [2.01e-9, 1.74700937253, 22743.4093795164],
      [2.07e-9, 4.45440927276, 6279.4854213396],
      [2.69e-9, 6.0564044503, 64471.99124174489],
      [1.9e-9, 0.99261116842, 29296.6153895786],
      [1.94e-9, 3.82656562755, 419.4846438752],
      [2.62e-9, 5.26961924126, 522.5774180938],
      [2.1e-9, 4.68618183158, 6254.6266625236],
      [1.97e-9, 2.80624554186, 4933.2084403326],
      [2.52e-9, 4.3622015462, 40879.4405046438],
      [2.61e-9, 1.07241516738, 55022.9357470744],
      [2.33e-9, 5.41751014958, 39609.6545831656],
      [1.85e-9, 4.14324541379, 5642.1982426092],
      [2.47e-9, 3.44855612987, 6702.5604938666],
      [2.05e-9, 4.04424043226, 536.8045120954],
      [1.91e-9, 3.15807087926, 16723.350142595],
      [2.22e-9, 5.16259496507, 23539.7073863328],
      [1.8e-9, 4.56214752149, 6489.2613984286],
      [2.27e-9, 0.60156339452, 5905.7022420756],
      [1.7e-9, 0.93185903228, 16062.1845261168],
      [1.59e-9, 0.92751013112, 23937.856389741],
      [1.57e-9, 4.69607868164, 6805.6532680852],
      [2.18e-9, 0.8553337343, 16627.3709153772],
      [1.69e-9, 0.94641052064, 3097.88382272579],
      [2.07e-9, 4.88410451334, 6286.6662786432],
      [1.6e-9, 4.95943826819, 10021.8372800994],
      [1.75e-9, 6.12762824563, 239424.39025435288],
      [1.73e-9, 3.13887234973, 6179.9830757728],
      [1.57e-9, 3.62822057807, 18451.07854656599],
      [2.06e-9, 5.74617821138, 3646.3503773544],
      [1.57e-9, 4.67695912207, 6709.6740408674],
      [1.46e-9, 3.09506069745, 4907.3020501456],
      [1.65e-9, 2.2713912876, 10660.6869350424],
      [1.44e-9, 3.96947747592, 6019.9919266186],
      [1.71e-9, 5.91302216729, 6058.7310542895],
      [1.44e-9, 2.1315565512, 26084.0218062162],
      [1.51e-9, 0.67417383565, 2388.8940204492],
      [1.96e-9, 1.67718461229, 2107.0345075424],
      [1.46e-9, 5.10373877968, 10770.8932562618],
      [1.87e-9, 1.23915444627, 19402.7969528166],
      [1.37e-9, 1.26247412216, 12566.2190102856],
      [1.91e-9, 5.03547476279, 263.0839233728],
      [1.37e-9, 3.52825454595, 639.897286314],
      [1.35e-9, 0.73840670927, 5017.508371365],
      [1.64e-9, 2.39195095081, 6357.8574485587],
      [1.68e-9, 0.05515907462, 9380.9596727172],
      [1.61e-9, 1.15721259392, 26735.9452622132],
      [1.44e-9, 1.76097645199, 5888.4499649322],
      [1.31e-9, 2.51859277344, 6599.467719648],
      [1.42e-9, 2.43802911123, 5881.4037282342],
      [1.59e-9, 5.90325893762, 6281.5913772831],
      [1.51e-9, 3.72338532519, 12669.2444742014],
      [1.32e-9, 2.38417741883, 6525.8044539654],
      [1.27e-9, 0.00254936441, 10027.9031957292],
      [1.48e-9, 2.85102145528, 6418.1409300268],
      [1.43e-9, 5.7446027956, 26087.9031415742],
      [1.72e-9, 0.4128996224, 174242.4659640497],
      [1.36e-9, 4.15497742275, 6311.5250374592],
      [1.7e-9, 5.98194913129, 327574.51427678124],
      [1.36e-9, 2.48430537541, 13341.6743113068],
      [1.49e-9, 0.33002271275, 245.8316462294],
      [1.65e-9, 2.496679246, 58953.145443294],
      [1.23e-9, 1.67328384813, 32217.2001810808],
      [1.23e-9, 3.45660563754, 6277.552925684],
      [1.17e-9, 0.86065134175, 6245.0481773556],
      [1.49e-9, 5.61358281003, 5729.506447149],
      [1.28e-9, 0.71204006448, 103.0927742186],
      [1.59e-9, 2.43166592149, 221995.02880149524],
      [1.37e-9, 1.706577092, 12566.08438968],
      [1.29e-9, 2.80667872683, 6016.4688082696],
      [1.13e-9, 3.58302904101, 25685.872802808],
      [1.09e-9, 3.26403795962, 6819.8803620868],
      [1.22e-9, 0.34120688202, 1162.4747044078],
      [1.06e-9, 1.59721172719, 17782.7320727842],
      [1.44e-9, 2.28891651774, 12489.8856287072],
      [1.37e-9, 5.82029768354, 44809.6502008634],
      [1.34e-9, 1.26539983018, 5331.3574437408],
      [1.03e-9, 5.96518130595, 6321.1035226272],
      [1.09e-9, 0.33808549034, 11300.5842213564],
      [1.29e-9, 5.8918727719, 12029.3471878874],
      [1.22e-9, 5.77325634636, 11919.140866668],
      [1.07e-9, 6.2499898935, 77690.75950573849],
      [1.07e-9, 1.00535580713, 77736.78343050249],
      [1.15e-9, 5.86963518266, 12721.572099417],
      [1.02e-9, 5.66283467269, 5540.0857894588],
      [1.43e-9, 0.24122178432, 4214.0690150848],
      [1.43e-9, 0.88529649733, 7576.560073574],
      [1.07e-9, 2.92124030351, 31415.379249957],
      [1e-9, 5.99485644501, 4061.2192153944],
      [1.03e-9, 2.41941934525, 5547.1993364596],
      [1.04e-9, 4.44106051277, 2118.7638603784],
      [1.1e-9, 0.37559635174, 5863.5912061162],
      [1.24e-9, 2.55619029611, 12539.853380183],
      [1.1e-9, 3.66952094465, 238004.5241572363],
      [1.12e-9, 4.32512422724, 97238.62754448749],
      [1.2e-9, 1.26895630075, 12043.574281889],
      [9.7e-10, 5.42612959752, 7834.1210726394],
      [9.4e-10, 2.56461130309, 19004.6479494084],
      [1.05e-9, 5.68272475301, 16522.6597160022],
      [1.17e-9, 3.65425622684, 34520.3093093808],
      [1.08e-9, 1.24206843948, 84672.47584450469],
      [9.8e-10, 0.13589994287, 11080.1715789176],
      [9.7e-10, 2.46722096722, 71980.63357473118],
      [9.5e-10, 5.36958330451, 6288.5987742988],
      [9.6e-10, 0.20796618776, 18139.2945014159],
      [1.11e-9, 5.01961920313, 11823.1616394502],
      [9e-10, 2.72355843779, 26880.3198130326],
      [9.9e-10, 0.90164266199, 18635.9284545362],
      [1.26e-9, 4.78722177847, 305281.9430710488],
      [1.24e-9, 5.00979495566, 172146.9713405403],
      [9e-10, 4.50544881196, 40077.61957352],
      [1.04e-9, 5.6367968071, 2787.0430238574],
      [9.1e-10, 5.43564326147, 6272.0301497275],
      [1e-9, 2.00639461597, 12323.4230960088],
      [1.17e-9, 2.35555589778, 83286.91426955358],
      [1.05e-9, 2.59824000109, 30666.1549584328],
      [9e-10, 2.35779490026, 12491.3701014155],
      [8.9e-10, 3.57152453732, 11720.0688652316],
      [9.5e-10, 5.67015349858, 14919.0178537546],
      [8.7e-10, 1.86043406047, 27707.5424942948],
      [1.06e-9, 3.04150600352, 22345.2603761082],
      [8.2e-10, 5.58298993353, 10241.2022911672],
      [8.3e-10, 3.10607039533, 36147.4098773004],
      [9.4e-10, 5.47749711149, 9924.8104215106],
      [8.2e-10, 4.71988314145, 15141.390794312],
      [9.6e-10, 3.89073946348, 6379.0550772092],
      [1.1e-9, 4.92131611151, 5621.8429232104],
      [1.1e-9, 4.89978492291, 72140.6286666874],
      [9.7e-10, 5.20764563059, 6303.4311693902],
      [8.5e-10, 1.61269222311, 33326.5787331742],
      [9.3e-10, 1.32651591333, 23020.65308658799],
      [9e-10, 0.5773301638, 26482.1708096244],
      [7.8e-10, 3.99588630754, 11293.4706743556],
      [1.06e-9, 3.92012705073, 62883.3551395136],
      [9.8e-10, 2.94397773524, 316.3918696566],
      [7.6e-10, 3.96310417608, 29026.48522950779],
      [9.8e-10, 0.95914722366, 48739.859897083],
      [7.8e-10, 1.97068528043, 90279.92316810328],
      [7.6e-10, 0.23027966596, 21424.4666443034],
      [7.9e-10, 1.46227790922, 8982.810669309],
      [7.8e-10, 2.28840998832, 266.6070417218],
      [7.1e-10, 1.5194076559, 33794.5437235286],
      [7.6e-10, 0.22880641443, 57375.8019008462],
      [9.7e-10, 0.39449562097, 24279.10701821359],
      [7.5e-10, 2.77638584795, 12964.300703391],
      [7.7e-10, 5.18846946344, 11520.9968637952],
      [6.8e-10, 0.50006599129, 4274.5183108324],
      [7.5e-10, 2.07323762803, 15664.03552270859],
      [7.7e-10, 0.4666517878, 16207.886271502],
      [8.1e-10, 4.10452219483, 161710.6187862324],
      [7.1e-10, 3.91415328513, 7875.6718636242],
      [8.1e-10, 0.91938383406, 74.7815985673],
      [8.3e-10, 4.69916218791, 23006.42599258639],
      [6.9e-10, 0.98999300277, 6393.2821712108],
      [6.5e-10, 5.41938745446, 28628.3362260996],
      [7.3e-10, 2.45564765251, 15508.6151232744],
      [6.5e-10, 3.02336771694, 5959.570433334],
      [6.4e-10, 0.18375587635, 1066.49547719],
      [8e-10, 5.81239171612, 12341.8069042809],
      [6.6e-10, 2.15105504851, 38.0276726358],
      [6.7e-10, 5.14047250153, 9814.6041002912],
      [6.2e-10, 2.43313614978, 10138.1095169486],
      [6.8e-10, 2.24442548639, 24383.0791084414],
      [7.8e-10, 1.39649333997, 9411.4646150872],
      [5.9e-10, 4.95362151577, 35707.7100829074],
      [7.3e-10, 1.35229143121, 5327.4761083828],
      [5.7e-10, 3.16018882154, 5490.300961524],
      [7.2e-10, 5.91833527334, 10881.0995774812],
      [6.7e-10, 0.66414713064, 29864.334027309],
      [6.5e-10, 0.30352816135, 7018.9523635232],
      [5.9e-10, 5.36231868425, 10239.5838660108],
      [5.6e-10, 3.22196331515, 2636.725472637],
      [6.8e-10, 5.32086226658, 3116.6594122598],
      [5.9e-10, 1.63156134967, 61306.0115970658],
      [5.4e-10, 4.29491690425, 21947.1113727],
      [7e-10, 0.29271565928, 6528.9074962208],
      [5.7e-10, 5.89190132575, 34513.2630726828],
      [5.4e-10, 2.51856815404, 6279.1945146334],
      [7.4e-10, 1.38235845304, 9967.4538999816],
      [5.4e-10, 0.92276712152, 6286.9571853494],
      [7e-10, 5.00933012248, 6453.7487206106],
      [5.3e-10, 3.86543309344, 32370.9789915656],
      [5.5e-10, 4.51794544854, 34911.412076091],
      [6.3e-10, 5.41479412056, 11502.8376165305],
      [6.3e-10, 2.34416220742, 11510.7019230567],
      [5.6e-10, 0.91310629913, 9910.583327509],
      [6.7e-10, 4.03308763854, 34596.3646546524],
      [6e-10, 5.57024703495, 5756.9080032458],
      [7.2e-10, 2.80863088166, 10866.8724834796],
      [6.6e-10, 6.12047940728, 12074.488407524],
      [5.1e-10, 2.59519527563, 11396.5634485742],
      [6.2e-10, 5.14746754396, 25287.7237993998],
      [5.4e-10, 2.50994032776, 5999.2165311262],
      [5.1e-10, 4.51195861837, 29822.7832363242],
      [5.9e-10, 0.44167237876, 250570.6758572191],
      [5.1e-10, 3.6884906676, 6262.7205305926],
      [4.9e-10, 0.54704693048, 22594.05489571199],
      [6.5e-10, 2.38423614501, 52670.0695933026],
      [6.9e-10, 5.34363738671, 66813.5648357332],
      [5.6e-10, 2.67216180349, 17892.93839400359],
      [4.9e-10, 4.18361320516, 18606.4989460002],
      [5.5e-10, 0.83886167974, 20452.8694122218],
      [5e-10, 1.46327331958, 37455.7264959744],
      [5.8e-10, 3.34847975377, 33990.6183442862],
      [6.5e-10, 1.45522693982, 76251.32777062019],
      [5.6e-10, 2.356506642, 37724.7534197482],
      [4.8e-10, 1.80689447612, 206.1855484372],
      [5.6e-10, 3.84224878744, 5483.254724826],
      [5.3e-10, 0.17334326094, 77717.29458646949],
      [5.3e-10, 0.79879700631, 77710.24834977149],
      [4.7e-10, 0.43240779709, 735.8765135318],
      [5.3e-10, 4.58786566028, 11616.976091013],
      [4.8e-10, 6.20230111054, 4171.4255366138],
      [5.2e-10, 2.9171905303, 6993.0088985497],
      [5.7e-10, 3.42008310383, 50317.2034395308],
      [4.8e-10, 0.12356889012, 13362.4497067992],
      [6e-10, 5.52056066934, 949.1756089698],
      [4.5e-10, 3.37963782356, 10763.779709261],
      [4.7e-10, 5.50958184902, 12779.4507954208],
      [5.2e-10, 5.42770349015, 310145.1528239236],
      [6.1e-10, 2.93237974631, 5791.4125575326],
      [4.4e-10, 2.87440620802, 8584.6616659008],
      [4.6e-10, 4.0314179656, 10667.8004820432],
      [4.4e-10, 1.21579107625, 6272.4391846416],
      [4.7e-10, 2.57670800912, 11492.542675792],
      [4.4e-10, 3.62570223167, 63658.8777508376],
      [5.1e-10, 0.84531181151, 12345.739057544],
      [4.6e-10, 1.17584556517, 149.5631971346],
      [4.3e-10, 0.01524970172, 37853.8754993826],
      [4.3e-10, 0.79038834934, 640.8776073822],
      [4.4e-10, 2.22554419931, 6293.7125153412],
      [4.9e-10, 1.01528394907, 149144.46708624958],
      [4.1e-10, 3.27146326065, 8858.3149443206],
      [4.5e-10, 3.03765521215, 65236.2212932854],
      [5.8e-10, 5.45843180927, 1975.492545856],
      [4.1e-10, 1.32190847146, 2547.8375382324],
      [4.7e-10, 3.67626039848, 28313.288804661],
      [4.7e-10, 6.21438985953, 10991.3058987006],
      [4e-10, 2.37237751212, 8273.8208670324],
      [5.6e-10, 1.09773690181, 77376.2010224076],
      [4e-10, 2.35698541041, 2699.7348193176],
      [4.3e-10, 5.28030897946, 17796.9591667858],
      [5.4e-10, 2.59175932091, 22910.44676536859],
      [5.5e-10, 0.07988985505, 83467.15635301729],
      [4.1e-10, 4.47510694062, 5618.3198048614],
      [4e-10, 1.35670430524, 27177.8515292002],
      [4.1e-10, 2.48011323946, 6549.6828917132],
      [5e-10, 2.56387920528, 82576.9812209953],
      [4.2e-10, 4.78798367468, 7856.89627409019],
      [4.7e-10, 2.75482175292, 18202.21671665939],
      [3.9e-10, 1.97008298629, 24491.4257925834],
      [4.2e-10, 4.04346599946, 7863.9425107882],
      [3.9e-10, 3.0103393642, 853.196381752],
      [3.8e-10, 0.49178679251, 38650.173506199],
      [4.4e-10, 1.35931241699, 21393.5419698576],
      [3.6e-10, 4.86047906533, 4157.1984426122],
      [4.3e-10, 5.64354880978, 1062.9050485382],
      [3.9e-10, 3.92736779879, 3903.9113764198],
      [4e-10, 5.3969491832, 9498.2122306346],
      [4.3e-10, 2.40863861919, 29424.634232916],
      [4.6e-10, 2.08022244271, 12573.2652469836],
      [5e-10, 6.15760345261, 78051.34191383338]
    ],
    "1": [
      [0.00103018607, 1.10748968172, 6283.0758499914],
      [0.00001721238, 1.06442300386, 12566.1516999828],
      [0.00000702217, 3.14159265359, 0],
      [3.2345e-7, 1.02168583254, 18849.2275499742],
      [3.0801e-7, 2.84358443952, 5507.5532386674],
      [2.4978e-7, 1.31906570344, 5223.6939198022],
      [1.8487e-7, 1.42428709076, 1577.3435424478],
      [1.0077e-7, 5.91385248388, 10977.078804699],
      [8.635e-8, 0.27158192945, 5486.777843175],
      [8.654e-8, 1.42046854427, 6275.9623029906],
      [5.069e-8, 1.68613408916, 5088.6288397668],
      [4.985e-8, 6.01402338185, 6286.5989683404],
      [4.667e-8, 5.98749245692, 529.6909650946],
      [4.395e-8, 0.51800423445, 4694.0029547076],
      [3.87e-8, 4.74932206877, 2544.3144198834],
      [3.755e-8, 5.07053801166, 796.2980068164],
      [4.1e-8, 1.08424801084, 9437.762934887],
      [3.518e-8, 0.02290216978, 83996.84731811189],
      [3.436e-8, 0.94937503872, 71430.69561812909],
      [3.221e-8, 6.15628775321, 2146.1654164752],
      [3.418e-8, 5.4115158188, 775.522611324],
      [2.863e-8, 5.48433323746, 10447.3878396044],
      [2.525e-8, 0.24296913555, 398.1490034082],
      [2.205e-8, 4.94892172085, 6812.766815086],
      [2.186e-8, 0.41991932164, 8031.0922630584],
      [2.828e-8, 3.41986300734, 2352.8661537718],
      [2.554e-8, 6.13241770582, 6438.4962494256],
      [1.932e-8, 5.31374587091, 8429.2412664666],
      [2.427e-8, 3.09118902115, 4690.4798363586],
      [1.73e-8, 1.53685999718, 4705.7323075436],
      [2.25e-8, 3.6883639562, 7084.8967811152],
      [2.094e-8, 1.281690604, 1748.016413067],
      [1.483e-8, 3.22226346483, 7234.794256242],
      [1.434e-8, 0.81293662216, 14143.4952424306],
      [1.754e-8, 3.22883705112, 6279.5527316424],
      [1.583e-8, 4.09815978783, 11499.6562227928],
      [1.575e-8, 5.53890314149, 3154.6870848956],
      [1.847e-8, 1.82041234937, 7632.9432596502],
      [1.499e-8, 3.63177937611, 11513.8833167944],
      [1.337e-8, 4.64442556061, 6836.6452528338],
      [1.275e-8, 2.69329661394, 1349.8674096588],
      [1.348e-8, 6.15284035323, 5746.271337896],
      [1.126e-8, 3.35676107739, 17789.845619785],
      [1.47e-8, 3.65282991735, 1194.4470102246],
      [1.101e-8, 4.4974742767, 4292.3308329504],
      [1.168e-8, 2.58033028504, 13367.9726311066],
      [1.236e-8, 5.64980098028, 5760.4984318976],
      [9.85e-9, 0.65326301914, 5856.4776591154],
      [9.28e-9, 2.3255501829, 10213.285546211],
      [1.073e-8, 5.82672338169, 12036.4607348882],
      [9.18e-9, 0.76907130762, 16730.4636895958],
      [8.76e-9, 1.50335727807, 11926.2544136688],
      [1.023e-8, 5.62071200879, 6256.7775301916],
      [8.53e-9, 0.6567813463, 155.4203994342],
      [8.02e-9, 4.10519132094, 951.7184062506],
      [8.59e-9, 1.42880883564, 5753.3848848968],
      [9.92e-9, 1.1423800161, 1059.3819301892],
      [8.14e-9, 1.63584008733, 6681.2248533996],
      [6.64e-9, 4.55039663226, 5216.5803728014],
      [6.27e-9, 1.50782904323, 5643.1785636774],
      [6.44e-9, 4.19480024859, 6040.3472460174],
      [5.9e-9, 6.18371704849, 4164.311989613],
      [6.35e-9, 0.5242358477, 6290.1893969922],
      [6.5e-9, 0.97935492869, 25132.3033999656],
      [5.68e-9, 2.30121525349, 10973.55568635],
      [5.49e-9, 5.26737827342, 3340.6124266998],
      [5.47e-9, 2.20143332641, 1592.5960136328],
      [5.26e-9, 0.92464258271, 11371.7046897582],
      [4.93e-9, 5.91036281399, 3894.1818295422],
      [4.83e-9, 1.6600571154, 12168.0026965746],
      [5.14e-9, 3.59683072524, 10969.9652576982],
      [5.16e-9, 3.97164781773, 17298.1823273262],
      [5.29e-9, 5.0353867768, 9917.6968745098],
      [4.87e-9, 2.50544745305, 6127.6554505572],
      [4.19e-9, 4.05235655996, 10984.1923516998],
      [5.38e-9, 5.54081539813, 553.5694028424],
      [4.02e-9, 2.16859478359, 7860.4193924392],
      [5.52e-9, 2.32219865498, 11506.7697697936],
      [3.67e-9, 3.39145698451, 6496.3749454294],
      [3.6e-9, 5.34467204596, 7079.3738568078],
      [3.34e-9, 3.61346365667, 11790.6290886588],
      [4.54e-9, 0.28755421898, 801.8209311238],
      [4.19e-9, 3.69613970002, 10575.4066829418],
      [3.19e-9, 0.30793759304, 16200.7727245012],
      [3.76e-9, 5.81560210508, 7058.5984613154],
      [3.64e-9, 1.08425056923, 6309.3741697912],
      [2.94e-9, 4.54798604178, 11856.2186514245],
      [2.9e-9, 1.26451946335, 8635.9420037632],
      [3.94e-9, 4.15683669084, 26.2983197998],
      [2.6e-9, 5.09424572996, 10177.2576795336],
      [2.41e-9, 2.25766000302, 11712.9553182308],
      [2.39e-9, 1.06936978753, 242.728603974],
      [2.76e-9, 3.44260568764, 5884.9268465832],
      [2.55e-9, 5.38496803122, 21228.3920235458],
      [3.07e-9, 4.24313885601, 3738.761430108],
      [2.13e-9, 3.44661200485, 213.299095438],
      [1.98e-9, 0.69427265195, 1990.745017041],
      [1.95e-9, 5.16563409007, 12352.8526045448],
      [2.13e-9, 3.89937836808, 13916.0191096416],
      [2.14e-9, 4.00445200772, 5230.807466803],
      [1.84e-9, 5.59805976614, 6283.14316029419],
      [1.84e-9, 2.85275392124, 7238.6755916],
      [1.79e-9, 2.54259058252, 14314.1681130498],
      [2.36e-9, 5.58826125715, 6069.7767545534],
      [1.89e-9, 2.72689937708, 6062.6632075526],
      [1.84e-9, 6.04216273598, 6283.0085396886],
      [2.25e-9, 1.66128561344, 4732.0306273434],
      [2.3e-9, 3.62591335086, 6284.0561710596],
      [1.72e-9, 0.97566476085, 3930.2096962196],
      [1.62e-9, 2.19467339429, 18073.7049386502],
      [2.15e-9, 1.04672844028, 3496.032826134],
      [1.82e-9, 5.17782354566, 17253.04110768959],
      [1.67e-9, 2.17754938066, 6076.8903015542],
      [1.67e-9, 4.75672473773, 17267.26820169119],
      [1.49e-9, 0.80944185798, 709.9330485583],
      [1.49e-9, 0.17584214812, 9779.1086761254],
      [1.92e-9, 5.00680790235, 11015.1064773348],
      [1.41e-9, 4.38420380014, 4136.9104335162],
      [1.58e-9, 4.60969054283, 9623.6882766912],
      [1.33e-9, 3.30507062245, 154717.6098876827],
      [1.66e-9, 6.13191098325, 3.523118349],
      [1.81e-9, 1.60715321141, 7.1135470008],
      [1.5e-9, 5.28136702046, 13517.8701062334],
      [1.42e-9, 0.49788089569, 25158.6017197654],
      [1.24e-9, 6.03440459813, 9225.539273283],
      [1.24e-9, 0.99251562639, 65147.6197681377],
      [1.28e-9, 1.92032744711, 22483.84857449259],
      [1.24e-9, 3.99739675184, 4686.8894077068],
      [1.21e-9, 2.37814805239, 167283.7615876655],
      [1.23e-9, 5.6231511294, 5642.1982426092],
      [1.17e-9, 5.81755956156, 12569.6748183318],
      [1.57e-9, 3.40236948518, 16496.3613962024],
      [1.3e-9, 2.10499918142, 1589.0728952838],
      [1.16e-9, 0.55839966736, 5849.3641121146],
      [1.23e-9, 5.81645568991, 6282.0955289232],
      [1.1e-9, 0.42176497674, 6172.869528772],
      [1.5e-9, 4.26279600865, 3128.3887650958],
      [1.06e-9, 2.27436561182, 5429.8794682394],
      [1.14e-9, 1.52894564202, 12559.038152982],
      [1.21e-9, 0.39459045915, 12132.439962106],
      [1.04e-9, 2.41845930933, 426.598190876],
      [1.09e-9, 5.82786999856, 16858.4825329332],
      [1.02e-9, 4.4662648491, 23543.23050468179],
      [1e-9, 2.93812275274, 4535.0594369244],
      [9.7e-10, 3.97935904984, 6133.5126528568],
      [9.8e-10, 0.87616810121, 6525.8044539654],
      [1.1e-9, 6.22339014386, 12146.6670561076],
      [9.8e-10, 3.17344332543, 10440.2742926036],
      [9.6e-10, 2.44128701699, 3097.88382272579],
      [9.9e-10, 5.75642493267, 7342.4577801806],
      [9e-10, 0.18984343165, 13119.72110282519],
      [9.9e-10, 5.58884724219, 2388.8940204492],
      [9.1e-10, 6.04278320182, 20426.571092422],
      [8e-10, 1.29028142103, 5650.2921106782],
      [8.6e-10, 3.94529200528, 10454.5013866052],
      [8.5e-10, 1.92836879835, 29088.811415985],
      [7.6e-10, 2.70726317966, 143571.32428481648],
      [9.1e-10, 5.63859073351, 8827.3902698748],
      [7.6e-10, 1.80783856698, 28286.9904848612],
      [7.5e-10, 3.40858032804, 5481.2549188676],
      [7e-10, 4.53719487231, 17256.6315363414],
      [8.9e-10, 1.10064490942, 11769.8536931664],
      [6.6e-10, 2.78384937771, 536.8045120954],
      [6.8e-10, 3.88199295043, 17260.1546546904],
      [8.8e-10, 3.88075269535, 7477.522860216],
      [6.1e-10, 6.17558202197, 11087.2851259184],
      [6e-10, 4.34824715818, 6206.8097787158],
      [8.2e-10, 4.59843208943, 9388.0059094152],
      [7.9e-10, 1.63139280394, 4933.2084403326],
      [8.1e-10, 1.55550779371, 9380.9596727172],
      [7.8e-10, 4.20905757519, 5729.506447149],
      [5.8e-10, 5.76889633224, 3634.6210245184],
      [6e-10, 0.93813100594, 12721.572099417],
      [7.1e-10, 6.11408885148, 8662.240323563],
      [5.7e-10, 5.48112524468, 18319.5365848796],
      [7e-10, 0.01749174864, 14945.3161735544],
      [7.4e-10, 1.0997604582, 16460.33352952499],
      [5.6e-10, 1.63036186739, 15720.8387848784],
      [5.5e-10, 4.86788348404, 13095.8426650774],
      [6e-10, 5.93729841267, 12539.853380183],
      [5.4e-10, 0.22608242982, 15110.4661198662],
      [5.4e-10, 2.30250047594, 16062.1845261168],
      [6.4e-10, 2.13513754101, 7875.6718636242],
      [5.9e-10, 5.87963500139, 5331.3574437408],
      [5.8e-10, 2.30546168615, 955.5997416086],
      [4.9e-10, 1.93839278478, 5333.9002410216],
      [5.4e-10, 5.80331607119, 12043.574281889],
      [5.4e-10, 4.44671053809, 4701.1165017084],
      [4.9e-10, 0.30241161485, 6805.6532680852],
      [4.6e-10, 2.76898193028, 6709.6740408674],
      [4.6e-10, 3.98449608961, 98068.53671630539],
      [4.9e-10, 3.72022009896, 12323.4230960088],
      [4.5e-10, 3.30065998328, 22003.9146348698],
      [4.8e-10, 0.71071357303, 6303.4311693902],
      [6.1e-10, 1.66030429494, 6262.300454499],
      [4.7e-10, 1.26317154881, 11919.140866668],
      [5.1e-10, 1.08020906825, 10988.808157535],
      [4.5e-10, 0.89150445122, 51868.2486621788],
      [4.3e-10, 0.57756724285, 24356.7807886416],
      [4.3e-10, 1.61526242998, 6277.552925684],
      [4.5e-10, 2.96132920534, 8982.810669309],
      [4.3e-10, 5.74295325645, 11403.676995575],
      [5.5e-10, 3.14274403422, 33019.0211122046],
      [5.7e-10, 0.06379726305, 15671.0817594066],
      [4.1e-10, 2.53761820726, 6262.7205305926],
      [4e-10, 1.53130436944, 18451.07854656599],
      [5.2e-10, 1.71451922581, 1551.045222648],
      [5.5e-10, 0.89439119424, 11933.3679606696],
      [4.5e-10, 3.88495384656, 60530.4889857418],
      [4e-10, 4.75740908001, 38526.574350872],
      [4e-10, 3.77498297348, 26087.9031415742],
      [3.9e-10, 2.97113832621, 2118.7638603784],
      [4e-10, 3.36050962605, 10021.8372800994],
      [4.7e-10, 1.67051113434, 6303.8512454838],
      [5.2e-10, 5.21827368711, 77713.7714681205],
      [4.7e-10, 4.26356628717, 21424.4666443034],
      [3.7e-10, 1.66712389942, 6819.8803620868],
      [3.7e-10, 0.65746800933, 12029.3471878874],
      [3.5e-10, 3.36255650927, 24072.9214697764],
      [3.6e-10, 0.11087914947, 10344.2950653858],
      [4e-10, 4.14725582115, 2787.0430238574],
      [3.5e-10, 5.93650887012, 31570.7996493912],
      [3.6e-10, 2.15108874765, 30774.5016425748],
      [3.6e-10, 1.75078825382, 16207.886271502],
      [3.4e-10, 2.75708224536, 12139.5535091068],
      [3.4e-10, 6.168913788, 24491.4257925834],
      [3.4e-10, 2.31528650443, 55798.4583583984],
      [3.2e-10, 4.21446357042, 15664.03552270859],
      [3.4e-10, 3.19783054699, 32217.2001810808],
      [3.9e-10, 1.24979117796, 6418.1409300268],
      [3.8e-10, 5.89832942685, 640.8776073822],
      [3.3e-10, 4.80200120107, 16723.350142595],
      [3.2e-10, 1.72442327688, 27433.88921587499],
      [3.5e-10, 4.44608896525, 18202.21671665939],
      [3.1e-10, 4.5279073128, 6702.5604938666],
      [3.4e-10, 3.96287980676, 18216.443810661],
      [3e-10, 5.06259854444, 226858.23855437007],
      [3.4e-10, 1.43910280005, 49515.382508407],
      [3e-10, 0.29303163371, 13521.7514415914],
      [2.9e-10, 2.0263384022, 11609.8625440122],
      [3e-10, 2.5492323024, 9924.8104215106],
      [3.2e-10, 4.91793198558, 11300.5842213564],
      [3e-10, 0.23284423547, 23581.2581773176],
      [2.9e-10, 1.62807736495, 639.897286314],
      [2.8e-10, 3.84568936822, 2699.7348193176],
      [2.9e-10, 1.83149729794, 29822.7832363242],
      [3.3e-10, 4.60320094415, 19004.6479494084],
      [2.7e-10, 1.86151121799, 6288.5987742988],
      [3e-10, 4.4649407224, 36147.4098773004],
      [2.8e-10, 5.19684492912, 5863.5912061162],
      [3.5e-10, 4.52695674113, 36949.2308084242],
      [2.7e-10, 3.52528177609, 10770.8932562618],
      [2.6e-10, 1.48499438453, 11080.1715789176],
      [3.5e-10, 2.82154380962, 19402.7969528166],
      [2.5e-10, 2.46339998836, 6279.4854213396],
      [2.6e-10, 4.97688894643, 16737.5772365966],
      [2.7e-10, 0.408271125, 12964.300703391],
      [2.9e-10, 4.15148654061, 45892.73043315699],
      [2.6e-10, 4.56404104286, 17796.9591667858],
      [2.5e-10, 2.89309528854, 6286.6662786432],
      [2.6e-10, 4.82914580957, 1066.49547719],
      [3.1e-10, 3.93096113738, 29864.334027309],
      [2.4e-10, 6.14987193584, 18606.4989460002],
      [2.4e-10, 3.74225964547, 29026.48522950779],
      [2.5e-10, 5.70460621565, 27707.5424942948],
      [2.5e-10, 5.33928840652, 15141.390794312],
      [2.3e-10, 2.37624087345, 17996.0311682222],
      [2.6e-10, 1.34231351782, 18875.525869774],
      [2.2e-10, 5.5079162612, 6245.0481773556],
      [2.4e-10, 1.33998410121, 19800.9459562248],
      [2.3e-10, 0.2251228089, 6279.7894925736],
      [2.2e-10, 1.17576471775, 11925.2740926006],
      [2.2e-10, 3.5860360664, 6915.8595893046],
      [2.3e-10, 3.21621246666, 6286.3622074092],
      [2.9e-10, 2.09564449439, 15265.8865193004],
      [2.2e-10, 4.74660932338, 28230.18722269139],
      [2.1e-10, 2.30688751432, 5999.2165311262],
      [2.8e-10, 3.92087592807, 18208.349942592],
      [2.1e-10, 3.22643339385, 25934.1243310894],
      [2.1e-10, 3.04956726238, 6566.9351688566],
      [2.7e-10, 5.35645770522, 33794.5437235286],
      [2.5e-10, 5.91542362188, 6489.2613984286],
      [2e-10, 1.52296293311, 135.0650800354],
      [1.9e-10, 1.78134428631, 156137.47598479927],
      [1.9e-10, 0.34388684087, 5327.4761083828],
      [2.6e-10, 3.41701003233, 25287.7237993998],
      [1.9e-10, 2.86664271911, 18422.62935909819],
      [1.9e-10, 4.71432851499, 77690.75950573849],
      [1.9e-10, 2.54227398241, 77736.78343050249],
      [2e-10, 5.91915117116, 48739.859897083]
    ],
    "2": [
      [0.00004359385, 5.78455133808, 6283.0758499914],
      [0.00000123633, 5.57935427994, 12566.1516999828],
      [1.2342e-7, 3.14159265359, 0],
      [8.792e-8, 3.62777893099, 77713.7714681205],
      [5.689e-8, 1.86958905084, 5573.1428014331],
      [3.302e-8, 5.47034879713, 18849.2275499742],
      [1.471e-8, 4.47964125007, 5507.5532386674],
      [1.013e-8, 2.81323115556, 5223.6939198022],
      [8.54e-9, 3.107765669, 1577.3435424478],
      [1.102e-8, 2.84173992403, 161000.6857376741],
      [6.48e-9, 5.47348203398, 775.522611324],
      [6.08e-9, 1.37894173533, 6438.4962494256],
      [4.99e-9, 4.4164924225, 6286.5989683404],
      [4.16e-9, 0.90332697974, 10977.078804699],
      [4.04e-9, 3.2056726953, 5088.6288397668],
      [3.51e-9, 1.81081728907, 5486.777843175],
      [4.66e-9, 3.65086758149, 7084.8967811152],
      [4.58e-9, 5.38585314743, 149854.4001348079],
      [3.04e-9, 3.51015066341, 796.2980068164],
      [2.66e-9, 6.17413982699, 6836.6452528338],
      [2.81e-9, 1.8387467254, 4694.0029547076],
      [2.62e-9, 1.41420110644, 2146.1654164752],
      [2.64e-9, 3.14103683911, 71430.69561812909],
      [3.19e-9, 5.35037932146, 3154.6870848956],
      [2.38e-9, 2.17695432424, 155.4203994342],
      [2.29e-9, 4.7596958807, 7234.794256242],
      [2.91e-9, 4.61776401638, 4690.4798363586],
      [2.11e-9, 0.21864885298, 4705.7323075436],
      [2.04e-9, 4.22895113488, 1349.8674096588],
      [1.95e-9, 4.58550676556, 529.6909650946],
      [2.55e-9, 2.81442711144, 1748.016413067],
      [1.82e-9, 5.70454011389, 6040.3472460174],
      [1.8e-9, 6.02147727878, 4292.3308329504],
      [1.86e-9, 1.58690991244, 6309.3741697912],
      [1.67e-9, 2.88802733052, 9437.762934887],
      [1.66e-9, 1.99990574734, 8031.0922630584],
      [1.6e-9, 0.04412738495, 2544.3144198834],
      [1.97e-9, 2.01089431842, 1194.4470102246],
      [1.65e-9, 5.78372596774, 83996.84731811189],
      [2.14e-9, 3.38300910371, 7632.9432596502],
      [1.4e-9, 0.36669664351, 10447.3878396044],
      [1.51e-9, 0.95519595275, 6127.6554505572],
      [1.36e-9, 1.48417295645, 2352.8661537718],
      [1.28e-9, 5.48057748834, 951.7184062506],
      [1.26e-9, 5.26866506592, 6279.5527316424],
      [1.27e-9, 3.77552907014, 6812.766815086],
      [1.03e-9, 4.95897533789, 398.1490034082],
      [1.04e-9, 0.70183576826, 1592.5960136328],
      [1.01e-9, 1.14481598642, 3894.1818295422],
      [1.31e-9, 0.76624310306, 553.5694028424],
      [1.09e-9, 5.41063597567, 6256.7775301916],
      [7.8e-10, 5.84775340741, 242.728603974],
      [9.7e-10, 1.94685257714, 11856.2186514245],
      [1e-9, 5.19725292131, 244287.60000722768],
      [7.6e-10, 0.70480774041, 8429.2412664666],
      [8e-10, 6.18430772683, 1059.3819301892],
      [6.8e-10, 5.29561709093, 14143.4952424306],
      [8.5e-10, 5.39487308005, 25132.3033999656],
      [5.5e-10, 5.16874637579, 7058.5984613154],
      [6.3e-10, 0.48494730699, 801.8209311238],
      [5.8e-10, 4.07254840265, 13367.9726311066],
      [5.1e-10, 3.89696552232, 12036.4607348882],
      [5.1e-10, 5.56335232286, 1990.745017041],
      [6e-10, 2.2504659671, 8635.9420037632],
      [4.9e-10, 5.58163417371, 6290.1893969922],
      [5.1e-10, 3.87240194908, 26.2983197998],
      [5.1e-10, 4.19300909995, 7860.4193924392],
      [4.1e-10, 3.97169191582, 10973.55568635],
      [4.1e-10, 3.5708091923, 7079.3738568078],
      [5.6e-10, 2.76959005761, 90955.5516944961],
      [4.2e-10, 1.91461189163, 7477.522860216],
      [4.2e-10, 0.42775891995, 10213.285546211],
      [4.2e-10, 1.06925480488, 709.9330485583],
      [3.8e-10, 6.17935925345, 9917.6968745098],
      [5e-10, 0.81691517401, 11506.7697697936],
      [5.3e-10, 1.45828359397, 233141.3144043615],
      [3.8e-10, 3.32444534628, 5643.1785636774],
      [4.7e-10, 6.21543665927, 6681.2248533996],
      [3.7e-10, 0.3635930998, 10177.2576795336],
      [4.5e-10, 5.29587706357, 10575.4066829418],
      [3.4e-10, 5.63446915337, 6525.8044539654],
      [3.4e-10, 5.36385158519, 4933.2084403326],
      [3.5e-10, 5.36152295839, 25158.6017197654],
      [4.2e-10, 5.08837645072, 11015.1064773348],
      [4.2e-10, 4.22496037505, 88860.05707098669],
      [3.9e-10, 1.99171699618, 6284.0561710596],
      [2.9e-10, 3.1908862817, 11926.2544136688],
      [2.9e-10, 0.14996158324, 12168.0026965746],
      [3e-10, 1.58346276808, 9779.1086761254],
      [2.6e-10, 4.16210340581, 12569.6748183318],
      [3.6e-10, 2.74684637873, 3738.761430108],
      [2.6e-10, 0.7282491532, 1589.0728952838],
      [3.1e-10, 5.34906371821, 143571.32428481648],
      [2.5e-10, 0.10240267494, 22483.84857449259],
      [3e-10, 3.47110495524, 14945.3161735544],
      [2.6e-10, 3.89359701125, 5753.3848848968],
      [2.4e-10, 1.18744224678, 4535.0594369244],
      [3.3e-10, 2.99317143244, 3930.2096962196],
      [2.4e-10, 1.57253767584, 6496.3749454294],
      [2.4e-10, 3.47434797542, 4136.9104335162],
      [2.2e-10, 3.91230073719, 6275.9623029906],
      [2.5e-10, 4.02978941287, 3128.3887650958],
      [2.3e-10, 1.07724492065, 12721.572099417],
      [2.1e-10, 1.89591807148, 16730.4636895958],
      [2.5e-10, 2.42198937013, 5729.506447149],
      [2e-10, 1.78163489101, 17789.845619785],
      [2.1e-10, 0.49258939822, 29088.811415985],
      [2.6e-10, 4.14947806747, 2388.8940204492],
      [2.7e-10, 2.54785812264, 3496.032826134],
      [2e-10, 4.29944129273, 16858.4825329332],
      [2.1e-10, 5.97796936723, 7.1135470008],
      [1.9e-10, 0.80292033311, 16062.1845261168],
      [2.4e-10, 4.89894141052, 17260.1546546904],
      [2.5e-10, 1.37003752175, 6282.0955289232],
      [2.2e-10, 4.92663152168, 18875.525869774],
      [2.3e-10, 5.68902059771, 16460.33352952499],
      [2.3e-10, 3.03021283729, 66567.48586525429],
      [1.6e-10, 3.89713736666, 5331.3574437408],
      [1.6e-10, 5.68562539832, 12559.038152982],
      [1.6e-10, 3.95085099736, 3097.88382272579],
      [1.6e-10, 3.99041783945, 6283.14316029419],
      [2e-10, 6.106439191, 167283.7615876655],
      [1.5e-10, 4.09775914607, 11712.9553182308],
      [1.6e-10, 5.717699407, 17298.1823273262],
      [1.6e-10, 3.28894009404, 5884.9268465832],
      [1.5e-10, 4.4256424368, 13517.8701062334],
      [1.6e-10, 4.4345208093, 6283.0085396886],
      [1.4e-10, 1.44384279999, 4164.311989613],
      [1.4e-10, 4.47380919159, 11790.6290886588],
      [1.4e-10, 4.77646531825, 7342.4577801806],
      [1.1e-10, 2.56768522896, 5481.2549188676],
      [1.1e-10, 1.514433322, 16200.7727245012],
      [1.1e-10, 0.88708889185, 21228.3920235458],
      [1.4e-10, 4.50116508534, 640.8776073822]
    ],
    "3": [
      [0.00000144595, 4.27319433901, 6283.0758499914],
      [6.729e-8, 3.91706261708, 12566.1516999828],
      [7.74e-9, 0, 0],
      [2.47e-9, 3.73021571217, 18849.2275499742],
      [3.6e-10, 2.8008140905, 6286.5989683404],
      [3.3e-10, 5.62990083112, 6127.6554505572],
      [1.8e-10, 3.72826142555, 6438.4962494256],
      [1.6e-10, 4.26011484232, 6525.8044539654],
      [1.4e-10, 3.47817116396, 6256.7775301916],
      [1.2e-10, 3.55747379482, 25132.3033999656],
      [1e-10, 4.43995693209, 4705.7323075436],
      [1e-10, 4.2804525547, 83996.84731811189],
      [9e-11, 5.36457057335, 6040.3472460174],
      [8e-11, 1.78458957263, 5507.5532386674],
      [9e-11, 0.4727519993, 6279.5527316424],
      [9e-11, 1.34741231639, 6309.3741697912],
      [9e-11, 0.77092900708, 5729.506447149],
      [7e-11, 3.50146897332, 7058.5984613154],
      [5e-11, 2.890710617, 775.522611324],
      [6e-11, 2.36514111314, 6836.6452528338]
    ],
    "4": [
      [3.858e-8, 2.56389016346, 6283.0758499914],
      [3.06e-9, 2.26911740541, 12566.1516999828],
      [5.3e-10, 3.44031471924, 5573.1428014331],
      [1.5e-10, 2.03136359366, 18849.2275499742],
      [1.3e-10, 2.05688873673, 77713.7714681205],
      [7e-11, 4.4121885448, 161000.6857376741],
      [4e-11, 5.33854414781, 6438.4962494256],
      [6e-11, 3.81514213664, 149854.4001348079],
      [4e-11, 4.26602478239, 6127.6554505572]
    ],
    "5": [
      [8.6e-10, 1.21805304895, 6283.0758499914],
      [1.2e-10, 0.65572878044, 12566.1516999828]
    ]
  },
  name: "earth",
  type: "B"
};
export default m;
