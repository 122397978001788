import React, { useState } from "react";
import {
  Add as AddIcon,
  AddBusiness as AddBusinessIcon,
  Edit as EditIcon,
  Group as GroupIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
} from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { DataGrid, PageTitle, useQueryPagination } from "../../components";
import { useResponsive } from "../../hooks";
import { checkIfIsBefore, filterNull, gqlQuery, QueryType } from "../../lib";
import { ClientContracts, Company, Employee } from "../../API";
import { columns, hiddenColumnsDesktop, hiddenColumnsMobile } from "./columns";
import { useEmployeeModal } from "./Modals";

export const Employees = () => {
  const [showActive, setShowActive] = useState(true);

  const { data: companies } = useQuery(
    gqlQuery({ req: "listCompaniesWithEmployees" })
  );
  const options = buildOptionsObj(
    (companies?.data?.listCompanies?.items ?? []) as Company[]
  );
  const { isMd } = useResponsive();
  const {
    showCreateModal,
    showUpdateModal,
    showDeactivateModal,
    showReactivateModal,
    showContractsModal,
  } = useEmployeeModal();
  const { data, isError, refetch, isPending, pagination } = useQueryPagination(
    ({ vars, key }: Omit<QueryType, "req">) => {
      const { queryFn, queryKey } = gqlQuery({
        req: "listEmployeesWithCompanies",
        vars: { ...vars },
        key,
      });
      return {
        queryKey,
        queryFn: async () => {
          const { data, errors } = await queryFn();
          const nextToken = data?.listEmployees?.nextToken;
          const items = (data?.listEmployees?.items ?? []).map((e) => {
            // warning that employee is inactive since at least from today
            const warning =
              e?.inactiveAt && new Date(e.inactiveAt) <= new Date();
            // an employee has been inactive longer than a year
            const isYearAgo =
              e?.inactiveAt &&
              checkIfIsBefore(new Date(e?.inactiveAt), 1, "years");
            // employee has active clients
            const hasClients = !!e?.clients?.items.length;
            // danger if inactive client has clients or is over 1 year inactive
            const danger = warning && (hasClients || isYearAgo);
            return {
              ...e,
              warning,
              danger,
            };
          });
          return {
            items,
            errors,
            nextToken,
          };
        },
      };
    }
  );

  const hideInactiveEmployees = {
    label: !showActive ? "Show Active" : "Show Inactive",
    icon: <CheckCircleOutlineIcon />,
    onClick: () => setShowActive(!showActive),
  };

  return (
    <>
      <PageTitle
        action={{
          label: "Add Employee",
          icon: <AddIcon />,
          onClick: showCreateModal,
        }}
        altAction={hideInactiveEmployees}
        icon={<GroupIcon />}
        title="Employees"
        testID="add-employee"
      />

      <DataGrid
        columns={columns(({ row }) => showUpdateModal({ row }))}
        data={(data?.items ?? []).filter(
          ({ inactiveAt }) =>
            showActive != (!!inactiveAt && new Date(inactiveAt) < new Date())
        )}
        emptyState={{
          title: "No Employees Found",
          description: "Get started by adding a employees",
          testID: "no-employees",
        }}
        errorState={{
          isError,
          onClick: refetch,
        }}
        loading={isPending}
        pagination={pagination}
        rowActions={[
          {
            label: "Clients",
            disabled: !showActive,
            onClick: ({ row }: { row: Employee }) =>
              filterNull<ClientContracts>(row.clients?.items).then(
                (contracts) => {
                  showContractsModal({
                    id: row.id,
                    contracts,
                    selected: getClientIds(row),
                    options,
                  });
                }
              ),
            icon: <AddBusinessIcon />,
          },
          {
            label: "Edit",
            onClick: ({ row }) => showUpdateModal({ row }),
            icon: <EditIcon />,
          },
          showActive
            ? {
                label: "Deactivate",
                onClick: ({ row }) => showDeactivateModal(row),
                icon: <ArchiveIcon />,
              }
            : {
                label: "Reactivate",
                onClick: ({ row }) => showReactivateModal(row),
                icon: <UnarchiveIcon />,
              },
        ]}
        columnVisibility={isMd ? hiddenColumnsMobile : hiddenColumnsDesktop}
        testID="employees"
      />
    </>
  );
};

const getClientIds = (row: Employee) =>
  (row.clients?.items ?? []).map((i) => i?.clientID ?? "");

const buildOptionsObj = (companies: (Company | null)[]) =>
  companies
    .sort((a, b) =>
      (a?.name ?? "").toLowerCase().localeCompare((b?.name ?? "").toLowerCase())
    )
    .flatMap((i) => {
      const clients =
        i?.clients?.items
          .filter(
            (client) => !client?.inactiveAt || !!client?.employees?.items.length
          )
          .map((client) => ({
            label: `${client?.firstName} ${client?.lastName}`,
            value: client?.id ?? "",
            header: false,
          })) ?? [];

      const comps = [
        {
          label: clients.length
            ? i?.name ?? ""
            : `${i?.name ?? ""} (no active managers)`,
          value: i?.id ?? "",
          header: true,
        },
      ];

      return comps.concat(clients);
    });
