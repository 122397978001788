import React, { ReactElement, useState } from "react";
import { Box, InputAdornment } from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { endOfWeek, nextFriday, previousFriday, startOfWeek } from "date-fns";
import {
  CustomButton,
  CustomTextField,
  renderPickerDays,
} from "./CustomPicker";

type Props = Omit<MuiDatePickerProps<Interval, Interval>, "renderInput"> & {
  InputIcon?: ReactElement;
};

export const WeekPicker = ({
  label = "Select Week",
  value,
  onChange,
  InputIcon,
}: Props) => {
  const [open, setOpen] = useState(false);
  const selectedDate = nextFriday(new Date(value?.start ?? ""));
  const selectedWeek = {
    start: startOfWeek(selectedDate),
    end: endOfWeek(selectedDate),
  };

  const handleOnChange = (val: Date | null) => {
    onChange(val ? { start: startOfWeek(val), end: endOfWeek(val) } : val);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <CustomButton
          aria-label="Previous week"
          variant="outlined"
          onClick={() => handleOnChange(previousFriday(selectedDate))}
        >
          <ChevronLeftIcon />
        </CustomButton>
        <MuiDatePicker
          label={label}
          value={selectedDate}
          onChange={handleOnChange}
          renderDay={renderPickerDays(selectedWeek)}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              inputProps={{ ...params.inputProps, readOnly: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">{InputIcon}</InputAdornment>
                ),
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="small"
              onClick={() => setOpen(true)}
            />
          )}
          inputFormat="'W/E' do MMM"
          showDaysOutsideCurrentMonth
          disableMaskedInput
        />
        <CustomButton
          aria-label="Next week"
          variant="outlined"
          onClick={() => handleOnChange(nextFriday(selectedDate))}
        >
          <ChevronRightIcon />
        </CustomButton>
      </Box>
    </LocalizationProvider>
  );
};
