import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import { AppBar } from "../AppBar";
import { Navigation } from "../Navigation";

export const PageLayout = () => {
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <Box sx={{ display: "flex" }} data-testid="page-layout">
      <CssBaseline />
      <AppBar onClick={handleDrawerToggle} drawerWidth={drawerWidth} />
      <Navigation
        open={mobileOpen}
        onClose={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />

      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
          p: { xs: 2, sm: 3 },
          pt: { xs: 9, sm: 11 },
        }}
        data-testid="main"
      >
        <Outlet />
      </Box>
    </Box>
  );
};
