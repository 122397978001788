import React from "react";
import { Controller, Control, Path, FieldValues } from "react-hook-form";
import {
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";

type Props<T extends FieldValues> = Omit<
  TextFieldProps,
  "select" | "selectProps"
> & {
  control: Control<T>;
  name: Path<T>;
  optional?: boolean;
  rules?: { pattern?: RegExp; minLength?: number; maxLength?: number };
};

export const TextField = <T extends Record<string, unknown>>({
  control,
  name,
  optional = false,
  rules,
  type,
  ...rest
}: Props<T>) => {
  const validation = {
    ...{
      required: { value: !optional, message: `${rest.label} is required.` },
    },
    ...(type === "email" && {
      pattern: {
        value: /^\S+@\S+\.\S+$/i,
        message: "Must be 'email@domain.site'",
      },
    }),
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...validation,
        ...rules,
      }}
      render={({ field, fieldState: { error } }) => (
        <CustomTextField
          {...field}
          {...rest}
          type={type}
          required={!optional}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          margin="dense"
          size="small"
          error={!!error}
          helperText={error?.message ? error.message : null}
        />
      )}
    />
  );
};

const CustomTextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.common.white,
  },
}));
