import React from "react";
import { Box } from "@mui/material";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CustomTextField, renderPickerDays } from "./CustomPicker";

type Props = Omit<MuiDatePickerProps<Interval, Interval>, "renderInput">;

export const RangePicker = ({ value, onChange }: Props) => {
  const { start, end } = value ?? { start: new Date(), end: new Date() };

  const handleOnChangeStart = (val: Date | null) => {
    onChange(val !== null ? { start: val, end: val > end ? val : end } : val);
  };

  const handleOnChangeEnd = (val: Date | null) => {
    onChange(
      val !== null ? { start: val > start ? start : val, end: val } : val
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <MuiDatePicker
          label="Start Date"
          value={start}
          onChange={handleOnChangeStart}
          renderDay={renderPickerDays({ start, end })}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="outlined"
              inputProps={{ ...params.inputProps, readOnly: true }}
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="small"
            />
          )}
          inputFormat="do MMM"
          showDaysOutsideCurrentMonth
          disableMaskedInput
        />
        <MuiDatePicker
          label="End Date"
          value={end}
          onChange={handleOnChangeEnd}
          renderDay={renderPickerDays({ start, end })}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="outlined"
              inputProps={{ ...params.inputProps, readOnly: true }}
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="small"
            />
          )}
          inputFormat="do MMM"
          showDaysOutsideCurrentMonth
          disableMaskedInput
        />
      </Box>
    </LocalizationProvider>
  );
};
