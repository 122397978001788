import React, { FC } from "react";
import { Button as MuiButton, ButtonProps } from "@mui/material";

type Props = Omit<ButtonProps, "variant" | "disableElevation">;

export const PrimaryButton: FC<Props> = ({ ...props }) => (
  <MuiButton disableElevation variant="contained" color="primary" {...props} />
);

export const SecondaryButton: FC<Props> = ({ ...props }) => (
  <MuiButton disableElevation variant="outlined" {...props} />
);

export const TextButton: FC<Props> = ({ ...props }) => (
  <MuiButton disableElevation variant="text" {...props} />
);
