import React, { FC, ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "../../Buttons";

type Action = {
  label: string;
  icon: ReactElement;
  onClick: () => void;
};

export type Props = {
  title: string;
  description?: string;
  action?: Action;
  testID: string;
};

export const EmptyState: FC<Props> = ({
  title,
  description,
  action,
  testID,
}) => (
  <Box
    sx={{
      position: "relative",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: 1,
    }}
  >
    <Box
      sx={{
        display: "flex",
        width: 1,
        maxWidth: 500,
        flexDirection: "column",
        padding: 3,
        alignItems: "center",
        justifyContent: "center",
        border: 1,
        borderColor: "grey.100",
        backgroundColor: "grey.100",
        borderRadius: 1,
        gap: 2,
      }}
    >
      <Typography component="h3" variant={"h5"}>
        {title}
      </Typography>

      {description && <Typography>{description}</Typography>}

      {action && (
        <PrimaryButton
          onClick={action.onClick}
          startIcon={action.icon}
          data-testid={`${testID}-no-data-button`}
        >
          {action.label}
        </PrimaryButton>
      )}
    </Box>
  </Box>
);
