import React, { FC } from "react";
import { Chip } from "@mui/material";
import { TooltipCell } from "./TooltipCell";

type Props = {
  data: (string | undefined)[];
};

export const ChipListCell: FC<Props> = ({ data }) => (
  <TooltipCell>{buildChips(data)}</TooltipCell>
);

const buildChips = (data: (string | undefined)[]) =>
  data.map((item, idx) => (
    <Chip key={`${item}-${idx}`} label={item} sx={{ m: 0.5 }} />
  ));
