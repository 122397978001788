import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { nextFriday, toDate } from "date-fns";
import { Client, Employee } from "../../../../API";
import { Address } from "../../../../addresses";
import { Info } from ".";

const styles = StyleSheet.create({
  /* stylelint-disable */
  container: {
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  column: {
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: "auto",
    paddingTop: 15,
    paddingRight: 10,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
  },
  /* stylelint-enable */
});

type Props = {
  employee: Employee;
  client: Client;
  week: Interval;
  address: Address;
};

export const Overview = ({
  employee: { firstName, lastName, email },
  client,
  week: { start },
  address,
}: Props) => (
  <View style={styles.container}>
    <View style={styles.column}>
      <Info title={"Address:"} content={`${address.line1}`} />
      <Info content={`${address.line2}`} />
      <Info title={"City:"} content={`${address.line3}`} />
      <Info
        title={"Client:"}
        content={`${client.firstName} ${client.lastName} (${client.company.name})`}
      />
    </View>
    <View style={styles.column}>
      <Info
        title={"Pay Period Start Date:"}
        content={toDate(start).toLocaleDateString()}
      />
      <Info
        title={"Pay Period End Date:"}
        content={nextFriday(start).toLocaleDateString()}
      />
      <Info title={"Employee:"} content={`${firstName} ${lastName}`} />
      <Info title={"Employee Email:"} content={email} />
    </View>
  </View>
);
