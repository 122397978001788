import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ActionBar, WeekPicker } from "../../components";
import { gqlQuery } from "../../lib";
import { RangePicker } from "../DatePickers";
import { SearchField } from "../OptionField";

type FilterProps<T> = {
  value: T;
  onChange: (value: T) => void;
  type?: "week" | "range";
  InputIcon?: ReactElement;
};

type Props = {
  testID: string;
  company?: FilterProps<string>;
  employee?: FilterProps<string>;
  dates?: FilterProps<Interval | null>;
};

export const FilterBar = ({ testID, company, employee, dates }: Props) => {
  const { data: companies } = useQuery(
    gqlQuery({
      req: "listCompanies",
      vars: {
        limit: 1000,
      },
    })
  );
  const { data: employees } = useQuery(
    gqlQuery({
      req: "listEmployees",
      vars: {
        limit: 1000,
      },
    })
  );

  const employeeOptions = [
    ...(employees?.data?.listEmployees?.items ?? []).map((i, index) => ({
      label: `${i?.firstName} ${i?.lastName}` ?? "",
      value: i?.id ?? "",
      key: i?.id ?? index,
    })),
  ];

  const companyOptions = [
    ...(companies?.data?.listCompanies?.items ?? []).map((i, index) => ({
      label: i?.name ?? "",
      value: i?.id ?? "",
      key: i?.id ?? index,
    })),
  ];

  const DatePicker = ({ type, ...dates }: FilterProps<Interval | null>) =>
    ({
      week: <WeekPicker {...dates} data-testid={`${testID}-weekpicker`} />,
      range: (
        <RangePicker {...dates} data-testid={`${testID}-daterange-picker`} />
      ),
      _: null,
    }[type ?? "_"]);

  return (
    <ActionBar>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            lg: "max-content 400px",
          },
          gap: 2,
        }}
      >
        {dates && <DatePicker {...dates} />}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
            },
            gap: 2,
          }}
        >
          {employee && (
            <SearchField
              {...employee}
              label="Employee"
              options={employeeOptions}
              data-testid={`${testID}-employee-dropdown`}
            />
          )}
          {company && (
            <SearchField
              {...company}
              label="Company"
              options={companyOptions}
              data-testid={`${testID}-company-dropdown`}
            />
          )}
        </Box>
      </Box>
    </ActionBar>
  );
};
