import React from "react";
import { ListItemIcon, Menu as MuiMenu, MenuItem } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAuthenticator } from "@aws-amplify/ui-react";

type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  close: () => void;
};

export const Menu = ({ anchorEl, open, close }: Props) => {
  const { signOut } = useAuthenticator((context) => [context.user]);

  return (
    <MuiMenu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={close}
      onClick={close}
      PaperProps={{
        elevation: 3,
        sx: {
          overflow: "visible",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: "''",
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          close();
          signOut();
        }}
        data-testid="dt-logout-link"
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </MuiMenu>
  );
};
