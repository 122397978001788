import React from "react";
import {
  CloudDoneOutlined as CloudDoneOutlinedIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  CloudOffOutlined as CloudOffOutlinedIcon,
} from "@mui/icons-material";
import { StatusIcon, StatusProps } from "./StatusIcon";

type Status = "saving" | "saved" | "error" | boolean | null;

const mapToStatus = {
  saved: {
    label: "Saved",
    icon: <CloudDoneOutlinedIcon color="info" />,
  },
  saving: {
    label: "Saving",
    icon: <CloudUploadOutlinedIcon color="info" />,
  },
  error: {
    label: "Changes not saved",
    icon: <CloudOffOutlinedIcon color="error" />,
  },
};

export const TimesheetIcon = ({ status, ...rest }: StatusProps<Status>) => (
  <StatusIcon {...rest} status={getStatus(status)} />
);

const getStatus = (val: Status) =>
  mapToStatus[
    typeof val === "string"
      ? val
      : val === null
      ? "saving"
      : val
      ? "saved"
      : "error"
  ];
