import React from "react";
import { alpha, Grid } from "@mui/material";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { Tooltip } from "../../Tooltip";
import { HourCategories } from "../../../lib";
import { ApprovalRow, DateHours } from "../../../pages/Approvals/utils";

type Props = {
  children: React.ReactElement;
  data: ApprovalRow;
};

const toDay = (date: string) => format(new Date(date), "dd/MM/yyyy");

const FormRow = ({ date, hours }: { date: string; hours?: number }) => (
  <Box
    key={date}
    sx={{
      px: 1,
      py: 0.5,
      textAlign: "center",
      borderBlockEnd: "solid",
      backgroundColor: ({ palette }) =>
        hours === 8
          ? alpha(palette.success.light, 0.5)
          : hours === 0
          ? alpha(palette.error.light, 0.3)
          : alpha(palette.warning.light, 0.1),
    }}
  >
    {hours === undefined ? `${toDay(date)}:` : hours}
  </Box>
);

export const SummaryCell = ({ children, data }: Props) =>
  data.total > 0 ? (
    <Tooltip title={GridBox(data)} placement="right-end">
      {children}
    </Tooltip>
  ) : (
    children
  );

const GridBox = (data: DateHours) => (
  <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={0.25}>
      <Grid item>
        {data["standard"].dates.map(({ date }) => ({ date })).map(FormRow)}
      </Grid>
      {Object.values(HourCategories).map((cat) => (
        <Grid item key={cat}>
          {data[cat].dates.map(FormRow)}
          {(cat[0] + cat[1]).toUpperCase()}
        </Grid>
      ))}
    </Grid>
  </Box>
);
