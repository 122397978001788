import React from "react";
import { Document, Page, View, Font, StyleSheet } from "@react-pdf/renderer";
import { Client, CreateTimelogInput, Employee } from "../../../API";
import { Address } from "../../../addresses";
import { Header, Overview, WeekSummary } from "./components";

const styles = StyleSheet.create({
  /* stylelint-disable */
  page: {},
  container: {
    marginHorizontal: 10,
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  /* stylelint-enable */
});

Font.register({
  family: "Lato",
  src: "https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf",
});

Font.register({
  family: "Lato Italic",
  src: "https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf",
});

Font.register({
  family: "Lato Bold",
  src: "https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf",
});

type Props = {
  title: string;
  employee: Employee;
  client: Client;
  timesheet: CreateTimelogInput[];
  week: Interval;
  address: Address;
};

export const TimesheetPDF = ({ title, timesheet, ...props }: Props) => (
  <Document
    author="Test Driven Solutions Ltd"
    subject="Weekly client hours"
    title={title}
  >
    <Page orientation="landscape" size="A4" style={styles.page}>
      <Header />
      <View style={styles.container}>
        <Overview {...props} />
        <WeekSummary data={timesheet} />
      </View>
    </Page>
  </Document>
);
