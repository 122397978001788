import { ModelTimelogFilterInput, Employee, Timelog, Client } from "../../API";
import { filterNull, gqlQuery, QueryType, toDbDates } from "../../lib";

type QueryProps = {
  employeeID: string;
  companyID: string;
  dates: Interval | null;
  logFilter?: ModelTimelogFilterInput | null;
};

export const reportsQuery =
  ({ dates, employeeID, companyID, logFilter }: QueryProps) =>
  (input: Omit<QueryType, "req">) => {
    if (dates === null) {
      throw new Error(`Incorrect date range. Value is ${dates}`);
    }
    const dateRange = toDbDates(dates);

    const { queryFn: listEmployeesQueryFn, queryKey } = gqlQuery({
      req: "listEmployeesWithTimelogs",
      vars: {
        ...input.vars,
        ...(employeeID.length > 0 && { employeeID }),
        ...(companyID.length > 0 && { companyID }),
        logFilter,
        dateRange,
      },
      key:
        employeeID || companyID
          ? [dateRange, { companyID, employeeID }, ...(input.key ?? [])]
          : [dateRange, ...(input.key ?? [])],
    });

    const { queryFn: listClientsQueryFn } = gqlQuery({ req: "listClients" });

    return {
      queryKey,
      queryFn: async () => {
        const { data: employeeData, errors: employeeErrors = [] } =
          await listEmployeesQueryFn();
        const employees = await filterNull<Employee>(
          (employeeData?.listEmployees?.items ?? []) as Employee[]
        );
        const { data: clientsData, errors: clientsErrors = [] } =
          await listClientsQueryFn();
        const clients = await filterNull<Client>(
          (clientsData?.listClients?.items ?? []) as Client[]
        );

        return {
          employees,
          items: await buildSummaryReport(employees, clients),
          errors: [...employeeErrors, ...clientsErrors],
          nextToken: employeeData?.listEmployees?.nextToken,
        };
      },
      enabled: !companyID || !!logFilter,
    };
  };

export type SummaryReportRow = {
  id: string;
  company: string;
  client: string;
  employee: string;
  hours: number;
};

const buildSummaryReport = async (
  employees: Employee[],
  clients: Client[]
): Promise<SummaryReportRow[]> =>
  (
    await Promise.all(
      employees.map(async ({ firstName, lastName, timelogs }) => {
        // An Employee will have a SummaryReportRow for each Client they have worked on in the report search criteria.
        const summaryReport: { [key: string]: SummaryReportRow } = {};
        const approvedLogs = await filterNull(
          timelogs?.items,
          ({ approved }) => !!approved
        );
        return Object.values(
          approvedLogs.reduce(
            calcEmployeeHours(clients, `${firstName} ${lastName}`),
            summaryReport
          )
        );
      })
    )
  ).flat();

const calcEmployeeHours =
  (clients: Client[], employeeName: string) =>
  (
    summary: { [key: string]: SummaryReportRow },
    { employeeID, clientID, standard, overtime }: Timelog
  ) => {
    const id = `${clientID}-${employeeID}`;
    const client = clients.find((client) => client.id === clientID) ?? {
      company: { name: "Unknown Company" },
      firstName: "ID",
      lastName: clientID,
    };
    return {
      ...summary,
      [id]: {
        id,
        company: client.company.name,
        client: `${client.firstName} ${client.lastName}`,
        employee: employeeName,
        hours: (summary[id]?.hours ?? 0) + (standard ?? 0) + (overtime ?? 0),
      },
    };
  };
