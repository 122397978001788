import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { CreateTimelogInput } from "../../../API";
import {
  DefaultModalProps,
  ModalSkeleton,
  SelectField,
  TextField,
} from "../../../components";
import { Option } from "../Timesheet";

type FormFields = CreateTimelogInput;

type Props = DefaultModalProps & {
  onSubmit: ({ data }: { data: FormFields }) => void;
  testID: string;
  params: { contracts: Option[]; row: FormFields };
};

export const TimesheetModal: FC<Props> = ({
  onSubmit,
  testID,
  params: { contracts = [], row },
  ...rest
}) => {
  const [saving, setSaving] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: mapDefaultValues(row),
    mode: "onBlur",
  });

  return (
    <ModalSkeleton
      {...rest}
      isForm
      title={`Enter time for ${new Date(row.date).toLocaleDateString()}`}
      primaryAction={{
        label: "Save",
        pending: saving,
        onSubmit: handleSubmit(
          ({ standard, overtime, sick, holiday, ...rest }) => {
            setSaving(true);
            return onSubmit({
              data: {
                standard: Number(standard),
                overtime: Number(overtime),
                sick: Number(sick),
                holiday: Number(holiday),
                ...rest,
              },
            });
          }
        ),
        testID: "save",
      }}
      testID={testID}
    >
      <Box
        sx={{
          display: "grid",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
            gap: 2,
          }}
        >
          <TextField control={control} name="date" label="Date" disabled />
          <SelectField
            control={control}
            name="clientID"
            label="Client"
            options={contracts}
            displayEmpty
            variant="outlined"
          />
          <TextField
            control={control}
            name="standard"
            label="Standard"
            type="number"
            inputProps={{ min: 0 }}
            optional
          />
          <TextField
            control={control}
            name="overtime"
            label="Overtime"
            type="number"
            inputProps={{ min: 0 }}
            optional
          />
          <TextField
            control={control}
            name="sick"
            label="Sick"
            type="number"
            inputProps={{ min: 0 }}
            optional
          />
          <TextField
            control={control}
            name="holiday"
            label="Holiday"
            type="number"
            inputProps={{ min: 0 }}
            optional
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
          }}
        >
          <TextField
            control={control}
            name="notes"
            label="Notes"
            type="text"
            multiline
            minRows={2}
            optional
          />
        </Box>
      </Box>
    </ModalSkeleton>
  );
};

const mapDefaultValues = (value: FormFields): FormFields => ({
  id: value.id,
  date: value.date,
  clientID: value.clientID,
  employeeID: value.employeeID,
  standard: value.standard ?? 0,
  overtime: value.overtime ?? 0,
  sick: value.sick ?? 0,
  holiday: value.holiday ?? 0,
  notes: value.notes ?? "",
});
