import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import { format } from "date-fns";
import { CreateTimelogInput } from "../../API";
import { ActionCell } from "../../components";
import { HourCategories } from "../../lib";
import { Option } from "./Timesheet";

const PLACEHOLDER = "No clients";

type Props = {
  contracts: Option[];
  onOpenModal: ({ row }: { row: CreateTimelogInput }) => void;
  onUpdateOnCall: ({ row }: { row: CreateTimelogInput }) => void;
  editable: boolean;
  showOnCall: boolean;
};

const hourFields = (editable: boolean) =>
  Object.values(HourCategories).reduce(
    (fields: GridColDef<CreateTimelogInput, string>[], cat) => [
      ...fields,
      {
        field: cat,
        headerName: cat[0].toUpperCase() + cat.slice(1),
        type: "number",
        editable,
        minWidth: 125,
        flex: 1,
        sortable: false,
      },
    ],
    []
  );

export const columns = ({
  contracts,
  onOpenModal,
  onUpdateOnCall,
  editable,
  showOnCall,
}: Props): GridColDef<CreateTimelogInput, string>[] => [
  {
    field: "day",
    headerName: "Day",
    minWidth: 170,
    valueGetter: ({ row }) => format(new Date(row.date), "EEEE"),
    renderCell: ({ row, value }) => (
      <ActionCell disabled={!editable} onClick={() => onOpenModal({ row })}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {value}
          <OpenInNewIcon fontSize="small" />
        </Box>
      </ActionCell>
    ),
    cellClassName: () => (editable ? "" : "success"),
    sortable: false,
  },
  {
    field: "date",
    headerName: "Date",
    type: "date",
    minWidth: 115,
    valueGetter: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
    sortable: false,
  },
  {
    field: "clientID",
    headerName: "Client",
    type: "singleSelect",
    editable,
    minWidth: 150,
    valueOptions: contracts,
    valueFormatter: ({ value: clientID }) =>
      contracts.find(({ value }) => value === clientID)?.label ?? PLACEHOLDER,
    sortable: false,
  },
  {
    field: "onCall",
    headerName: "On-call",
    type: "boolean",
    editable,
    hide: !showOnCall,
    width: 75,
    align: "center",
    renderCell: ({ value, row }) => (
      <ActionCell
        disabled={!editable}
        onClick={() => onUpdateOnCall({ row: { ...row, onCall: !value } })}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
        </Box>
      </ActionCell>
    ),
    sortable: false,
  },
  ...hourFields(editable),
  {
    field: "total",
    headerName: "Total",
    type: "number",
    minWidth: 125,
    valueGetter: ({ row }) =>
      [row.standard, row.overtime, row.sick, row.holiday]
        .map((v) => v ?? 0)
        .reduce((a, b) => a + b, 0)
        .toString(),
    cellClassName: ({ value }) =>
      value === "0" ? "error" : value === "8" ? "success" : "warning",
    sortable: false,
  },
];
