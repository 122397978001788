import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

export const NotFound: FC = () => (
  <Box
    sx={{
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "cemter",
      textAlign: "center",
      justifyContent: "center",
    }}
  >
    <Typography variant="h1" mb={3} data-testid="title">
      Oops!
    </Typography>
    <Typography data-testid="not-found">404 - PAGE NOT FOUND</Typography>
    <Typography mb={3}>
      The page you are looking for doesn&apos;t exist
    </Typography>
    <Box>
      <Button
        component={NavLink}
        to={"/"}
        variant="contained"
        data-testid="home-btn"
      >
        Go Home
      </Button>
    </Box>
  </Box>
);
