import React from "react";
import { Box } from "@mui/material";

export const ActionBar = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      gap: 2,
      width: 1,
      mb: 2,
      px: 2,
      py: 1,
      bgcolor: "grey.100",
      borderRadius: 1,
    }}
    data-testid="action-bar"
  >
    {children}
  </Box>
);
