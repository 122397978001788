import { __extends } from "tslib";
import { AssociateTrackerConsumerCommand, } from "./commands/AssociateTrackerConsumerCommand";
import { BatchDeleteDevicePositionHistoryCommand, } from "./commands/BatchDeleteDevicePositionHistoryCommand";
import { BatchDeleteGeofenceCommand, } from "./commands/BatchDeleteGeofenceCommand";
import { BatchEvaluateGeofencesCommand, } from "./commands/BatchEvaluateGeofencesCommand";
import { BatchGetDevicePositionCommand, } from "./commands/BatchGetDevicePositionCommand";
import { BatchPutGeofenceCommand, } from "./commands/BatchPutGeofenceCommand";
import { BatchUpdateDevicePositionCommand, } from "./commands/BatchUpdateDevicePositionCommand";
import { CalculateRouteCommand, } from "./commands/CalculateRouteCommand";
import { CalculateRouteMatrixCommand, } from "./commands/CalculateRouteMatrixCommand";
import { CreateGeofenceCollectionCommand, } from "./commands/CreateGeofenceCollectionCommand";
import { CreateMapCommand } from "./commands/CreateMapCommand";
import { CreatePlaceIndexCommand, } from "./commands/CreatePlaceIndexCommand";
import { CreateRouteCalculatorCommand, } from "./commands/CreateRouteCalculatorCommand";
import { CreateTrackerCommand, } from "./commands/CreateTrackerCommand";
import { DeleteGeofenceCollectionCommand, } from "./commands/DeleteGeofenceCollectionCommand";
import { DeleteMapCommand } from "./commands/DeleteMapCommand";
import { DeletePlaceIndexCommand, } from "./commands/DeletePlaceIndexCommand";
import { DeleteRouteCalculatorCommand, } from "./commands/DeleteRouteCalculatorCommand";
import { DeleteTrackerCommand, } from "./commands/DeleteTrackerCommand";
import { DescribeGeofenceCollectionCommand, } from "./commands/DescribeGeofenceCollectionCommand";
import { DescribeMapCommand } from "./commands/DescribeMapCommand";
import { DescribePlaceIndexCommand, } from "./commands/DescribePlaceIndexCommand";
import { DescribeRouteCalculatorCommand, } from "./commands/DescribeRouteCalculatorCommand";
import { DescribeTrackerCommand, } from "./commands/DescribeTrackerCommand";
import { DisassociateTrackerConsumerCommand, } from "./commands/DisassociateTrackerConsumerCommand";
import { GetDevicePositionCommand, } from "./commands/GetDevicePositionCommand";
import { GetDevicePositionHistoryCommand, } from "./commands/GetDevicePositionHistoryCommand";
import { GetGeofenceCommand } from "./commands/GetGeofenceCommand";
import { GetMapGlyphsCommand, } from "./commands/GetMapGlyphsCommand";
import { GetMapSpritesCommand, } from "./commands/GetMapSpritesCommand";
import { GetMapStyleDescriptorCommand, } from "./commands/GetMapStyleDescriptorCommand";
import { GetMapTileCommand } from "./commands/GetMapTileCommand";
import { ListDevicePositionsCommand, } from "./commands/ListDevicePositionsCommand";
import { ListGeofenceCollectionsCommand, } from "./commands/ListGeofenceCollectionsCommand";
import { ListGeofencesCommand, } from "./commands/ListGeofencesCommand";
import { ListMapsCommand } from "./commands/ListMapsCommand";
import { ListPlaceIndexesCommand, } from "./commands/ListPlaceIndexesCommand";
import { ListRouteCalculatorsCommand, } from "./commands/ListRouteCalculatorsCommand";
import { ListTagsForResourceCommand, } from "./commands/ListTagsForResourceCommand";
import { ListTrackerConsumersCommand, } from "./commands/ListTrackerConsumersCommand";
import { ListTrackersCommand, } from "./commands/ListTrackersCommand";
import { PutGeofenceCommand } from "./commands/PutGeofenceCommand";
import { SearchPlaceIndexForPositionCommand, } from "./commands/SearchPlaceIndexForPositionCommand";
import { SearchPlaceIndexForSuggestionsCommand, } from "./commands/SearchPlaceIndexForSuggestionsCommand";
import { SearchPlaceIndexForTextCommand, } from "./commands/SearchPlaceIndexForTextCommand";
import { TagResourceCommand } from "./commands/TagResourceCommand";
import { UntagResourceCommand, } from "./commands/UntagResourceCommand";
import { UpdateGeofenceCollectionCommand, } from "./commands/UpdateGeofenceCollectionCommand";
import { UpdateMapCommand } from "./commands/UpdateMapCommand";
import { UpdatePlaceIndexCommand, } from "./commands/UpdatePlaceIndexCommand";
import { UpdateRouteCalculatorCommand, } from "./commands/UpdateRouteCalculatorCommand";
import { UpdateTrackerCommand, } from "./commands/UpdateTrackerCommand";
import { LocationClient } from "./LocationClient";
var Location = (function (_super) {
    __extends(Location, _super);
    function Location() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Location.prototype.associateTrackerConsumer = function (args, optionsOrCb, cb) {
        var command = new AssociateTrackerConsumerCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.batchDeleteDevicePositionHistory = function (args, optionsOrCb, cb) {
        var command = new BatchDeleteDevicePositionHistoryCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.batchDeleteGeofence = function (args, optionsOrCb, cb) {
        var command = new BatchDeleteGeofenceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.batchEvaluateGeofences = function (args, optionsOrCb, cb) {
        var command = new BatchEvaluateGeofencesCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.batchGetDevicePosition = function (args, optionsOrCb, cb) {
        var command = new BatchGetDevicePositionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.batchPutGeofence = function (args, optionsOrCb, cb) {
        var command = new BatchPutGeofenceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.batchUpdateDevicePosition = function (args, optionsOrCb, cb) {
        var command = new BatchUpdateDevicePositionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.calculateRoute = function (args, optionsOrCb, cb) {
        var command = new CalculateRouteCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.calculateRouteMatrix = function (args, optionsOrCb, cb) {
        var command = new CalculateRouteMatrixCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.createGeofenceCollection = function (args, optionsOrCb, cb) {
        var command = new CreateGeofenceCollectionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.createMap = function (args, optionsOrCb, cb) {
        var command = new CreateMapCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.createPlaceIndex = function (args, optionsOrCb, cb) {
        var command = new CreatePlaceIndexCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.createRouteCalculator = function (args, optionsOrCb, cb) {
        var command = new CreateRouteCalculatorCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.createTracker = function (args, optionsOrCb, cb) {
        var command = new CreateTrackerCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.deleteGeofenceCollection = function (args, optionsOrCb, cb) {
        var command = new DeleteGeofenceCollectionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.deleteMap = function (args, optionsOrCb, cb) {
        var command = new DeleteMapCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.deletePlaceIndex = function (args, optionsOrCb, cb) {
        var command = new DeletePlaceIndexCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.deleteRouteCalculator = function (args, optionsOrCb, cb) {
        var command = new DeleteRouteCalculatorCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.deleteTracker = function (args, optionsOrCb, cb) {
        var command = new DeleteTrackerCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.describeGeofenceCollection = function (args, optionsOrCb, cb) {
        var command = new DescribeGeofenceCollectionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.describeMap = function (args, optionsOrCb, cb) {
        var command = new DescribeMapCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.describePlaceIndex = function (args, optionsOrCb, cb) {
        var command = new DescribePlaceIndexCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.describeRouteCalculator = function (args, optionsOrCb, cb) {
        var command = new DescribeRouteCalculatorCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.describeTracker = function (args, optionsOrCb, cb) {
        var command = new DescribeTrackerCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.disassociateTrackerConsumer = function (args, optionsOrCb, cb) {
        var command = new DisassociateTrackerConsumerCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.getDevicePosition = function (args, optionsOrCb, cb) {
        var command = new GetDevicePositionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.getDevicePositionHistory = function (args, optionsOrCb, cb) {
        var command = new GetDevicePositionHistoryCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.getGeofence = function (args, optionsOrCb, cb) {
        var command = new GetGeofenceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.getMapGlyphs = function (args, optionsOrCb, cb) {
        var command = new GetMapGlyphsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.getMapSprites = function (args, optionsOrCb, cb) {
        var command = new GetMapSpritesCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.getMapStyleDescriptor = function (args, optionsOrCb, cb) {
        var command = new GetMapStyleDescriptorCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.getMapTile = function (args, optionsOrCb, cb) {
        var command = new GetMapTileCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listDevicePositions = function (args, optionsOrCb, cb) {
        var command = new ListDevicePositionsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listGeofenceCollections = function (args, optionsOrCb, cb) {
        var command = new ListGeofenceCollectionsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listGeofences = function (args, optionsOrCb, cb) {
        var command = new ListGeofencesCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listMaps = function (args, optionsOrCb, cb) {
        var command = new ListMapsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listPlaceIndexes = function (args, optionsOrCb, cb) {
        var command = new ListPlaceIndexesCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listRouteCalculators = function (args, optionsOrCb, cb) {
        var command = new ListRouteCalculatorsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listTagsForResource = function (args, optionsOrCb, cb) {
        var command = new ListTagsForResourceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listTrackerConsumers = function (args, optionsOrCb, cb) {
        var command = new ListTrackerConsumersCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.listTrackers = function (args, optionsOrCb, cb) {
        var command = new ListTrackersCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.putGeofence = function (args, optionsOrCb, cb) {
        var command = new PutGeofenceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.searchPlaceIndexForPosition = function (args, optionsOrCb, cb) {
        var command = new SearchPlaceIndexForPositionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.searchPlaceIndexForSuggestions = function (args, optionsOrCb, cb) {
        var command = new SearchPlaceIndexForSuggestionsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.searchPlaceIndexForText = function (args, optionsOrCb, cb) {
        var command = new SearchPlaceIndexForTextCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.tagResource = function (args, optionsOrCb, cb) {
        var command = new TagResourceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.untagResource = function (args, optionsOrCb, cb) {
        var command = new UntagResourceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.updateGeofenceCollection = function (args, optionsOrCb, cb) {
        var command = new UpdateGeofenceCollectionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.updateMap = function (args, optionsOrCb, cb) {
        var command = new UpdateMapCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.updatePlaceIndex = function (args, optionsOrCb, cb) {
        var command = new UpdatePlaceIndexCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.updateRouteCalculator = function (args, optionsOrCb, cb) {
        var command = new UpdateRouteCalculatorCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Location.prototype.updateTracker = function (args, optionsOrCb, cb) {
        var command = new UpdateTrackerCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    return Location;
}(LocationClient));
export { Location };
