import { alpha, styled } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color: "rgb(0 0 0 / 85%)",
  webkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.grey[50],
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #f0f0f0",
  },
  "& .MuiDataGrid-columnHeader:not(:last-child), .MuiDataGrid-cell": {
    borderRight: "1px solid #f0f0f0",
  },
  "& .MuiDataGrid-cell": {
    color: "rgb(0 0 0 / 85%)",
  },
  "& .MuiDataGrid-cell.error": {
    backgroundColor: alpha(theme.palette.error.light, 0.1),
  },
  "& .MuiDataGrid-cell.warning": {
    backgroundColor: alpha(theme.palette.warning.light, 0.1),
  },
  "& .MuiDataGrid-cell.success": {
    backgroundColor: alpha(theme.palette.success.light, 0.5),
  },
  "& .MuiDataGrid-cell--editable": {
    cursor: "pointer",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  ".MuiDataGrid-cell:first-of-type": {
    borderLeft: "1px solid #f0f0f0",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  [`& .${gridClasses.row}`]: {
    "&.warning": {
      backgroundColor: alpha(theme.palette.warning.light, 0.1),
    },
    "&.danger": {
      backgroundColor: alpha(theme.palette.error.light, 0.1),
    },
    "&.even": {
      backgroundColor: theme.palette.grey[50],
      "&.warning": {
        backgroundColor: alpha(theme.palette.warning.light, 0.2),
      },
      "&.danger": {
        backgroundColor: alpha(theme.palette.error.light, 0.2),
      },
    },
  },
  [`& .${gridClasses.row}.odd, & .${gridClasses.row}.even`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));
