import React from "react";
import { Controller, Control, Path, FieldValues } from "react-hook-form";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
  header?: boolean;
};

type Props<T extends FieldValues> = SelectProps & {
  control: Control<T>;
  name: Path<T>;
  options: Option[];
  optional?: boolean;
};

const genLabel = () => Math.random().toString(36).substring(2, 9);

const findOption = (options: Option[], value: string) =>
  options.find((option) => option.value === value);

export const SelectField = <T extends Record<string, unknown>>({
  control,
  name,
  label,
  options,
  optional = false,
  multiple,
  placeholder,
  ...rest
}: Props<T>) => {
  const labelId = genLabel();
  options =
    multiple || findOption(options, "")
      ? options
      : [{ label: "None", value: "" }, ...options];

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: !optional }}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          variant="outlined"
          margin="dense"
          size="small"
          required={!optional}
          error={!!error}
          sx={{ bgcolor: "common.white" }}
        >
          <InputLabel shrink id={labelId} required={!optional}>
            {label}
          </InputLabel>
          <Select
            {...field}
            {...rest}
            multiple={multiple}
            labelId={labelId}
            value={options.length > 1 ? field.value : ""}
            input={<OutlinedInput notched label={label} />}
            renderValue={(value) => {
              const selected = multiple
                ? (value as string[])
                : (value as string);

              if (!selected || !selected?.length) {
                return (
                  <Typography variant="caption" color="gray">
                    {placeholder ? placeholder : "Please select..."}
                  </Typography>
                );
              }

              if (multiple) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      marginBottom: "-1px",
                    }}
                  >
                    {(selected as string[]).map((item) => (
                      <Chip
                        key={item}
                        label={findOption(options, item)?.label}
                        size="small"
                      />
                    ))}
                  </Box>
                );
              }

              return (
                <Typography>
                  {findOption(options, selected as string)?.label}
                </Typography>
              );
            }}
            displayEmpty
          >
            {options.map((option) =>
              option.header ? (
                <ListSubheader key={option.value}>{option.label}</ListSubheader>
              ) : (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {multiple ? (
                    <>
                      <Checkbox
                        checked={
                          (field.value as string[]).indexOf(option.value) > -1
                        }
                      />
                      <ListItemText primary={option.label} />
                    </>
                  ) : option.value ? (
                    <ListItemText primary={option.label} />
                  ) : (
                    <ListItemText secondary={option.label} />
                  )}
                </MenuItem>
              )
            )}
          </Select>

          {error?.message && (
            <FormHelperText error={true}>{error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
