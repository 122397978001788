import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateEmployeeInput,
  Employee,
  ClientContracts,
  UpdateEmployeeInput,
} from "../API";
import { ApiActions, filterNull, gqlMutation, useContractsLib } from ".";

export const useEmployeeLib = () => {
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () =>
      queryClient.invalidateQueries(["listEmployeesWithCompanies"]),
  };

  const { mutateAsync: createEmployee } = useMutation({
    ...gqlMutation({ req: "createEmployee" }),
    ...options,
  });
  const { mutateAsync: updateEmployee } = useMutation({
    ...gqlMutation({ req: "updateEmployee" }),
    ...options,
    onSettled: (res) => {
      queryClient.invalidateQueries([
        "getEmployeeWithCompany",
        res?.data?.updateEmployee?.id,
      ]);
    },
  });
  const { mutateAsync: deleteEmployee } = useMutation({
    ...gqlMutation({ req: "deleteEmployee" }),
    ...options,
  });
  const { cleanupContracts, moveContracts } = useContractsLib();

  const createEmployeeRecord = async (
    input: CreateEmployeeInput,
    actions: ApiActions,
    fromRecord?: Employee
  ) => {
    createEmployee({ input }, actions);
    if (fromRecord && input.id) {
      migrateRecord(input.id, fromRecord);
    }
  };

  const updateEmployeeRecord = async (
    input: UpdateEmployeeInput,
    actions: ApiActions,
    contracts?: ClientContracts[]
  ) => {
    if (contracts?.length && !!input.inactiveAt) {
      cleanupContracts({ id: input.id, clientContracts: contracts });
    }
    updateEmployee({ input }, actions);
  };

  const migrateRecord = async (id: string, fromRecord: Employee) => {
    const { id: baseID, clients } = fromRecord;
    const contracts = (await filterNull<ClientContracts>(clients?.items)).map(
      ({ id }) => id
    );
    moveContracts(id, contracts);
    deleteEmployee({ input: { id: baseID } });
  };

  const cleanupEmployeeRecords = async (
    { id, clients }: Employee,
    actions: ApiActions
  ) => {
    if (clients?.items) {
      await cleanupContracts({
        id,
        clientContracts: clients?.items ?? [],
      });
    }
    deleteEmployee({ input: { id } }, actions);
  };

  return {
    createEmployeeRecord,
    updateEmployeeRecord,
    cleanupEmployeeRecords,
  };
};
