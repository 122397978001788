import { createContext } from "react";
import { ShowModal, HideModal } from "./types";

type Context = {
  showModal: (args: ShowModal) => void;
  hideModal: (args: HideModal) => void;
};

export const ModalContext = createContext<Context>({
  showModal: () => ({ key: "", component: null }),
  hideModal: () => ({ key: "" }),
});
