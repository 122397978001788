import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  /* stylelint-disable */
  item: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  title: {
    margin: 4,
    fontSize: 14,
    fontFamily: "Lato Bold",
    textAlign: "justify",
  },
  content: {
    margin: 4,
    fontSize: 12,
    fontFamily: "Lato",
    textAlign: "justify",
  },
  /* stylelint-enable */
});

type Props = {
  title?: string;
  content?: string;
};

export const Info = ({ title, content }: Props) => (
  <View style={styles.item}>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.content}>{content}</Text>
  </View>
);
