import React, { FC, useState } from "react";
import { ModalSkeleton } from "./ModalSkeleton";
import { DefaultModalProps } from "./types";

export type UpdateModalProps<T> = DefaultModalProps & {
  title: string;
  label: string;
  description: string;
  onUpdate: (row: T) => void;
  params: T;
};

export const UpdateModal: FC<UpdateModalProps<never>> = <T,>({
  title,
  label,
  description,
  onUpdate,
  params,
  ...rest
}: UpdateModalProps<T>) => {
  const [updating, setUpdating] = useState(false);
  return (
    <ModalSkeleton
      title={title}
      primaryAction={{
        label: label,
        pending: updating,
        onClick: () => {
          setUpdating(true);
          return onUpdate(params);
        },
        isDanger: true,
        testID: "update",
      }}
      testID="update"
      {...rest}
    >
      {description}
    </ModalSkeleton>
  );
};
