import { ComponentProps } from "react";
import { Employee } from "../../../API";
import { toAWSDate, useContractsLib, useEmployeeLib } from "../../../lib";
import {
  ContractsModal,
  ContractsModalParams,
  UpdateModal,
  useModal,
} from "../../../components";
import { EmployeeModal } from "./EmployeeModal";

// This hook is solely responsible for the management of the various Employee modals.
// Here we can manage the modals for the various actions (CRUD) that we want to perform on the employees.
export const useEmployeeModal = () => {
  const { createEmployeeRecord, updateEmployeeRecord } = useEmployeeLib();
  const { setContracts } = useContractsLib();

  const { showModal: showCreateModal, hideModal: hideCreateModal } = useModal({
    component: EmployeeModal,
    data: {
      title: "Add Employee",
      onSubmit: ({ data }) =>
        createEmployeeRecord(data, { onSuccess: hideCreateModal }),
      testID: "add-employee",
    },
  });

  const { showModal: showUpdateModal, hideModal: hideUpdateModal } = useModal<
    ComponentProps<typeof EmployeeModal>,
    { row: Employee }
  >({
    component: EmployeeModal,
    data: {
      title: "Update Employee",
      onSubmit: ({ data: { id, inactiveAt, ...rest }, contracts }) =>
        id &&
        updateEmployeeRecord(
          { ...rest, inactiveAt, id },
          {
            onSuccess: hideUpdateModal,
          },
          contracts
        ),
      testID: "update-employee",
    },
  });

  const { showModal: showDeactivateModal, hideModal: hideDeactivateModal } =
    useModal<ComponentProps<typeof UpdateModal>, Employee>({
      component: UpdateModal,
      data: {
        title: "Deactivate Employee",
        label: "Deactivate",
        description: "Are you sure you want to deactivate this employee?",
        onUpdate: ({ id, clients: { items: contracts } }) =>
          updateEmployeeRecord(
            { id, inactiveAt: toAWSDate(new Date()) },
            { onSuccess: hideDeactivateModal },
            contracts
          ),
      },
    });

  const { showModal: showReactivateModal, hideModal: hideReactivateModal } =
    useModal<ComponentProps<typeof UpdateModal>, Employee>({
      component: UpdateModal,
      data: {
        title: "Reactivate Employee",
        label: "Reactivate",
        description: "Are you sure you want to reactivate this employee?",
        onUpdate: ({ id }) =>
          updateEmployeeRecord(
            { id, inactiveAt: null },
            {
              onSuccess: hideReactivateModal,
            }
          ),
      },
    });

  const { showModal: showContractsModal, hideModal: hideContractsModal } =
    useModal<ComponentProps<typeof ContractsModal>, ContractsModalParams>({
      component: ContractsModal,
      data: {
        title: "Clients",
        onSubmit: (data) =>
          setContracts(data, { onSettled: hideContractsModal }),
        testID: "employee-contracts",
      },
    });

  return {
    showCreateModal,
    showUpdateModal,
    showDeactivateModal,
    showReactivateModal,
    showContractsModal,
  };
};
