import React, { useState } from "react";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Business as BusinessIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Edit as EditIcon,
  Unarchive as UnarchiveIcon,
} from "@mui/icons-material";
import { DataGrid, PageTitle, useQueryPagination } from "../../components";
import { useResponsive } from "../../hooks";
import { CreateClientInput } from "../../API";
import { gqlQuery, QueryType } from "../../lib";
import { columns, hiddenColumnsDesktop, hiddenColumnsMobile } from "./columns";
import { useClientModals } from "./Modals";

export type ModalCreateClientInput = CreateClientInput & {
  clientID?: string;
  employees?: { id: string }[];
};

export const Clients = () => {
  const { isMd } = useResponsive();
  const {
    showCreateModal,
    showUpdateModal,
    showDeactivateModal,
    showReactivateModal,
  } = useClientModals();
  const [showActive, setShowActive] = useState(true);
  const { data, isError, refetch, isPending, pagination } = useQueryPagination(
    ({ vars, key }: Omit<QueryType, "req">) => {
      const { queryFn, queryKey } = gqlQuery({
        req: "listCompaniesWithEmployees",
        vars: { ...vars },
        key,
      });
      return {
        queryKey,
        queryFn: async () => {
          const { data, errors } = await queryFn();
          return {
            ...data?.listCompanies,
            errors,
          };
        },
      };
    }
  );

  const showActiveCompanies = {
    label: !showActive ? "Show Active" : "Show Inactive",
    icon: <CheckCircleOutlineIcon />,
    onClick: () => setShowActive(!showActive),
  };

  return (
    <>
      <PageTitle
        action={{
          label: "Add Client",
          icon: <AddIcon />,
          onClick: showCreateModal,
        }}
        icon={<BusinessIcon />}
        title="Clients"
        altAction={showActiveCompanies}
        testID="add-client"
      />

      <DataGrid
        columns={columns(({ row }) => showUpdateModal({ row }))}
        data={(data?.items ?? []).filter(
          ({ inactiveAt }) =>
            showActive != (!!inactiveAt && new Date(inactiveAt) < new Date())
        )}
        emptyState={{
          title: "No Client Entries",
          description: "Get started by adding a client",
          testID: "no-clients",
        }}
        errorState={{
          isError,
          onClick: refetch,
        }}
        loading={isPending}
        pagination={pagination}
        rowActions={[
          {
            label: "Edit",
            onClick: ({ row }) => showUpdateModal({ row }),
            icon: <EditIcon />,
          },
          showActive
            ? {
                label: "Deactivate",
                onClick: ({ row }) => showDeactivateModal(row),
                icon: <ArchiveIcon />,
              }
            : {
                label: "Reactivate",
                onClick: ({ row }) => showReactivateModal(row),
                icon: <UnarchiveIcon />,
              },
        ]}
        columnVisibility={isMd ? hiddenColumnsMobile : hiddenColumnsDesktop}
        testID="clients"
      />
    </>
  );
};
