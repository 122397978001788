import React from "react";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
} from "@mui/icons-material";
import { StatusIcon, StatusProps } from "./StatusIcon";

type Status = "approved" | "rejected" | "pending" | boolean | null;

const mapToStatus = {
  approved: {
    label: "Approved",
    icon: <CheckCircleOutlineIcon color="success" />,
  },
  rejected: {
    label: "Changes Required",
    icon: <ErrorOutlineIcon color="error" />,
  },
  pending: {
    label: "Pending Approval",
    icon: <PauseCircleOutlineIcon color="disabled" />,
  },
};

export const ApprovalIcon = ({ status, ...rest }: StatusProps<Status>) => (
  <StatusIcon {...rest} status={getStatus(status)} />
);

const getStatus = (val: Status) =>
  mapToStatus[
    typeof val === "string"
      ? val
      : val === null
      ? "pending"
      : val
      ? "approved"
      : "rejected"
  ];
