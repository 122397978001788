import { useMutation, useQueryClient } from "@tanstack/react-query";
import { startOfWeek } from "date-fns";
import { CreateTimelogInput, UpdateTimelogInput } from "../API";
import { ApiActions, gqlMutation } from ".";

export const useTimelogLib = () => {
  const queryClient = useQueryClient();
  const { mutate: createTimelog } = useMutation({
    ...gqlMutation({ req: "createTimelog" }),
    onMutate: async ({ input }) => {
      // Optimistic UI update while API runs
      queryClient.setQueryData(
        ["getEmployeeWithCompany", input.employeeID, input.date],
        {
          ...input,
          id: "1",
        }
      );
      return { input };
    },
    onSettled: (res) => {
      if (!res?.data?.createTimelog?.date) {
        throw new Error("Interal error: date value undefined");
      }
      const dateKey = startOfWeek(new Date(res.data.createTimelog.date));
      queryClient.invalidateQueries([
        "getEmployeeWithCompany",
        res?.data?.createTimelog?.employeeID,
        dateKey,
      ]);
      queryClient.invalidateQueries(["listTimelogs"]);
    },
  });
  const { mutate: updateTimelog } = useMutation({
    ...gqlMutation({ req: "updateTimelog" }),
    onMutate: async ({ input }: { input: UpdateTimelogInput }) => {
      await queryClient.cancelQueries(["listEmployeesWithTimelogs"]);
      await queryClient.cancelQueries(["listTimelogs"]);
      await queryClient.cancelQueries([
        "getEmployeeWithCompany",
        input.employeeID,
      ]);
      return queryClient.getQueryData([
        "getEmployeeWithCompany",
        input.employeeID,
      ]);
    },
    onSettled: (res, err, { input }, context) => {
      if (err) {
        queryClient.setQueryData(
          ["getEmployeeWithCompany", input.employeeID],
          context
        );
      } else {
        if (!res?.data?.updateTimelog?.date) {
          throw new Error("Interal error: date value undefined");
        }
        const dateKey = startOfWeek(new Date(res?.data?.updateTimelog?.date));
        queryClient.invalidateQueries([
          "getEmployeeWithCompany",
          res?.data?.updateTimelog?.employeeID,
          dateKey,
        ]);
        queryClient.invalidateQueries(["listEmployeesWithTimelogs"]);
        queryClient.invalidateQueries(["listTimelogs"]);
        queryClient.invalidateQueries(["logsByEmployee", dateKey]);
      }
    },
  });
  const { mutateAsync: deleteTimelog } = useMutation(
    gqlMutation({ req: "deleteTimelog" })
  );

  const setTimelogApproval = (
    logIds: string[],
    approved: boolean,
    actions: ApiActions
  ) => {
    logIds.forEach((id) => updateTimelog({ input: { id, approved } }), actions);
  };

  const setTimelogs = (
    { id, ...input }: CreateTimelogInput,
    actions: ApiActions
  ) => {
    input = { ...input, date: input.date.replace(/\//g, "-") };
    id
      ? updateTimelog({ input: { ...input, id, approved: null } }, actions)
      : createTimelog({ input }, actions);
  };

  const deleteTimelogById = (id: string) => deleteTimelog({ input: { id } });

  return { setTimelogApproval, setTimelogs, deleteTimelogById };
};
