import { GridColDef } from "@mui/x-data-grid";
import { SummaryReportRow } from "./utils";

export const columns: GridColDef<SummaryReportRow>[] = [
  {
    field: "company",
    headerName: "Company",
    type: "string",
    width: 130,
  },
  {
    field: "client",
    headerName: "Manager(s)",
    type: "string",
    width: 130,
  },
  {
    field: "days",
    headerName: "Days",
    type: "number",
    valueGetter: ({ row }) => row.hours / 8,
    width: 100,
  },
  {
    field: "hours",
    headerName: "Hours",
    type: "number",
    width: 100,
  },
  {
    field: "employee",
    headerName: "Consultant",
    type: "string",
    minWidth: 160,
    flex: 1,
  },
];
