import { eachDayOfInterval } from "date-fns";
import {
  Employee,
  Client,
  CreateTimelogInput,
  ClientContracts,
  Timelog,
} from "../../API";
import { filterNull } from "../../lib";

type TimesheetInput = {
  employee: Employee;
  week: Interval;
  client?: Client;
};

type timesheetCounts = {
  standard: number;
  overtime: number;
  sick: number;
  holiday: number;
  total: number;
};

export const getTimesheet = async (sheet: {
  week: Interval;
  employee: Employee;
}) => {
  const timesheet = await buildTimesheet(sheet);
  const isApproved = timesheet.reduce(
    (current: boolean | null, { approved }) =>
      current === null || approved === null
        ? null
        : current || (approved ?? false),
    false
  );

  const sheetIds = timesheet.flatMap(({ approved, clientID }) =>
    isApproved && approved ? clientID ?? [] : []
  );

  const activeIds = (await filterNull(sheet.employee.clients?.items)).map(
    ({ clientID }) => clientID
  );

  return {
    timesheet,
    isApproved,
    clientIDs: [...new Set(sheetIds.concat(isApproved ? [] : activeIds))],
  };
};

export const buildTimesheet = async ({
  employee,
  week,
  client,
}: TimesheetInput): Promise<CreateTimelogInput[]> =>
  buildSheetData(
    employee.id,
    await filterNull<ClientContracts>(employee.clients?.items),
    await filterNull<Timelog>(
      employee.timelogs?.items,
      ({ clientID: id }) => !client || id === client.id
    ),
    eachDayOfInterval(week).slice(0, 7)
  );

const buildSheetData = async (
  employeeID: string,
  contracts: ClientContracts[],
  logs: Timelog[],
  dates: Date[]
): Promise<CreateTimelogInput[]> =>
  dates.map((val, idx) => {
    const date = val.toLocaleDateString("sv");
    const clientID = contracts[0]?.clientID;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, createdAt, updatedAt, ...log } =
      logs.length && date === logs[0].date
        ? (logs.shift() as Timelog)
        : { __typename: "", createdAt: "", updatedAt: "" };

    return {
      id: idx.toString(),
      employeeID,
      clientID,
      ...log,
      date: date.replace(/-/g, "/"),
    };
  });

export const sumTimesheet = (timesheet: CreateTimelogInput[]) => {
  const counts: timesheetCounts = timesheet.reduce(
    (acc, sheet) => {
      acc.standard += sheet.standard || 0;
      acc.overtime += sheet.overtime || 0;
      acc.sick += sheet.sick || 0;
      acc.holiday += sheet.holiday || 0;
      return acc;
    },
    {
      standard: 0,
      overtime: 0,
      sick: 0,
      holiday: 0,
      total: 0,
    }
  );

  counts.total =
    counts.standard + counts.overtime + counts.sick + counts.holiday;

  return counts;
};
