import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { Client, CreateClientInput, UpdateClientInput } from "../API";
import { ModalCreateClientInput } from "../pages";
import { ApiActions, gqlMutation, gqlQuery, useContractsLib } from ".";

export const useGetClient = (clientIDs: string | string[]) => {
  const ids = typeof clientIDs === "string" ? [clientIDs] : clientIDs;
  return useQueries({
    queries: ids.map((id) =>
      gqlQuery({ req: "getClient", vars: { id }, key: [id] })
    ),
  }).flatMap(({ data }) => data?.data?.getClient ?? []);
};

export const useClientLib = () => {
  const queryClient = useQueryClient();

  const options = {
    onMutate: async () => {
      await queryClient.cancelQueries(["listCompaniesWithEmployees"]);
      await queryClient.cancelQueries(["listClients"]);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["listCompaniesWithEmployees"]);
      queryClient.invalidateQueries(["listClients"]);
    },
  };

  const { mutateAsync: createClient } = useMutation({
    ...gqlMutation({ req: "createClient" }),
    ...options,
  });
  const { mutateAsync: updateClient } = useMutation({
    ...gqlMutation({ req: "updateClient" }),
    ...options,
  });
  const { mutateAsync: deleteClient } = useMutation({
    ...gqlMutation({ req: "deleteClient" }),
    ...options,
  });

  const { cleanupContracts } = useContractsLib();

  const setClientRecords = async ({
    id: companyClientsId,
    clients,
    removeClients,
    actions,
  }: {
    id: string;
    clients: ModalCreateClientInput[];
    removeClients: Client[];
    actions: ApiActions;
  }) => {
    removeClients.forEach((client) => cleanupClientRecords(client));
    await Promise.all(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      clients.map(({ employees, clientID: id, ...input }) =>
        id
          ? updateClient({
              input: {
                ...input,
                id,
                companyClientsId,
              } as UpdateClientInput,
            })
          : createClient({
              input: {
                ...input,
                companyClientsId,
              } as CreateClientInput,
            })
      )
    );
    actions.onSuccess?.();
    actions.onSettled?.();
  };

  const cleanupClientRecords = async ({ id, employees }: Client) => {
    await cleanupContracts({
      id,
      clientContracts: employees?.items ?? [],
    });

    return deleteClient({ input: { id } });
  };

  return {
    setClientRecords,
    cleanupClientRecords,
  };
};
